/*----- 3. Slider style  ------*/
.ql-container {
  .ql-snow {
    border: none !important;
  }
}
.ql-editor {
  overflow-y: inherit !important;
  span {
    color: "#000000";
  }
  h3 {
    color: "#000000";
  }
}

.add-banner {
  position: absolute;
  top: 115px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  &.top-115 {
    top: 115px;
  }
  &.top-45 {
    top: 45px;
  }
  &.top-210 {
    top: 210px;
  }
  h6 {
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-slider5-bg-img {
  h6 {
    position: absolute;
    z-index: 10;
    top: 8em;
    right: 22em;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-plants-bg-img {
  h6 {
    position: absolute;
    z-index: 10;
    top: 8em;
    right: 7em;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-img {
  h6 {
    position: absolute;
    top: 9em;
    right: 1em;
    z-index: 10;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}

.edit-slider4-bg-img {
  h6 {
    position: absolute;
    top: 5em;
    right: 1em;
    z-index: 10;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-slider6-bg-img {
  h6 {
    position: absolute;
    top: -4em;
    right: -5em;
    z-index: 10;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-furniture-bg-img {
  h6 {
    position: absolute;
    top: 2em;
    right: 4em;
    z-index: 10;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-furniture5-bg-img {
  h6 {
    position: absolute;
    top: 11em;
    right: 1em;
    z-index: 10;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-flower-bg-img {
  h6 {
    position: absolute;
    top: 12em;
    right: 1em;
    z-index: 10;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-slider1-bg-img {
  h6 {
    position: absolute;
    top: 7em;
    right: 1em;
    z-index: 10;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-shapeText-inline {
  h6 {
    position: absolute;
    top: 0em;
    left: 2.5em;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-title-inline {
  align-items: center;
  h6 {
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}
.edit-inline {
  display: flex;
  align-items: center;
  h6 {
    top: 12em;
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: $themehover-color;
    }
  }
}
.slider-height-1 {
  height: 750px;
  @media #{$xl-layout} {
    height: 650px;
  }
  @media #{$lg-layout} {
    height: 520px;
  }
  @media #{$md-layout} {
    height: 450px;
  }
  @media #{$xs-layout} {
    height: auto;
    padding: 50px 0 0;
    &.slider-height-res15 {
      padding: 0 0 0;
    }
  }
  @media #{$sm-layout} {
    padding: 38px 0 0;
  }
  &.slider-height-res {
    @media #{$xs-layout} {
      position: relative;

      height: 400px;
      &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: "";

        opacity: 0.5;
        background-color: #fff;
      }
    }
  }
}

.single-slide__content1 {
  .slider-btn-highlightBanner {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;  
      text-transform: uppercase;  
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
}
.single-slider {
  display: flex;
  align-items: flex-end;

  .slider-content {
    //padding: 183px 0 0;
    @media #{$xl-layout} {
      //padding: 80px 0 0;
    }
    @media #{$lg-layout} {
      padding: 45px 0 0;
    }
    @media #{$md-layout} {
      padding: 0 0 0;
    }
    @media #{$xs-layout} {
      padding: 0 0 40px;

      text-align: center;
    }
    @media #{$sm-layout} {
      padding: 0 0 0;
      text-align: left;
    }
    p {
      font-size: 20px;
      padding: 3px 3px;
      color: $theme-color;
      line-height: normal;
    }
    h3 {
      //font-size: 24px;
      font-weight: 500;
      margin: 0;
      position: relative;
      display: inline-block;
      margin: 0;
    }
    h1 {
      //font-size: 72px;
      line-height: 97px;
      margin: 6px 0 37px;
      color: #010101;
      @media #{$lg-layout} {
        font-size: 52px;
        line-height: 65px;
      }
      @media #{$md-layout} {
        font-size: 40px;
        line-height: 52px;

        margin: 6px 0 20px;
      }
      @media #{$xs-layout} {
        font-size: 33px;
        line-height: 48px;

        margin: 6px 0 20px;
      }
      @media #{$sm-layout} {
        font-size: 30px;

        margin: 6px 0 10px;
      }
    }
    button {
      font-size: 20px;
      line-height: 1;
      text-align: center;
      padding: 13px 13px;
      background-color: $theme-color;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #fff;
      border: 1px solid #fff;
      &:hover {
        background-color: #800200;
        color: #fff;
        border: 1px solid $theme-color;
      }
    }
    .slider-btn {
      a {
        font-size: 16px;
        line-height: 1;
        margin: 5px 5px;
        display: inline-block;

        padding: 19px 50px 21px;

        text-transform: uppercase;

        color: #010101;
        @media #{$md-layout} {
          padding: 15px 35px 17px;
        }
        @media #{$xs-layout} {
          font-size: 14px;
          padding: 12px 30px 12px;
        }
        &:hover {
          color: #fff;
          border: 1px solid $theme-color;
        }
      }
    }
    .slider-btn-spring {
      a {
        font-size: 16px;
        line-height: 1;
        display: inline-block;
        margin: 5px 5px;
        padding: 19px 50px 19px;  
        text-transform: uppercase;  
        color: #010101;
        border: 1px solid #333;
        &:hover {
          color: #fff;
          border: 1px solid #a749ff;
        }
        @media #{$md-layout} {
          padding: 15px 35px 15px;
        }
        @media #{$xs-layout} {
          font-size: 14px;  
          padding: 12px 30px 12px;
        }
      }
    }
    .slider-btn-BannerSlider {
      a {
        font-size: 16px;
        line-height: 1;
        display: inline-block;
        margin: 5px 5px;
        padding: 19px 50px 19px;  
        text-transform: uppercase;  
        color: #010101;
        border: 1px solid #333;
        &:hover {
          color: #fff;
          border: 1px solid #a749ff;
        }
        @media #{$md-layout} {
          padding: 15px 35px 15px;
        }
        @media #{$xs-layout} {
          font-size: 14px;
  
          padding: 12px 30px 12px;
        }
      }
    }
    &.slider-content-10 {
      padding-top: 0;
      @media #{$xs-layout} {
        display: block;

        width: 100%;

        text-align: center;
      }
      @media #{$sm-layout} {
        text-align: left;
      }
    }
  }
  .slider-single-img {
    margin: 0 -80px 0 -80px;
    span {
      float: right;
      font-size: 20px;
      cursor: pointer;
      padding: 3px 3px;
      color: $theme-color;
      &:hover {
        color: #331b03;
      }
    }
    @media #{$xl-layout} {
      margin: 0 0px 0 0px;
    }
    @media #{$lg-layout} {
      margin: 0 0 0 0;
    }
    @media #{$md-layout} {
      margin: 0 0 0 0;
    }
    @media #{$xs-layout} {
      margin: 0 0 0 0;
    }
  }
  &.single-slider-10 {
    align-items: center;
  }
}

@keyframes mymove {
  from {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.swiper-slide-active {
  .slider-animated-1 {
    .text1s {
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
    .text2s {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }
    .text3s {
      -webkit-animation-delay: 3s;
      animation-delay: 3s;
    }
    .text4s {
      -webkit-animation-delay: 4s;
      animation-delay: 4s;
    }
    .text5s {
      -webkit-animation-delay: 5s;
      animation-delay: 5s;
    }
    .fast {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    .textFadeInLeft {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
    }
    .textFadeInRight {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight;
    }
    .textFadeInUp {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
    }
    .textFadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
    }

    p {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-delay: 1.5s;
      animation-delay: 1.5s;
    }

    &.slider-single-img img {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-delay: 1.6s;
      animation-delay: 1.6s;
    }
    &.single-slider-img4 img {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-delay: 1.6s;
      animation-delay: 1.6s;
    }
    &.slider-singleimg-hm9 img {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-delay: 1.6s;
      animation-delay: 1.6s;
    }
    &.slider-singleimg-hm11 img {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-delay: 1.6s;
      animation-delay: 1.6s;
    }
    .content-img img {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
  }
  .slider-animated-2 {
    .text1s {
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
    .text2s {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }
    .text3s {
      -webkit-animation-delay: 3s;
      animation-delay: 3s;
    }
    .text4s {
      -webkit-animation-delay: 4s;
      animation-delay: 4s;
    }
    .text5s {
      -webkit-animation-delay: 5s;
      animation-delay: 5s;
    }
    .fast {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    .textFadeInLeft {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
    }
    .textFadeInRight {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight;
    }
    .textFadeInUp {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
    }
    .textFadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
    }
    p {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
    a {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
      -webkit-animation-delay: 1.6s;
      animation-delay: 1.6s;
    }
    &.slider-singleimg-hm10 img {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight;
      -webkit-animation-delay: 1.6s;
      animation-delay: 1.6s;
    }
  }
}

.nav-style-1 {
  .ht-swiper-button-nav {
    font-size: 80px;

    position: absolute;
    top: 50%;
    left: 80px;

    display: inline-block;
    visibility: hidden;

    width: 80px;
    height: 80px;
    padding: 0;

    transition: all 0.3s ease 0s;
    transform: translateY(-50%);
    text-align: center;

    opacity: 0;
    color: #999;
    border: none;
    background: none;
    @media #{$xl-layout} {
      left: -30px;
    }
    @media #{$lg-layout} {
      left: 30px;
    }
    @media #{$md-layout} {
      font-size: 60px;

      left: 15px;
    }
    @media #{$xs-layout} {
      font-size: 50px;

      left: 15px;
    }
    &:hover {
      color: $theme-color;
    }
    &.swiper-button-next {
      right: 80px;
      left: auto;
      @media #{$xl-layout} {
        right: -30px;
      }
      @media #{$lg-layout} {
        right: 30px;
      }
      @media #{$md-layout} {
        right: 15px;
      }
      @media #{$xs-layout} {
        right: 15px;
      }
    }
  }

  &.nav-testi-style {
    .owl-nav {
      div {
        font-size: 50px;

        left: -155px;

        color: #8d8c8c;
        @media #{$xl-layout} {
          left: -80px;
        }
        @media #{$lg-layout} {
          left: -80px;
        }
        @media #{$md-layout} {
          left: -30px;
        }
        @media #{$xs-layout} {
          display: none;
        }
        &:hover {
          color: #3d6882;
        }
        &.owl-next {
          right: -155px;
          left: auto;
          @media #{$xl-layout} {
            right: -80px;
          }
          @media #{$lg-layout} {
            right: -80px;
          }
          @media #{$md-layout} {
            right: -30px;
          }
        }
      }
    }
    &.nav-testi-mrg {
      .owl-nav {
        div {
          left: -80px;

          color: #fff;
          @media #{$md-layout} {
            left: 0;
          }
          @media #{$xs-layout} {
            display: none;
          }
          &.owl-next {
            right: -80px;
            left: auto;
            @media #{$md-layout} {
              right: 0;
              left: auto;
            }
            @media #{$xs-layout} {
              display: none;
            }
          }
        }
      }
    }
  }
}
.slider-area {
  &:hover .nav-style-1 .ht-swiper-button-nav {
    visibility: visible;

    opacity: 1;
  }
  &.ml-10 {
    margin-left: 0;
  }
  @media #{$xl-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 30px;
    }
    &.mr-70 {
      margin-right: 30px;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 40px;
    }
    &.mr-70 {
      margin-right: 40px;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }

  .swiper-pagination-bullets {
    position: absolute;
    right: 0;
    bottom: 26px;
    left: 0;

    text-align: center;
    .swiper-pagination-bullet {
      position: relative;

      display: inline-block;

      width: 14px;
      height: 14px;
      margin: 0 8px;

      opacity: 1;
      border-radius: 100%;
      background: #fff none repeat scroll 0 0;
      &:before {
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;

        content: "";

        border: 1px solid transparent;
        border-radius: 100%;
      }
      &-active:before {
        border: 1px solid #fff;
      }
    }
  }
}

/* slider style-2 */
.slider-content-2 {
  margin-top: -6px;
  @media #{$xs-layout} {
    text-align: center;
  }
  p {
    color: $theme-color;
  }

  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  h3 {
    //font-size: 24px;
    font-weight: 500;

    position: relative;

    margin-bottom: 0;
    margin-left: 135px;

    color: #000000;
    @media #{$xs-layout} {
      margin-left: 0;
    }
    @media #{$sm-layout} {
      margin-left: 60px;
    }
    //&::before {
    //  position: absolute;
    //  top: 14px;
    //  left: -135px;

    //  width: 120px;
    //  height: 2px;

    //  content: "";

    //  background-color: #000000;
    //  @media #{$xs-layout} {
    //    display: none;
    //  }
    //  @media #{$sm-layout} {
    //    left: 80px;

    //    display: block;

    //    width: 60px;
    //  }
    //}

    &.no-style {
      margin-left: 0;

      @media #{$sm-layout} {
        margin-left: 0;
      }
      &:before {
        display: none;
      }
    }
  }
  h1 {
    //font-size: 72px;
    line-height: 97px;

    margin: 6px 0 37px;

    color: #010101;
    @media #{$xl-layout} {
      font-size: 60px;
      line-height: 80px;
    }
    @media #{$lg-layout} {
      font-size: 56px;
      line-height: 70px;
    }
    @media #{$md-layout} {
      font-size: 45px;
      line-height: 60px;
    }
    @media #{$xs-layout} {
      font-size: 35px;
      line-height: 45px;
    }
  }
  .slider-btn {
    a {
      font-size: 16px;
      line-height: 1;
      margin: 5px 5px;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 21px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      background-color: #ffb893;
      @media #{$xs-layout} {
        padding: 14px 30px 16px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
      }
    }

    &--style2 {
      span {
        font-size: 20px;
        cursor: pointer;
        padding: 3px 3px;
        color: $theme-color;
        &:hover {
          color: #331b03;
        }
      }
      a {
        background-color: transparent;
      }
    }
  }
  &.slider-content-fruits {
    h3 {
      color: #6eab49;
      &::before {
        background-color: #6eab49;
        @media #{$sm-layout} {
          left: 45px;
        }
      }
    }
    h1 {
      font-size: 71px;
      @media #{$xl-layout} {
        font-size: 55px;
      }
      @media #{$lg-layout} {
        font-size: 50px;
      }
      @media #{$md-layout} {
        font-size: 40px;
      }
      @media #{$xs-layout} {
        font-size: 30px;

        margin: 6px 0 20px;
      }
    }
    .slider-btn {
      a {
        font-size: 16px;
        margin: 5px 5px;
        color: #fff;
        border: 1px solid #6eab49;
        background-color: #6eab49;
        &:hover {
          color: #fff;
          border: 1px solid $theme-color;
        }
      }
    }
    &--style2 {
      h3 {
        color: #ab87cd;
        &:before {
          background-color: #ab87cd;
          @media #{$sm-layout} {
            left: 45px;
          }
        }
      }
      .slider-btn {
        a {
          margin: 5px 5px;
          border: 1px solid #ab87cd;
          background-color: #ffb893;
        }
      }
    }
  }
  .slider-btn-2 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
  &.slider-content-flower {
    h3 {
      letter-spacing: 0.8px;

      color: #c61a32;
      &::before {
        background-color: #c61a32;
        @media #{$sm-layout} {
          left: 45px;
        }
      }
    }
    h1 {
      font-size: 66px;
      @media #{$xl-layout} {
        font-size: 55px;
      }
      @media #{$lg-layout} {
        font-size: 50px;
      }
      @media #{$md-layout} {
        font-size: 40px;
      }
      @media #{$xs-layout} {
        font-size: 30px;

        margin: 6px 0 20px;
      }
    }
    .slider-btn {
      a {
        font-size: 16px;
        margin: 5px 5px;
        color: #fff;
        border: 1px solid #c61a32;
        background-color: #c61a32;
        &:hover {
          color: #fff;
          border: 1px solid $theme-color;
        }
      }
    }
  }

  &--style2 {
    h1 {
      margin-bottom: 5px;
    }
    p {
      font-size: 17px;
      font-weight: 400;

      margin-bottom: 50px;

      color: #73848e;
    }
    .slider-btn {
      a {
        margin: 5px 5px;
        background-color: transparent;
      }
    }

    &--white {
      p {
        color: $white;
      }
      h1 {
        color: $white;
      }
      h3 {
        color: $white;
      }
      .slider-btn {
        a {
          margin: 5px 5px;
          color: $white;
          border-color: $white;
        }
      }
    }
  }

  &--white {
    h1 {
      color: $white;
    }
    h3 {
      color: $white;
    }
    p {
      color: $white;
    }
    .slider-btn {
      a {
        margin: 5px 5px;
        color: $white;
        border-color: $white;
        background-color: transparent;
      }
    }
  }
}

.single-slider-2 {
  @media #{$xl-layout} {
    background-position: 50%;
  }
  @media #{$lg-layout} {
    background-position: 42%;
  }
  @media #{$xs-layout} {
    background-position: 30%;
  }
  @media #{$sm-layout} {
    background-position: 22%;
  }
}

/* home 3 */

.slider-height-2 {
  height: 800px;
  @media #{$xl-layout} {
    height: 570px;
  }
  @media #{$lg-layout} {
    height: 500px;
  }
  @media #{$md-layout} {
    height: 500px;
  }
  @media #{$xs-layout} {
    height: 400px;
  }
  &.slider-content-center {
    display: flex;
    align-items: center;
  }
}

.slider-content-3 {
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  h3 {
    font-weight: 500;
    position: relative;
    display: block;
    margin: 0;
  }
  h1 {
    line-height: 56px;
    margin: 28px 0 23px !important;
    @media #{$lg-layout} {
      font-size: 52px;
      line-height: 40px;
      margin: 15px 0 !important;
    }
    @media #{$md-layout} {
      font-size: 45px;
      line-height: 40px;
      margin: 15px 0 !important;
    }
    @media #{$xs-layout} {
      font-size: 28px;
      line-height: 28px;
      margin: 15px 0 !important;
    }
  }
  p {
    font-size: 18px;
    margin: 0 0 44px;
    color: $theme-color;
  }
  .slider-btn {
    a {
      margin: 5px 5px;
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      padding: 19px 50px 21px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      @media #{$md-layout} {
        font-size: 15px;
        padding: 15px 25px;
      }
      @media #{$xs-layout} {
        font-size: 15px;
        padding: 15px 25px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
      }
    }
    button {
      font-size: 20px;
      line-height: 1;
      display: inline-block;
      padding: 16px 13px;
      background-color: $theme-color;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #333;
      &:hover {
        background-color: #592e03;
        color: #fff;
        border: 1px solid $theme-color;
      }
    }
  }
  .slider-btn-3 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
  &.slider-content-4 {
    //margin-left: 112px;
    @media #{$xx-layout} {
      margin-left: 30px;
    }
    @media #{$xl-layout} {
      margin-left: 0;
    }
    @media #{$lg-layout} {
      margin-left: 0;
    }
    @media #{$md-layout} {
      margin-left: 0;
    }
    @media #{$xs-layout} {
      margin-left: 0;
    }
    p {
      font-size: 20px;
      padding: 3px 3px;
      color: $theme-color;
    }
    h1 {
      @media #{$md-layout} {
        font-size: 34px;
        line-height: 35px;

        margin: 15px 0 15px;
      }
    }
    h3 {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }

  &.slider-content-3-white {
    h3 {
      color: #fff;
      &::before {
        background-color: #fff;
      }
      &::after {
        background-color: #fff;
      }
    }
    h1 {
      color: #fff;
      @media #{$xx-layout} {
        font-size: 55px;
        line-height: 45px;
        margin: 20px 0 23px;
      }
      @media #{$xl-layout} {
        font-size: 55px;
        line-height: 45px;
        margin: 20px 0 23px;
      }
      @media #{$md-layout} {
        font-size: 47px;
        margin: 20px 0 18px;
      }
    }
    p {
      color: $theme-color;
      @media #{$xx-layout} {
        margin-bottom: 20px;
      }
      @media #{$xl-layout} {
        margin-bottom: 20px;
      }
      @media #{$lg-layout} {
        margin-bottom: 20px;
      }
      @media #{$md-layout} {
        margin-bottom: 20px;
      }
      @media #{$xs-layout} {
        margin-bottom: 20px;
      }
    }
    .slider-btn {
      a {
        z-index: 9;
        margin: 5px 5px;
        padding: 17px 41px 16px;
        margin: 5px 5px;
        border: 1px solid transparent;
        background-color: #e6e6e6;
        @media #{$xl-layout} {
          padding: 12px 30px 11px;
        }
        @media #{$lg-layout} {
          padding: 12px 30px 11px;
        }
        @media #{$md-layout} {
          padding: 12px 30px 11px;
        }
        @media #{$xs-layout} {
          padding: 12px 30px 11px;
        }
        &:hover {
          border: 1px solid $theme-color;
        }
      }
    }
  }
}

/* slider hm4 */

.slider-height-3 {
  height: 710px;
  & .container {
    margin-right: inherit;
    margin-left: inherit;
  }
  @media #{$xl-layout} {
    height: 570px;
  }
  @media #{$lg-layout} {
    height: 500px;
  }
  @media #{$md-layout} {
    height: 500px;
  }
  @media #{$xs-layout} {
    height: 400px;
  }
  &.slider-height-res-hm4 {
    @media #{$xs-layout} {
      height: 680px;
    }
  }
  &.slider-height-res-hm4 {
    @media #{$sm-layout} {
      height: 400px;
    }
  }
}

.single-slider-img4 {
  position: relative;

  margin: 0 -77px 0 50px;
  @media #{$xl-layout} {
    margin: 0 0 0 0;
  }
  @media #{$lg-layout} {
    margin: 0 0 0 0;
  }
  @media #{$md-layout} {
    margin: 0 0 0 0;
  }
  @media #{$xs-layout} {
    margin: 30px 0 0;
  }
  .single-price-wrap {
    position: absolute;
    top: 1.5em;
    right: 3em;
    width: 130px;
    animation-name: bounce-img;
    animation-duration: 2.9s;
    animation-iteration-count: infinite;
    @media #{$xl-layout} {
      right: 0;
    }
    @media #{$lg-layout} {
      right: 0;
    }
    @media #{$md-layout} {
      right: 0;
    }
    @media #{$xs-layout} {
      right: 0;
    }
    img {
      width: 100%;
    }
    .single-price {
      position: absolute;
      top: 0;
      left: 45%;
      margin-top: 26px;
      transform: translateX(-50%);
      text-align: center;
      span {
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        display: block;
        color: #915342;
        &.dollar {
          margin-top: 2px;
        }
      }
    }
  }
}

/* slider 5 */

.slider-height-4 {
  height: 750px;
  @media #{$xx-layout} {
    height: 600px;
  }
  @media #{$xl-layout} {
    height: 600px;
  }
  @media #{$lg-layout} {
    height: 550px;
  }
  @media #{$md-layout} {
    height: 530px;
  }
  @media #{$xs-layout} {
    height: 500px;
  }
}
.slider-content-5 {
  padding-top: 66px;
  h3 {
    //font-size: 30px;
    font-weight: 500;

    margin: 0;

    letter-spacing: 0.5px;

    color: #fff;
  }
  h1 {
    //font-size: 72px;
    font-weight: 500;
    line-height: 56px;
    margin: 27px 0 22px;
    letter-spacing: 0.5px;
    color: #fff;
    @media #{$md-layout} {
      font-size: 58px;
      margin: 17px 0 20px;
    }
    @media #{$xs-layout} {
      font-size: 45px;
      line-height: 38px;
      margin: 15px 0 15px;
    }
  }

  p {
    font-size: 18px;
    margin: 0 0 45px;
    color: $theme-color;
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
}
.slider-btn-5 {
  a {
    font-size: 16px;
    line-height: 1;
    display: inline-block;
    margin: 5px 5px;
    padding: 19px 50px 19px;
    text-transform: uppercase;
    color: #010101;
    border: 1px solid #333;
    &:hover {
      color: #fff;
      border: 1px solid #a749ff;
    }
    @media #{$md-layout} {
      padding: 15px 35px 15px;
    }
    @media #{$xs-layout} {
      font-size: 14px;
      padding: 12px 30px 12px;
    }
  }
}
.nav-style-2.owl-carousel > .owl-nav div,
.nav-style-2 .ht-swiper-button-nav {
  font-size: 60px;
  line-height: 94px;
  position: absolute;
  top: 50%;
  display: inline-block;
  visibility: hidden;
  width: 65px;
  height: 84px;
  padding: 0;
  transition: all 0.3s ease 0s;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  color: #000;
  border: none;
  background: none;
  background-color: #fff;
  @media #{$lg-layout} {
    font-size: 45px;
    line-height: 74px;
    width: 45px;
    height: 64px;
  }
  @media #{$md-layout} {
    font-size: 45px;
    line-height: 74px;
    width: 45px;
    height: 64px;
  }
  @media #{$xs-layout} {
    font-size: 30px;
    line-height: 54px;
    width: 35px;
    height: 50px;
  }
  &:hover {
    color: #fff;
    background-color: $theme-color;
  }
  &.owl-next {
    right: 0;
    left: auto;
  }

  &.swiper-button-prev {
    left: 0;
  }
  &.swiper-button-next {
    right: 0;
  }
}

.slider-area {
  &:hover .nav-style-2.owl-carousel > .owl-nav div,
  &:hover .nav-style-2 .ht-swiper-button-nav {
    visibility: visible;
    opacity: 1;
  }
  &.ml-10 {
    margin-left: 0;
  }
}

/* slider 6 hm7 */

.slider-height-5 {
  height: 850px;
  @media #{$xx-layout} {
    height: 600px;
  }
  @media #{$xl-layout} {
    height: 600px;
  }
  @media #{$lg-layout} {
    height: 550px;
  }
  @media #{$md-layout} {
    height: 530px;
  }
  @media #{$xs-layout} {
    height: 500px;
  }
}

.slider-content-6 {
  h1 {
    //font-size: 60px;
    font-weight: 500;
    line-height: 48px;
    margin: 0;
    letter-spacing: 8px;
    color: #fff;
    @media #{$lg-layout} {
      font-size: 45px;
    }
    @media #{$md-layout} {
      font-size: 45px;
    }
    @media #{$xs-layout} {
      font-size: 30px;
      line-height: 38px;
    }
  }
  p {
    font-size: 18px;
    margin: 32px 0 35px;
    letter-spacing: 1px;
    color: $theme-color;
      &:hover {
        color:$themehover-color;
      }
    @media #{$lg-layout} {
      margin: 22px 0 30px;
    }
    @media #{$md-layout} {
      margin: 22px 0 30px;
    }
    @media #{$xs-layout} {
      margin: 18px 0 25px;
    }
  }
}

.nav-style-3.owl-carousel > .owl-nav,
.nav-style-3 .ht-swiper-button-nav {
  font-size: 60px;
  line-height: 94px;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;

  display: inline-block;

  width: 65px;
  height: 84px;

  transition: all 0.3s ease 0s;
  text-align: center;

  color: #000;
  border: none;
  background-color: #fff;
  &.swiper-button-prev {
    right: 50px;
  }
  @media #{$xs-layout} {
    font-size: 45px;
    line-height: 67px;

    width: 50px;
    height: 60px;
  }
  &:hover {
    color: #fff;
    background-color: $theme-color;
  }
}

.nav-style-3--blue.owl-carousel > .owl-nav,
.nav-style-3--blue .ht-swiper-button-nav {
  &:hover {
    color: #fff;
    background-color: #ffb893;
  }
}

/* slider hm8 */

.slider-height-6 {
  height: 543px;
  @media #{$lg-layout} {
    height: 500px;
  }
  @media #{$md-layout} {
    height: 400px;
  }
  @media #{$xs-layout} {
    height: 400px;
  }
}

.slider-hm8 {
  .slider-content-5 {
    padding-top: 0;
  }
  p {
    margin: 0 0 0;
  }
}

/* slider hm9 */
.slider-height-7 {
  height: 950px;
  @media #{$lg-layout} {
    height: 800px;
  }
  @media #{$md-layout} {
    height: 600px;
  }
  @media #{$xs-layout} {
    height: auto;
    padding: 130px 0 100px;
  }
}
.slider-h9-mrg {
  margin-top: -35px;
  @media #{$lg-layout} {
    margin-top: 0;
  }
  @media #{$md-layout} {
    margin-top: 30px;
  }
  @media #{$xs-layout} {
    margin-top: 0;
  }
}
.slider-content-7 {
  @media #{$xs-layout} {
    width: 180px;
    max-width: 100%;
    margin-left: 30px;

    text-align: left;
  }
  p {
    font-size: 20px;
    padding: 3px 3px;
    color: $theme-color;
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }

  h3 {
    //font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    letter-spacing: 0.3px;
    color: #313131;
  }

  h1 {
    //font-size: 48px;
    font-weight: 500;
    line-height: 65px;
    margin: 17px 0 37px;
    letter-spacing: 1px;
    color: #313131;
    @media #{$lg-layout} {
      font-size: 42px;
      line-height: 60px;
    }
    @media #{$md-layout} {
      font-size: 32px;
      line-height: 50px;

      margin: 6px 0 22px;
    }
    @media #{$xs-layout} {
      font-size: 22px;
      line-height: 38px;

      margin: 6px 0 22px;
    }
  }
  .slider-btn-9 a {
    font-size: 16px;
    line-height: 1;
    margin: 5px 5px;
    display: inline-block;
    margin: 0 5px;
    padding: 19px 50px 19px;

    text-transform: uppercase;

    color: #010101;
    border: 1px solid #333;
    @media #{$md-layout} {
      font-size: 15px;

      padding: 14px 30px 14px;
    }
    @media #{$xs-layout} {
      font-size: 15px;

      padding: 15px 30px 15px;
    }
    &:hover {
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  .slider-btn-7 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
  &.slider-content-mrg-hm17 {
    width: 590px;
    max-width: 100%;
    margin-top: 127px;
    @media #{$lg-layout} {
      width: 390px;
    }
    @media #{$md-layout} {
      width: 290px;
      margin-top: 0;
    }
    @media #{$xs-layout} {
      margin-top: 0;
    }
  }
}

.slider-singleimg-hm9 {
  p {
    font-size: 20px;
    cursor: pointer;
    padding: 3px 3px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }

  @media #{$xs-layout} {
    margin-top: 30px;
    &.ml-100 {
      margin-left: 20px;
    }
    &.mr-100 {
      margin-right: 20px;
    }
  }
  @media #{$sm-layout} {
    margin-top: 0;
  }
  @media #{$md-layout} {
    &.ml-100 {
      margin-left: 40px;
    }
    &.mr-100 {
      margin-right: 40px;
    }
  }
  @media #{$lg-layout} {
    &.ml-100 {
      margin-left: 70px;
    }
    &.mr-100 {
      margin-right: 70px;
    }
  }
}

/* slider hm10 */

.slider-height-8 {
  height: 700px;
  @media #{$xl-layout} {
    height: 650px;
  }
  @media #{$lg-layout} {
    height: 520px;
  }
  @media #{$md-layout} {
    height: 450px;
  }
  @media #{$xs-layout} {
    height: auto;
    padding: 60px 0;
  }
}

.slider-singleimg-hm10 {
  p {
    font-size: 20px;
    cursor: pointer;
    padding: 3px 3px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
  @media #{$md-layout} {
    &.ml-40 {
      margin-left: 0;
    }
    &.mr-40 {
      margin-right: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-40 {
      margin-left: 0;
    }
    &.mr-40 {
      margin-right: 0;
    }
  }
}

/* home 11 */

.slider-height-9 {
  height: 780px;
  @media #{$xl-layout} {
    height: 700px;
  }
  @media #{$lg-layout} {
    height: 630px;
  }
  @media #{$md-layout} {
    height: 500px;
  }
  @media #{$xs-layout} {
    height: auto;
    padding: 130px 0 70px;
  }
  @media #{$sm-layout} {
    padding: 130px 0 70px;
  }
}

.slider-content-11 {
  p {
    font-size: 20px;
    padding: 3px 3px;
    color: $theme-color;
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  h3 {
    //font-size: 24px;
    font-weight: 500;

    margin: 0;

    letter-spacing: 1px;
    @media #{$md-layout} {
      font-size: 20px;
    }
    @media #{$xs-layout} {
      font-size: 20px;
    }
  }
  h1 {
    //font-size: 72px;
    line-height: 97px;

    margin: 0;
    margin: 15px 0 38px;

    color: #010101;
    @media #{$lg-layout} {
      font-size: 52px;
      line-height: 65px;

      margin: 5px 0 27px;
    }
    @media #{$md-layout} {
      font-size: 45px;
      line-height: 55px;

      margin: 3px 0 20px;
    }
    @media #{$xs-layout} {
      font-size: 40px;
      line-height: 50px;

      margin: 3px 0 20px;
    }
  }
  .slider-btn-11 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;

      text-transform: uppercase;

      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;

        padding: 12px 30px 12px;
      }
    }
  }
  @media #{$xs-layout} {
    text-align: center;
  }
  @media #{$xs-layout} {
    &.fullpage-content {
      width: 100%;

      text-align: center;
      h3 {
        font-size: 16px;

        letter-spacing: 0;
      }
      h1 {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
  @media #{$sm-layout} {
    &.fullpage-content {
      h3 {
        font-size: 17px;

        letter-spacing: 0;
      }
      h1 {
        font-size: 35px;
      }
    }
  }
}

.slider-singleimg-hm11 {
  margin: 0 22px 0 28px;
  @media #{$xl-layout} {
    margin: 0 50px 0 60px;
  }
  @media #{$xs-layout} {
    margin: 30px 0 0 0;
  }
  @media #{$sm-layout} {
    margin: 0 0 0 0;
  }
}

.slider-h11-mrg {
  margin-bottom: -140px;
  @media #{$xs-layout,
  $md-layout} {
    margin-bottom: 0;
  }
}

/* home 12 */

#section2 .fp-tableCell,
#section4 .fp-tableCell {
  display: flex;
  align-items: center;
}
#section1 .fp-tableCell,
#section3 .fp-tableCell {
  display: flex;
  align-items: flex-end;
}

#section5 .fp-tableCell {
  height: auto !important;
}
#section5 {
  height: auto !important;
}

#fp-nav {
  top: 55%;
  ul {
    li {
      margin: 7px 7px 10px;
      a {
        span {
          background: #000;
        }
      }
    }
  }
  &.right {
    right: 64px;
    @media #{$md-layout} {
      right: 10px;
    }
    @media #{$sm-layout} {
      right: 25px;
    }
  }
}

.fullpage-slider-mrg {
  margin-top: -34px;
  @media #{$xs-layout} {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
.fullpage-slider-mrg-2 {
  margin-bottom: -88px;
  @media #{$xs-layout} {
    margin-bottom: 30px;
  }
  @media #{$sm-layout} {
    margin-bottom: -50px;
  }
}

.slider-section.active {
  .slider-animated-1 {
    .text1s {
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
    .text2s {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }
    .text3s {
      -webkit-animation-delay: 3s;
      animation-delay: 3s;
    }
    .text4s {
      -webkit-animation-delay: 4s;
      animation-delay: 4s;
    }
    .text5s {
      -webkit-animation-delay: 5s;
      animation-delay: 5s;
    }
    .fast {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    .textFadeInLeft {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
    }
    .textFadeInRight {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight;
    }
    .textFadeInUp {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
    }
    .textFadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
    }

    & img {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-delay: 0.8s;
      animation-delay: 0.8s;
    }
  }
}

.slider12-img-2 {
  @media #{$xs-layout} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  img {
    width: 100%;
  }
}

.slider12-img-1 {
  margin: 0 4px 0 6px;
  img {
    width: 100%;
  }
}

@media #{$xx-layout} {
  .slider12-img-1 {
    text-align: center;
    img {
      width: 350px;
    }
  }
}

@media #{$xl-layout} {
  .slider12-img-1 {
    text-align: center;
    img {
      width: 350px;
    }
  }
  .slider12-img-2 {
    text-align: center;
    img {
      width: 420px;
    }
  }
}

@media #{$lg-layout} {
  .slider12-img-1 {
    text-align: center;
    img {
      width: 330px;
    }
  }
}

@media #{$xs-layout} {
  .fullpage-wrapper {
    padding-top: 130px;
    @media #{$sm-layout} {
      padding-top: 0;
    }
  }
  .fullpage-slider-wrap-mrg {
    margin-bottom: 30px;
  }
}

@media #{$sm-layout} {
  .fullpage-slider-wrap-mrg {
    margin-bottom: 0;
  }
}

/* home 13 */

.slider-content-13 {

  p {
    color: $theme-color;
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  h5 {
    //font-size: 24px;
    font-weight: 500;

    margin: 0;

    letter-spacing: 1px;
    @media #{$xs-layout} {
      font-size: 22px;
    }
  }
  h1 {
    //font-size: 120px;
    font-weight: 600;
    line-height: 120px;

    margin: 34px 0 55px;
    @media #{$lg-layout} {
      font-size: 90px;
      line-height: 90px;

      margin: 24px 0 35px;
    }
    @media #{$md-layout} {
      font-size: 80px;
      line-height: 90px;

      margin: 20px 0 30px;
    }
    @media #{$xs-layout} {
      font-size: 40px;
      line-height: 50px;

      margin: 15px 0 20px;
    }
  }
  .slider-btn {
    a {
      font-size: 16px;
      line-height: 1;
      margin: 5px 5px;
      display: inline-block;

      padding: 19px 50px 19px;

      text-transform: uppercase;

      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
    }
  }
  .slider-btn-13 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
}

@media #{$xs-layout} {
  .hm-13-slider {
    background-position: 72%;
  }
}

@media #{$sm-layout} {
  .hm-13-slider {
    background-position: 55%;
  }
}

.slider-single-img-14 {
  position: relative;
  z-index: 9;

  margin: 0 23px;
  padding-bottom: 72px;
  @media #{$lg-layout} {
    padding-bottom: 60px;
  }
  @media #{$md-layout} {
    padding-bottom: 44px;
  }
  @media #{$xs-layout} {
    margin: 0 0;
    padding-bottom: 0;

    text-align: center;
  }
  @media #{$sm-layout} {
    padding-bottom: 38px;

    text-align: inherit;
  }
  img {
    width: 100%;
  }
}

.slider-height-14 {
  height: 850px;
  padding: 89px 0 0;
  @media #{$lg-layout} {
    height: 715px;
  }
  @media #{$md-layout} {
    height: 525px;
    padding: 70px 0 0;
  }
  @media #{$xs-layout} {
    height: auto;
    padding: 120px 0 0;
  }
}

.slider-content-14 {
  margin: -90px 0 0;
  @media #{$xs-layout} {
    margin: 0 0 30px;

    text-align: center;
  }
  @media #{$sm-layout} {
    margin: 0 0 30px;

    text-align: left;
  }
  p {
    font-size: 20px;
    cursor: pointer;
    padding: 3px 3px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  h3 {
    //font-size: 24px;
    font-weight: 500;
    position: relative;
    margin: 0 0 0 150px;
    letter-spacing: 0.8px;
    color: #fff;
    @media #{$md-layout} {
      font-size: 20px;
    }
    @media #{$xs-layout} {
      font-size: 20px;

      margin: 0 0 0 100px;
    }
    @media #{$sm-layout} {
      margin: 0 0 0 60px;
    }
    //&::before {
    //  position: absolute;
    //  top: 14px;
    //  left: -135px;

    //  width: 120px;
    //  height: 2px;

    //  content: "";

    //  background-color: #fff;
    //  @media #{$xs-layout} {
    //    left: -100px;

    //    width: 80px;
    //  }
    //  @media #{$xs-layout} {
    //    left: -60px;

    //    width: 50px;
    //  }
    //}
  }
  h1 {
    //font-size: 72px;
    line-height: 97px;
    margin: 14px 0 38px;
    color: #fff;
    @media #{$lg-layout} {
      font-size: 55px;
      line-height: 75px;

      margin: 10px 0 25px;
    }
    @media #{$md-layout} {
      font-size: 42px;
      line-height: 60px;

      margin: 10px 0 25px;
    }
    @media #{$xs-layout} {
      font-size: 33px;
      line-height: 50px;

      margin: 5px 0 15px;
    }
    @media #{$sm-layout} {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .slider-btn {
    a {
      font-size: 16px;
      line-height: 1;
      margin: 5px 5px;
      z-index: 1;

      display: inline-block;

      padding: 20px 50px 19px;

      transition: all 0.4s ease-in-out 0s;
      text-transform: uppercase;

      color: #010101;
      border: none;
      background-color: #eeeff1;
      @media #{$xs-layout} {
        font-size: 15px;

        padding: 16px 30px 15px;
      }
      &:hover {
        color: #fff;
      }
    }
  }
}

.slider-content-15 {
  p {
    font-size: 20px;
    padding: 3px 3px;
    color: $theme-color;
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  h1 {
    font-family: $abrilfatface;
    //font-size: 90px;
    line-height: 115px;
    margin: 0;
    letter-spacing: 1px;
    color: #fff;
    @media #{$xl-layout} {
      font-size: 75px;
      line-height: 100px;
    }
    @media #{$lg-layout} {
      font-size: 70px;
      line-height: 85px;
    }
    @media #{$md-layout} {
      font-size: 60px;
      line-height: 85px;
    }
    @media #{$xs-layout} {
      font-size: 38px;
      line-height: 55px;
    }
    @media #{$sm-layout} {
      font-size: 42px;
      line-height: 65px;
    }
  }
  &.slider15-mrg-nrg {
    h1 {
      margin-top: -26px;
      @media #{$lg-layout} {
        margin-top: -18px;
      }
      @media #{$xs-layout} {
        margin-top: -20px;
      }
    }
  }
  .slider-btn-15 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
}

.dealy-style-2 {
  .timer {
    div {
      margin: 49px 0 43px;
      @media #{$lg-layout} {
        margin: 40px 0 40px;
      }
      @media #{$md-layout} {
        margin: 30px 0 35px;
      }
      @media #{$xs-layout} {
        margin: 20px 0 5px;
      }
      span {
        font-size: 48px;
        font-weight: 300;

        display: inline-block;

        margin: 0 34px 0;

        // color: #fff;
        @media #{$lg-layout} {
          margin: 0 25px 0;
        }
        @media #{$md-layout} {
          font-size: 40px;

          margin: 0 20px 0;
        }
        @media #{$xs-layout} {
          font-size: 30px;

          margin: 0 20px 20px;
        }
        p {
          font-size: 18px;
          font-weight: 500;

          margin: 33px 0 0;

          text-transform: uppercase;

          // color: #fff;
          @media #{$md-layout} {
            margin: 20px 0 0;
          }
          @media #{$xs-layout} {
            margin: 10px 0 0;
          }
        }
      }
    }
  }
}

.slider-btn-12 {
  a {
    font-size: 16px;
    line-height: 1;
    z-index: 1;
    margin: 5px 5px;
    display: inline-block;
    padding: 17px 47px 19px;
    text-transform: uppercase;
    color: #fff;
    background-color: #e90042;
    margin: 0 5px;
    @media #{$xs-layout} {
      padding: 13px 30px 15px;
    }
    &:hover {
      border: 1px solid $theme-color;
    }
  }
}

.slider-content-16 {
  @media #{$xs-layout} {
    text-align: center;
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  h5 {
    //font-size: 18px;
    font-weight: 500;
    position: relative;
    margin-bottom: 0;
    text-transform: uppercase;
    color: #000000;
    @media #{$xs-layout} {
      margin-left: 0;
    }
    @media #{$sm-layout} {
      margin-left: 60px;
    }

    span {
      // color: #fed700;
    }

    &.no-style {
      margin-left: 0;
      @media #{$sm-layout} {
        margin-left: 0;
      }
      &:before {
        display: none;
      }
    }
  }
  h1 {
    //font-size: 90px;
    font-weight: 600;
    line-height: 1em;
    margin: 30px 0 40px 0;
    color: #3c3a3a;
    @media #{$xl-layout} {
      font-size: 60px;
      line-height: 80px;
    }
    @media #{$lg-layout} {
      font-size: 56px;
      line-height: 70px;
    }
    @media #{$md-layout} {
      font-size: 45px;
      line-height: 60px;
    }
    @media #{$xs-layout} {
      font-size: 35px;
      line-height: 45px;
    }
  }
  .slider-btn {
    a {
      font-size: 16px;
      line-height: 1;
      margin: 5px 5px;
      display: inline-block;
      padding: 19px 50px 21px;
      text-transform: uppercase;
      color: #010101;
      background-color: #eeeff1;
      margin: 5px 5px;
      @media #{$xs-layout} {
        padding: 14px 30px 16px;
      }
      &:hover {
        color: #fff;
        border: 1px solid #fed700;
      }

      &:after {
        background-color: #fed700;
      }
    }
  }
  .slider-btn-16 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
}

.slider-height-18 {
  height: 460px;
  @media #{$xs-layout} {
    background-position: 85%;
  }
  &.slider-overly-res {
    @media #{$xs-layout} {
      position: relative;

      height: 400px;
      &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: "";

        opacity: 0.5;
        background-color: #fff;
      }
    }
  }
}

.slider-banner-area {
  margin-top: 163px;
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    margin-top: 0;
  }
}
@media #{$md-layout} {
  .res-mrg-md-mb {
    margin-bottom: 30px;
  }
}
@media #{$xs-layout} {
  .res-mrg-md-mb {
    margin-bottom: 30px;
  }
}

.ht-swiper-button-nav {
  &:after {
    font-size: 0;
  }
}

.slider-active-3 {
  .ht-swiper-button-nav {
    font-size: 40px;

    visibility: hidden;

    padding: 0;

    opacity: 0;
    color: #333;
    border: none;
    background: none;
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;

      opacity: 1;
    }
  }
}

.slider-content-medical {
  @media #{$xs-layout} {
    margin-top: 0;
    margin-bottom: 30px;

    text-align: center;
  }
  @media #{$sm-layout} {
    text-align: left;
  }
  p {
    font-size: 20px;
    padding: 3px 3px;
    color: $theme-color;
  }
  h3 {
    font-family: var(--font-family);
    //font-size: 18px;
    font-weight: 600;
    line-height: 1;

    margin: 0;

    color: #fff;
  }
  h1 {
    font-family: var(--font-family);
    //font-size: 72px;
    font-weight: bold;
    line-height: 82px;

    margin: 24px 0 39px;

    letter-spacing: -0.2px;

    color: #fff;
    @media #{$lg-layout} {
      font-size: 52px;
      line-height: 62px;

      margin: 20px 0 30px;
    }
    @media #{$md-layout} {
      font-size: 42px;
      line-height: 52px;

      margin: 18px 0 24px;
    }
    @media #{$xs-layout} {
      font-size: 32px;
      line-height: 42px;

      margin: 10px 0 20px;
    }
  }
}

.slider-btn-medical {
  a {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin: 0 5px;
    display: inline-block;
    margin: 5px 5px;
    padding: 18px 40px 16px;

    color: #000;
    border: 1px solid transparent;
    background-color: #fff;
    @media #{$xs-layout} {
      font-size: 16px;

      padding: 14px 30px 12px;
    }
  }
}

.slider-content-red {
  @media #{$xs-layout} {
    margin-top: 0;
    margin-bottom: 30px;

    text-align: center;
  }
  @media #{$sm-layout} {
    text-align: left;
  }

  h3 {
    font-family: var(--font-family);
    //font-size: 18px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    color: #ff0000;
  }
  p {
    color: $theme-color;
  }
  h1 {
    font-family: var(--font-family);
    //font-size: 72px;
    font-weight: bold;
    line-height: 82px;
    margin: 24px 0 39px;
    letter-spacing: -0.2px;
    color: #313131;
    @media #{$lg-layout} {
      font-size: 52px;
      line-height: 62px;
      margin: 20px 0 30px;
    }
    @media #{$md-layout} {
      font-size: 42px;
      line-height: 52px;

      margin: 18px 0 24px;
    }
    @media #{$xs-layout} {
      font-size: 32px;
      line-height: 42px;

      margin: 10px 0 20px;
    }
  }
}

.slider-btn-red {
  a {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin: 0 5px;
    display: inline-block;
    margin: 5px 5px;
    padding: 18px 40px 16px;

    color: #fff;
    border: 1px solid transparent;
    background-color: #ff0000;
    @media #{$xs-layout} {
      font-size: 16px;

      padding: 14px 30px 12px;
    }
    &:after {
      background: #313131;
    }
    &:hover {
      border-color: #313131;
    }
  }
}

.slider-content-brown {
  @media #{$xs-layout} {
    margin-top: 0;
    margin-bottom: 30px;

    text-align: center;
  }
  @media #{$sm-layout} {
    text-align: left;
  }

  p {
    color: $theme-color;
      &:hover {
        color:$themehover-color;
      }
  }

  h1 {
    //font-size: 18px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    margin-bottom: 50px;
    color: #313131;
    span {
      color: #bca487;
    }
  }
  h3 {
    //font-size: 72px;
    font-weight: bold;
    line-height: 82px;
    margin: 24px 0 39px;
    letter-spacing: -0.2px;
    color: #313131;
    span {
      color: #bca487;
    }
    @media #{$lg-layout} {
      font-size: 52px;
      line-height: 62px;

      margin: 20px 0 30px;
    }
    @media #{$md-layout} {
      font-size: 42px;
      line-height: 52px;

      margin: 18px 0 24px;
    }
    @media #{$xs-layout} {
      font-size: 32px;
      line-height: 42px;

      margin: 10px 0 20px;
    }
  }
}

.slider-btn-brown {
  a {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin: 0 5px;
    display: inline-block;
    margin: 5px 5px;
    padding: 18px 40px 16px;

    color: #fff;
    border: 1px solid transparent;
    background-color: #bca487;
    @media #{$xs-layout} {
      font-size: 16px;

      padding: 14px 30px 12px;
    }
    &:after {
      background: #313131;
    }
    &:hover {
      border-color: #313131;
    }
  }
}

.slider-content-blue {
  @media #{$xs-layout} {
    margin-top: 0;
    margin-bottom: 30px;

    text-align: center;
  }
  @media #{$sm-layout} {
    text-align: left;
  }
  p {
    color: $theme-color;
      &:hover {
        color:$themehover-color;
      }
  }
  h1 {
    //font-size: 18px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    margin-bottom: 50px;
    color: #313131;
    
  }
  h3 {
    //font-size: 86px;
    font-weight: 500;
    line-height: 1;
    margin: 24px 0 39px;
    letter-spacing: -0.2px;
    color: #3c3a3a;
    
    @media #{$lg-layout} {
      font-size: 52px;
      line-height: 62px;

      margin: 20px 0 30px;
    }
    @media #{$md-layout} {
      font-size: 42px;
      line-height: 52px;

      margin: 18px 0 24px;
    }
    @media #{$xs-layout} {
      font-size: 32px;
      line-height: 42px;

      margin: 10px 0 20px;
    }
  }
}

.slider-btn-blue {
  a {
    font-size: 16px;
    line-height: 1;
    margin: 5px 5px;
    display: inline-block;

    padding: 18px 40px 16px;
    color: #3c3a3a;
    border: 1px solid #3c3a3a;
    background-color: transparent;
    @media #{$xs-layout} {
      font-size: 16px;

      padding: 14px 30px 12px;
    }
    &:after {
      background: #ffb893;
    }
    &:hover {
      border-color: #ffb893;
    }
  }
}

.single-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 890px;

  @media #{$xx-layout,
  $xl-layout,
  $lg-layout,
  $md-layout} {
    height: 600px;
  }
  @media #{$xs-layout} {
    height: 500px;
  }

  &__content {
    padding: 120px 30px;

    text-align: center;

    background-color: rgba(255, 255, 255, 0.5);

    @media #{$xs-layout} {
      padding: 80px 30px;
    }
    span {
      font-size: 20px;
      cursor: pointer;
      padding: 3px 3px;
      color: $theme-color;
      &:hover {
        color: #331b03;
      }
    }
    button {
      font-size: 20px;
      line-height: 1;
      text-align: center;
      padding: 13px 13px;
      background-color: $theme-color;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #fff;
      border: 1px solid #fff;
      &:hover {
        background-color: #800200;
        color: #fff;
        border: 1px solid $theme-color;
      }
    }
    .title {
      //font-size: 72px;
      font-weight: 600;
      line-height: 1.4;
      color: #000;
      @media #{$lg-layout,
      $md-layout} {
        font-size: 60px;
      }

      @media #{$xs-layout} {
        font-size: 50px;
      }

      @media #{$xxs-layout} {
        font-size: 30px;
      }
    }

    .subtitle {
      //font-size: 24px;
      font-weight: 400;
      line-height: 1.3;
      margin-bottom: 50px;
      color: #000;
      @media #{$xxs-layout} {
        font-size: 18px;
      }
    }

    .button {
      font-size: 14px;
      line-height: 1;
      display: inline-block;
      padding: 10px 20px;
      color: #000;
      border: 1px solid #000;
      border-radius: 2px;
      &:hover {
        color: #fff;
        border-color: #bca487;
        background-color: #bca487;
      }
    }
  }
}

.slider-content-32 {
  position: relative;
  z-index: 9;
  .content-img {
    img {
      width: auto;
    }
  }
  button {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 13px 13px;
    background-color: $theme-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background-color: #800200;
      color: #fff;
      border: 1px solid $theme-color;
    }
  }
  p {
    color: $theme-color;
  }
  h1 {
    font-family: var(--font-family);
    //font-size: 70px;
    line-height: 90px;
    margin: 12px 0 50px;
    color: #010101;
    @media #{$lg-layout} {
      font-size: 60px;
      line-height: 80px;

      margin: 12px 0 35px;
    }
    @media #{$md-layout} {
      font-size: 50px;
      line-height: 65px;

      margin: 12px 0 35px;
    }
    @media #{$xs-layout} {
      font-size: 40px;
      line-height: 47px;

      margin: 12px 0 30px;
    }
  }
  .slider-btn-32 {
    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      margin: 5px 5px;
      padding: 19px 50px 19px;
      text-transform: uppercase;
      color: #010101;
      border: 1px solid #333;
      &:hover {
        color: #fff;
        border: 1px solid #a749ff;
      }
      @media #{$md-layout} {
        padding: 15px 35px 15px;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        padding: 12px 30px 12px;
      }
    }
  }
}
.valentine-btn {
  a {
    font-size: 16px;
    line-height: 1;
    display: inline-block;
    overflow: hidden;
    margin: 0 5px;
    padding: 16px 40px;
    color: #fff;
    border-radius: 50px;
    background-color: #d70056;
    @media #{$xs-layout} {
      font-size: 14px;
      padding: 12px 30px;
    }
  }
}
.product-large-image-wrapper .single-image {
  background-color: #fff;
}
.productSlider {
  .product-large-image-wrapper .single-image {
    min-height: unset;
    background-color: #fff;
  }
}
.edit-category-inline {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 99;
  h6 {
    margin-bottom: 3px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    color: $theme-color;
    &:hover {
      color: #331b03;
    }
  }
}

// .swiper-container-fade
// .swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active{}

.swiper-container-fade {
  .swiper-slide {
    opacity: 0 !important;
  }
  .swiper-slide-active {
    opacity: 1 !important;
    .swiper-slide-active {
      opacity: 1 !important;
    }
  }
}

.height-300 {
  height: 300px !important;
}

.height-400 {
  height: 400px !important;
  @media #{$xs-layout} {
    height: 300px !important;
  }
}

.height-500 {
  height: 500px !important;
  @media #{$md-layout} {
    height: 400px !important;
  }
  @media #{$sm2-layout} {
    height: 400px !important;
  }
  @media #{$xs-layout} {
    height: 300px !important;
  }
}

.height-600 {
  height: 600px !important;
  @media #{$md-layout} {
    height: 450px !important;
  }
  @media #{$sm2-layout} {
    height: 400px !important;
  }
  @media #{$xs-layout} {
    height: 300px !important;
  }
}

.height-700 {
  height: 700px !important;
  @media #{$lg-layout} {
    height: 600px !important;
  }
  @media #{$md-layout} {
    height: 450px !important;
  }
  @media #{$sm2-layout} {
    height: 400px !important;
  }
  @media #{$xs-layout} {
    height: 300px !important;
  }
}

.height-800 {
  height: 800px !important;
  @media #{$lg-layout} {
    height: 650px !important;
  }
  @media #{$md-layout} {
    height: 450px !important;
  }
  @media #{$xs-layout} {
    height: 300px !important;
    padding: 30px 15px;
  }
  @media #{$sm2-layout} {
    height: 400px !important;
    padding: 30px 15px;
  }
}

.banner_full {
  margin: 0 !important;
  width: 100%;
  .slider_item {
    width: 100% !important;
  }
}

// banner_default
.banner_default {
  margin-bottom: 0 !important;
  &.multi_content {
    .height-300 {
      @media #{$xs-layout} {
        height: auto !important;
      }
    }
    .height-400 {
      @media #{$xs-layout} {
        height: auto !important;
      }
    }
    .height-500 {
      @media #{$xs-layout} {
        height: auto !important;
      }
    }
    .height-600 {
      @media #{$xs-layout} {
        height: auto !important;
      }
    }
    .height-700 {
      @media #{$xs-layout} {
        height: auto !important;
      }
    }
    .height-800 {
      @media #{$xs-layout} {
        height: auto !important;
      }
    }

    .img_block {
      max-width: 350px;
      width: 100%;
      margin-left: auto;
      padding: 0;
      @media #{$xs-layout} {
        max-width: 100%;
        margin: 20px auto 0;
      }
      & > div {
        margin: 0;
      }
      .img-small {
        max-width: 350px;
        width: 100%;
        @media #{$xs-layout} {
          margin: 0 auto;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .single-price-wrap {
        @media #{$xs-layout} {
          width: 100px;
        }
        .single-price {
          span {
            @media #{$xs-layout} {
              font-size: 18px;
            }
          }
        }
      }
    }
    .container {
      @media #{$md-layout} {
        margin: 0 auto;
      }
      @media #{$xs-layout} {
        margin: 0 auto;
      }
      & > .row {
        @media #{$xs-layout} {
          flex-direction: column;
          align-items: center;
        }
        .caption_block {
          @media #{$xs-layout} {
            max-width: 100%;
          }
          & > div {
            @media #{$xs-layout} {
              width: 100%;
              margin-left: 0;
            }
          }
          p {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .container {
    & > .row {
      margin: 0;
      .slider-btn {
        a {
          margin: 5px 5px;
          padding: 15px 40px;
          @media #{$xs-layout} {
            font-size: 15px;
            padding: 12px 30px;
          }
        }
      }
      .edit-inline {
        @media #{$xs-layout} {
          justify-content: center;
        }
        .ql-container {
          padding: 0 !important;
          .ql-editor {
            padding: 0 !important;
          }
        }
      }
      .slider-content-3 {
        @media #{$xs-layout} {
          margin: 0 auto;
        }
      }
    }
  }
  h1 {
    line-height: 1.4 !important;
    margin: 0 0 30px !important;
    @media #{$lg-layout} {
      font-size: 36px;
    }
    @media #{$md-layout} {
      font-size: 30px;
    }
    @media #{$xs-layout} {
      font-size: 24px;
      margin: 0 0 25px !important;
    }
  }
  h2 {
    margin: 0 0 30px !important;
    @media #{$lg-layout} {
      font-size: 28px;
    }
    @media #{$md-layout} {
      font-size: 28px;
    }
    @media #{$xs-layout} {
      font-size: 24px;
      margin: 0 0 25px !important;
    }
  }
  h3 {
    font-size: 28px;
    line-height: 1.4;
    margin: 0 0 30px !important;
    @media #{$xs-layout} {
      font-size: 24px;
      margin: 0 0 25px !important;
    }
  }
  h4 {
    font-size: 26px;
    margin: 0 0 30px !important;
    @media #{$xs-layout} {
      font-size: 22px;
      margin: 0 0 25px !important;
    }
  }
  h5 {
    font-size: 24px;
    margin: 0 0 30px !important;
    @media #{$xs-layout} {
      font-size: 20px;
      margin: 0 0 25px !important;
    }
  }
  h6 {
    font-size: 22px;
    margin: 0 0 30px !important;
    @media #{$xs-layout} {
      font-size: 20px;
      margin: 0 0 25px !important;
    }
  }

  // slider-content-2
  .slider-content-2 {
    @media #{$xs-layout} {
      margin: 0 auto;
    }
    h1 {
      @media #{$xl-layout} {
        font-size: 45px;
      }
      @media #{$lg-layout} {
        font-size: 36px;
      }
      @media #{$md-layout} {
        font-size: 32px;
      }
      @media #{$xs-layout} {
        font-size: 24px;
      }
    }
    p {
      color: $theme-color;
    }
    .slider-content-flower {
      h1 {
        @media #{$xl-layout} {
          font-size: 45px;
        }
        @media #{$lg-layout} {
          font-size: 36px;
        }
        @media #{$md-layout} {
          font-size: 32px;
        }
        @media #{$xs-layout} {
          font-size: 24px;
        }
      }
    }
  }

  // slider-content-3
  .slider-content-3 {
    @media #{$xs-layout} {
      margin: 0 auto;
    }
    h1 {
      @media #{$xl-layout} {
        font-size: 45px;
      }
      @media #{$lg-layout} {
        font-size: 36px;
      }
      @media #{$md-layout} {
        font-size: 32px;
      }
      @media #{$xs-layout} {
        font-size: 24px;
      }
    }
  }

  // slider-content-5
  .slider-content-5 {
    @media #{$xs-layout} {
      margin: 0 auto;
    }
    .edit-inline {
      @media #{$xs-layout} {
        justify-content: center;
      }
      .ql-container {
        padding: 0 !important;
        .ql-editor {
          padding: 0 !important;
        }
      }
    }
    h1 {
      margin: 0 0 30px !important;
      @media #{$xs-layout} {
        font-size: 24px;
        margin: 0 0 25px !important;
      }
      @media #{$md-layout} {
        font-size: 30px;
      }
    }
    h3 {
      margin: 0 0 30px !important;
      @media #{$xs-layout} {
        font-size: 24px;
        margin: 0 0 20px !important;
      }
    }
  }

  // slider-content-7
  .slider-content-7 {
    h1 {
      @media #{$xl-layout} {
        font-size: 40px;
      }
      @media #{$lg-layout} {
        font-size: 36px;
      }
      @media #{$md-layout} {
        font-size: 32px;
      }
      @media #{$xs-layout} {
        font-size: 24px;
      }
    }
  }

  // slider-content-10
  .slider-content-10 {
    h1 {
      @media #{$lg-layout} {
        font-size: 36px;
      }
      @media #{$md-layout} {
        font-size: 32px;
      }
      @media #{$xs-layout} {
        font-size: 24px;
      }
    }
  }

  // slider-content-13
  .slider-content-13 {
    @media #{$xs-layout} {
      margin: 0 auto;
    }
  }

  // slider-content-15
  .slider-content-15 {
    margin: 0 auto;
    h1 {
      margin-top: 0 !important;
      @media #{$xl-layout} {
        font-size: 45px;
      }
      @media #{$lg-layout} {
        font-size: 36px;
      }
      @media #{$md-layout} {
        font-size: 32px;
      }
      @media #{$xs-layout} {
        font-size: 24px;
      }
    }
    .edit-inline {
      justify-content: center;
    }
  }

  // slider-content-16
  .slider-content-16 {
    @media #{$xs-layout} {
      margin: 0 auto;
    }
    p {
      color: $theme-color;
    }
    h1 {
      @media #{$xl-layout} {
        font-size: 45px;
      }
      @media #{$lg-layout} {
        font-size: 36px;
      }
      @media #{$md-layout} {
        font-size: 32px;
      }
      @media #{$xs-layout} {
        font-size: 24px;
      }
    }
  }
}

// banner_summer
.banner_spring {
  .slider_item {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    #myVideo {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .container {
      .slider-content {
        padding: 0;
        h1 {
          line-height: 1.4 !important;
          margin: 0 0 30px !important;
          @media #{$lg-layout} {
            font-size: 36px;
          }
          @media #{$md-layout} {
            font-size: 30px;
          }
          @media #{$xs-layout} {
            font-size: 24px;
            margin: 0 0 25px !important;
          }
        }
        .slider-btn {
          a {
            margin: 5px 5px;
            padding: 15px 40px !important;
            @media #{$xs-layout} {
              font-size: 15px !important;
              padding: 12px 30px !important;
            }
          }
        }
      }
    }
  }
}

// banner_winter
.banner_winter {
  @media #{$lg-layout} {
    margin-top: 88px !important;
  }
  @media #{$md-layout} {
    margin-top: 0 !important;
  }
  @media #{$xs-layout} {
    margin-top: 0 !important;
  }
}

// banner_menuOverlay
.banner_menuOverlay {
  .swiper-wrapper {
    .slider_item {
      display: flex;
      align-items: center;
      @media #{$lg-layout} {
        margin-top: 0 !important;
      }
      @media #{$md-layout} {
        margin-top: 0 !important;
      }
      @media #{$xs-layout} {
        margin-top: 0 !important;
      }
    }
  }
}

// banner_productCenterd
.banner_productCenterd {
  .swiper-wrapper {
    .slider_item {
      margin-top: 0 !important;
    }
  }
}

// banner_largeProduct
.banner_largeProduct {
  .slider-content-2 {
    p {
      color: $theme-color;
        &:hover {
          color:$themehover-color;
        }
    }
    &.slider-content-fruits {
      h1 {
        @media #{$xl-layout} {
          font-size: 45px;
        }
        @media #{$lg-layout} {
          font-size: 36px;
        }
        @media #{$md-layout} {
          font-size: 32px;
        }
        @media #{$xs-layout} {
          font-size: 24px;
        }
      }
    }
  }
}

// banner_deadline
.banner_deadline {
  .dealy-style-2 {
    .timer {
      div {
        @media #{$xs-layout} {
          margin-top: 10px;
        }
        span {
          color: $themetext-color;
          @media #{$md-layout} {
            font-size: 37px !important;
            margin: 0 15px 15px;
          }
          @media #{$xs-layout} {
            font-size: 22px !important;
            margin: 0 10px 15px;
          }
          @media #{$sm2-layout} {
            font-size: 26px !important;
            margin: 0 15px 15px;
          }
          &:first-child {
            @media #{$xs-layout} {
              margin-left: 0;
            }
          }
          &:last-child {
            @media #{$xs-layout} {
              margin-right: 0;
            }
          }
          p {
            @media #{$md-layout} {
              font-size: 22px !important;
              margin-top: 10px;
            }
            @media #{$xs-layout} {
              font-size: 15px !important;
              margin-top: 5px;
            }
            @media #{$sm2-layout} {
              font-size: 20px !important;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

// christmus_banner
.christmus_banner {
  @media #{$xs-layout} {
    padding: 0 0 110px;
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    & > .row {
      @media #{$xs-layout} {
        flex-direction: column;
      }
      .content_block {
        .edit-inline {
          @media #{$xs-layout} {
            justify-content: center;
          }
        }
        .ql-container {
          @media #{$xs-layout} {
            min-height: auto;
          }
          h1 {
            @media #{$xs-layout} {
              font-size: 28px;
            }
          }
          h3 {
            @media #{$xs-layout} {
              text-align: center;
            }
          }
        }
        h1 {
          line-height: 1.4 !important;
          margin: 0 0 30px !important;
          @media #{$lg-layout} {
            font-size: 36px;
          }
          @media #{$md-layout} {
            font-size: 30px;
          }
          @media #{$xs-layout} {
            font-size: 24px;
            margin: 0 0 25px !important;
          }
        }
        p {
          color: $theme-color;
        }
        .slider-btn-Christmas {
          a {
            font-size: 16px;
            line-height: 1;
            display: inline-block;
            margin: 5px 5px;
            padding: 19px 50px 19px;
            text-transform: uppercase;
            color: #010101;
            border: 1px solid #333;
            &:hover {
              color: #fff;
              border: 1px solid #a749ff;
            }
            @media #{$md-layout} {
              padding: 15px 35px 15px;
            }
            @media #{$xs-layout} {
              font-size: 14px;      
              padding: 12px 30px 12px;
            }
          }
        }
      }
      .img_block {
        @media #{$xs-layout} {
          max-width: 100%;
        }
        .edit-img {
          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
  }
}

// banner_special_events
.banner_special_events {
  .container {
    & > .row {
      .slider-content-32 {
        @media #{$xs-layout} {
          margin: 0 auto;
        }
        h1 {
          @media #{$xs-layout} {
            font-size: 28px;
          }
        }
      }
      .edit-inline {
        .content-img {
        }
      }
      .content-img {
        max-width: 330px;
        width: 100%;
        @media #{$xs-layout} {
          max-width: 250px;
          margin: 0 auto;
        }
        img {
          width: 100%;
          display: block;
          height: auto;
        }
      }
    }
  }
}

// banner_split
.banner_split {
  .slider-content-7 {
    @media #{$xs-layout} {
      margin: 0 auto;
      .edit-inline {
        @media #{$xs-layout} {
          justify-content: center;
        }
        .ql-container {
          @media #{$xs-layout} {
            min-height: unset;
          }
          .ql-editor {
            h3 {
              padding-top: 0 !important;
              padding-bottom: 0 !important;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

// banner_highlight
.single-slide{
  &.single-slide-banner{
    overflow: hidden;
    height: auto;
  }
  &.bg-img{
    position: relative;
    .single-slide-img-hover{
      position: relative;
      // &::after{
        // content: "";
        position: absolute;
        width: 0%;
        height: 0%;
        background-color: rgba(255, 255, 255, 0.5);
        left: 50%;
        top: 50%;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: translate(-50%, -50%);
      // }
    }
    &:hover{
      .single-slide-img-hover{
        // &::after{
          width: 100%;
          height: 100%;
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.5);
        // }
      }
    }
  }
}


  .single-slide__content {
    @media #{$md-layout} {
      padding: 80px 30px;
    }
    @media #{$xs-layout} {
      padding: 30px;
    }
    @media #{$sm2-layout} {
      padding: 50px 30px;
    }
    p {
      color: $theme-color;
        &:hover {
          color:$themehover-color;
        }
    }
    h2 {
      @media #{$lg-layout} {
        font-size: 35px;
      }
      @media #{$md-layout} {
        font-size: 28px;
      }
      @media #{$xs-layout} {
        font-size: 24px;
      }
    }
  }

.sliderBtn {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  &.swiper-button-prev {
    left: 0 !important;
  }
  &.swiper-button-next {
    right: 0 !important;
  }
}

.collection-active {
  .swiper-container {
    width: 90%;
    padding-left: 0.5% !important;
  }
  .swiper-wrapper {
    width: 90%;
  }
}
.product-large-img{
  .swiper-container {
    padding: 0;
  }
}
.slider-area .swiper-container{
  padding: 0;
}
.product-area .swiper-container{
  padding: 0;
}
.related-product-area .swiper-container{
  padding: 0;
}

.product-slider-area {
    .swiper-container {
        width: 100%;
    }
}
.tagged-product-area {
    .swiper-wrapper {
        justify-content: center;
    }
}

.slider-area{
  .swiper-wrapper{
    .slider_item{
      transition:all 0.2s ease-in-out;
      height: auto;
      .bg-img-block{
        height: 100%;
        img{
          object-fit: contain;
        }
      }
    }
  }
}

.winter-slider-area{
  .swiper-container{
    padding: 0;
  }
}

.related-product-area{
  .section-title{
    p{
      color: inherit;
      font-size: inherit;
      line-height: normal;
    }
  }
  .swiper-container{
    .swiper-wrapper{
      .swiper-slide{
        .product-img{
          img{
            max-width: 100% !important;
            max-height: 100% !important;
            width: 100% !important;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.customSliderwrapper {
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
}

.customSliderwrapper .items {
  width: 20%;
  @media #{$xl-layout} {
    width: 20%;
  }
  @media #{$lg-layout} {
    width: 20%;
  }
  @media #{$md-layout} {
    width: 33.33%;
  }
  @media #{$xs-layout} {
    width: 33.33%;
  }
}
.categories-slider-container {
  @media screen and (max-width:1310px) {
    .custome-swiper-btn.swiper-button-prev1 {
      left: 0px;
    }

    .custome-swiper-btn.swiper-button-next1 {
      right: 0px
    }

    .customSliderwrapper {
      padding: 0 20px;
    }
  }

  @media screen and (max-width:1199px) {
    .customSliderwrapper {
      padding: 0 35px;
      gap: 20px;
    }
  }

  .slidWrap {
    padding: 0 40px;
  }
}
