.dark-theme {
    background-color: #1b1b1b;
    color: #ffffff;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #ffffff;
    }

    p {
        color: #ffffff;
    }

    a {
        color: #ffffff;
    }

    .main-menu{
        nav{
            ul{
                li{
                    a {
                        color: #e1e1e1;
                    }
                }
            }
        }
    }

    .header-top-center-con{
        p{
            color: inherit;
        }
    }

    .header-offer{
        p{
            color: inherit;
        }
    }

    .single-testimonial-2 .client-info i {
        color: #ffffff;
    }

    .single-testimonial-2 .client-info h5 {
        color: #ffffff;
    }

    .single-testimonial-2 .client-info span {
        color: #ffffff;
    }

    .clickable-mainmenu {
        background-color: #1b1b1b;
        color: #ffffff;
    }

    .sidebar-menu nav ul li a {
        color: #ffffff;
    }

    .sidebar-menu nav ul li ul.submenu-left, .sidebar-menu nav ul li ul.submenu-right {
        background: #1b1b1b none repeat scroll 0 0;
        color: #ffffff;
    }

    .sidebar-menu nav ul li ul.submenu-left li a, .sidebar-menu nav ul li ul.submenu-right li a {
        color: #ffffff;
    }

    .sticky-bar.stick {
        background-color: #1b1b1b;
        box-shadow: 0 0 25px 0 rgb(255 255 255 / 7%);
        color: #ffffff;
    }

    .header-hm-7.stick .clickable-menu a,
    .header-hm-7.stick .clickable-menu button {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    [class*=" pe-7s-"],
    [class^=pe-7s-] {
        color: #ffffff;
    }

    .sticky-bar.stick .header-right-wrap-white .same-style.cart-wrap>button span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.cart-wrap>a span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.header-compare>button span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.header-compare>a span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.header-wishlist>button span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.header-wishlist>a span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.account-setting>button span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.account-setting>a span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.header-search>button span.count-style,
    .sticky-bar.stick .header-right-wrap-white .same-style.header-search>a span.count-style {
        color: #1b1b1b;
        background-color: #ffffff;
    }

    .boldcolor {
        color: #ffffff;
    }

    select {
        color: #ffffff;
        background: #1b1b1b;

        option {
            background: #1b1b1b;
            color: #ffffff;
        }
    }

    .pro-sidebar-search .pro-sidebar-search-form input {
        color: #ffffff;
    }

    .shop-top-bar .select-shoing-wrap .shop-select select {
        background-color: #1b1b1b;
        color: #ffffff;
    }

    .shop-top-bar .select-shoing-wrap p {
        color: #ffffff;
    }

    .shop-top-bar .shop-tab a,
    .shop-top-bar .shop-tab button {
        color: #ffffff;
    }

    .shop-top-bar .show-select select {
        background-color: #1b1b1b;
        color: #ffffff;
    }

    .product-wrap .product-content .product-price span,
    .product-list-image-wrap .product-content .product-price span {
        color: #ffffff;
    }

    .cart-table-content table tbody>tr td.product-name a {
        color: #ffffff;
    }

    .cart-table-content table tbody>tr td {
        color: #ffffff;
    }

    .compare-table .table tbody tr td.product-price {
        color: #ffffff;
    }

    .compare-table .table tbody tr td.product-rating i {
        color: #ffffff;
    }

    .sizediv .bggray {
        background-color: #1b1b1b;
    }

    .iconDisplaystyle .MuiSvgIcon-root {
        color: #ffffff;
    }

    .MuiSvgIcon-root {
        color: #ffffff;
    }

    .description-review-topbar .nav-item a.active {
        color: #ffffff;
    }

    .ratting-form-wrapper .ratting-form form .rating-form-style input,
    .ratting-form-wrapper .ratting-form form .rating-form-style textarea {
        color: #ffffff;
    }

    .MuiButton-root {
        color: #ffffff;
    }

    .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
    .header-right-wrap .same-style.header-wishlist .shopping-cart-content,
    .header-right-wrap .same-style.header-compare .shopping-cart-content {
        background: #1b1b1b none repeat scroll 0;
        color: #ffffff;
        border: 1px solid #ffffff
    }

    .offcanvas-mobile-menu{
        .header-right-wrap{
            .same-style{
                i, svg{
                    color: #000;
                }
            }
        }    
    }

    a.default-btn,
    button.default-btn {
        color: #ffffff !important;
        border: 1px solid #ffffff !important;
    }

    .billing-info-wrap .billing-info label,
    .billing-info-wrap .billing-select label {
        color: #ffffff;
    }

    .billing-info-wrap .billing-info input,
    .billing-info-wrap .billing-select input {
        color: #ffffff;
    }

    .your-order-area .your-order-wrap {
        background-color: #1b1b1b;
        color: #ffffff;
    }

    .your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul li.your-order-shipping {
        color: #ffffff;
    }

    .your-order-area .your-order-wrap .your-order-product-info .your-order-total ul li.order-total {
        color: #ffffff;
    }

    .form-control:disabled,
    .form-control[readonly] {
        color: #ffffff;
        background-color: #a4b2c17d !important;
    }

    .form-control {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    #ccnumfield {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .cart-tax,
    .discount-code-wrapper {
        background-color: #1b1b1b;
        color: #ffffff;
    }

    .cart-tax .tax-wrapper .tax-select-wrapper .tax-select label,
    .discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select label {
        color: #ffffff;
    }

    .cart-tax .title-wrap h4.cart-bottom-title,
    .discount-code-wrapper .title-wrap h4.cart-bottom-title {
        background-color: #1b1b1b;
    }

    .cart-tax .title-wrap h4.cart-bottom-title,
    .discount-code-wrapper .title-wrap h4.cart-bottom-title {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .cart-tax .tax-wrapper .tax-select-wrapper .tax-select input,
    .discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select input {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .cart-tax .tax-wrapper .tax-select-wrapper .tax-select select,
    .discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select select {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .grand-totall {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .grand-totall .title-wrap .section-bg-gary-cart {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .input-file-option>input {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .header-top-area {
        color: #ffffff;
        background-color: #1b1b1b;
    }


    .product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a {
        color: #ffffff;
    }

    .product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span {
        color: #ffffff;
    }

    .video-popup__content .title {
        color: #ffffff;
    }

    .video-popup__content .text {
        color: #ffffff;
    }

    .product-tab-list a h4 {
        color: $themetext-color;
    }
    .product-tab-list a.active h4 {
        color: $themehover-color !important;
    }

    .header-top-wap {
        color: #ffffff;
    }

    .slider-btn-3 {
        a {
            color: #ffffff;
            background-color: #1b1b1b;

            &:hover {
                color: #1b1b1b;
                background-color: #ffffff;
            }
        }
    }

    .sidebar-widget h4.pro-sidebar-title {
        color: #ffffff;
    }

    .MuiPaper-root {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .sidebar-widget-tag ul li label,
    .sidebar-widget-tag ul li button {
        color: #ffffff;
    }

    .shop-list-wrap .shop-list-content h3 a {
        color: #ffffff;
    }

    .ratting-form-wrapper .ratting-form form .star-box span {
        color: #ffffff !important;
    }

    .modal-content {
        background-color: #1b1b1b;
        color: #ffffff;
    }

    .MuiButton-contained {
        color: #1b1b1b;
    }

    .modal-dialog .modal-header .close {
        color: #ffffff;
    }

    .footer-widget .subscribe-style.subscribe-style-white .subscribe-form input {
        color: #ffffff;
    }

    .personalizationTabel {
        color: #ffffff;
    }

    .personalizationTabel td input {
        color: #1b1b1b;

        &:focus {
            color: #ffffff;
        }
    }

    .single-review .review-top-wrap .review-left .review-rating i {
        color: #ffffff;
    }

    .inputStyle {
        color: #1b1b1b;

        &:focus {
            color: #ffffff;
        }
    }

    .cart-table-content table tbody>tr td.product-price-cart span {
        color: #ffffff;
    }

    .deleteBtn i.fa.fa-times {
        color: #ffffff;
    }

    .nav-pills .nav-link.active h4 {
        color: #ffffff !important;
    }

    .product-tab-list a h4:hover {
        color: #ffffff;
    }

    .sidebar-widget-tag .blogTags li label,
    .sidebar-widget-tag ul li button {
        color: #1b1b1b;
    }

    .sidebar-widget-tag .blogTags li label:hover {
        color: #ffffff;
    }

    .blog-wrap-2 .blog-content-2 {
        .blog-meta-2 ul li {
            color: #ffffff;
        }

        h4 a {
            color: #ffffff;
        }

        .blog-author {
            color: #969393;
        }

        .blog-share-comment .blog-btn-2 a {
            color: #ffffff;
        }

        .blog-share-comment .blog-share span {
            color: #ffffff;
        }

        p {
            color: #e5d8d8;
        }

        .blog-meta-2 ul li a {
            color: #ffffff;
        }
    }

    .blog-comment-wrapper .single-comment-wrapper {
        background-color: #434343;
    }

    .blog-reply-wrapper .blog-form .leave-form input,
    .blog-reply-wrapper .blog-form .text-leave textarea {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .blog-reply-wrapper {
        background-color: #434343;
    }

    .blog-details-top .blog-details-content .blog-meta-2 ul li {
        color: #ffffff;

        a {
            color: #ffffff;
        }
    }

    .order-table-content table {
        thead>tr {
            background-color: #1b1b1b;
        }

        tbody>tr td {
            color: #ffffff;
        }

        thead>tr th {
            color: #ffffff;
        }
    }

    .discount-code-wrapper1.w-100>div>input {
        background-color: #1b1b1b;
        color: #ffffff;
    }

    .sidebar-menu nav ul li ul.mega-menu {
        color: #ffffff;
        background-color: #1b1b1b;

        li ul li.mega-menu-title a {
            color: #ffffff;
        }

        li ul li a {
            color: #ffffff;
        }

        .imageUl li a {
            color: #ffffff;
        }
    }

    .filterInput {
        color: #1b1b1b;
        background-color: #ffffff;
    }

    .contact-form {
        background-color: #434343;

        .contact-title h2 {
            color: #ffffff;
        }

        .contact-form-style {

            input,
            textarea {
                color: #ffffff;
                background-color: #1b1b1b;
            }
        }
    }

    .contact-info-wrap {
        color: #ffffff;
        background-color: #434343;

        .single-contact-info {
            .contact-info-dec p {
                color: #ffffff;
            }

            .contact-info-dec p a {
                color: #ffffff;
            }

            .contact-icon i {
                color: #ffffff;
                border: 1px solid #ffffff;
            }
        }
    }

    .gm-err-container {
        background-color: #434343;
        color: #ffffff;

        .gm-err-title {
            color: #ffffff;
        }

        .gm-err-message {
            color: #ffffff;
        }
    }

    .contact-social ul li a {
        color: #ffffff;
    }

    .contact-form .contact-form-style button {
        color: #ffffff;
        background-color: #1b1b1b;
    }

    .blog-reply-wrapper .blog-form .text-leave button:hover {
        background-color: #1b1b1b;
    }

    .login-register-wrapper .login-form-container {
        background-color: #434343;

        .login-register-form form {

            input {
                color: #ffffff;
                background-color: #1b1b1b;
            }

            .button-box .login-toggle-btn {

                label,
                a {
                    color: #ffffff;
                }
            }

            .css-qc6sy-singleValue {
                color: #ffffff;
            }

            .css-b62m3t-container {
                background-color: #1b1b1b;
            }

            .css-1pndypt-Input {
                color: #ffffff;
            }

            .css-uab3em-control {
                color: #1b1b1b;
            }
        }

        #react-select-2-listbox {
            background-color: #1b1b1b;
        }
    }

    .notlogin-form {
        background-color: #1b1b1b;
    }

    .MuiDialogTitle-root {
        background-color: #222222;
    }
}