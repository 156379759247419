.banner-form {
    padding: 24px;

    background-color: #f3f3f3;
    @media #{$lg-layout} {
        padding: 50px 50px 50px 50px;
    }
    @media #{$md-layout} {
        padding: 50px 30px 50px 30px;
    }
    @media #{$xs-layout} {
        padding: 30px 30px 30px 30px;
    }

    .banner-form-style {
        min-width: 32rem;
        &.only-banner {
            max-width: 800px;
            .row {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        .bannerbutton {
            font-size: 14px;
            font-weight: 500;
            line-height: 1;

            margin-top: 38px;
            padding: 15px 52px;

            text-transform: uppercase;

            color: $themetext-color;
            border: none;
            background-color: $theme-color;
            &:hover {
                color: $themetext-color;
                background-color: $themehover-color;
            }
        }
        .cancelButton {
            font-size: 14px;
            font-weight: 500;
            line-height: 1;

            margin-top: 38px;
            padding: 15px 52px;

            text-transform: uppercase;

            color: #fff;
            border: none;
            background-color: #504a4a;
            &:hover {
                color: $themetext-color;
                background-color: $theme-color;
            }
        }
        .file-upload-wrapper {
            max-width: 300px;
        }
    }
    p {
        color: #333;
        &.success {
            margin-top: 10px;
        }
    }
}
