.featured-product{
    position: relative;
    overflow: hidden;
    display: flex;
    .shape-separate{
        background-color: #f1f0f0;
        position: absolute;
        left: 40%;
        top: -150px;
        bottom: -200px;
        width: 300px;
        transform: rotate(40deg);
        z-index: -1;
    }
    .edit-inline{
        flex: 0 0 205px;
        align-items: flex-start;
        img{
            height: auto !important;
        }
    }
    .product-name{
        padding-left: 12px;
        // position: absolute;
        // left: calc(40% + 170px);
        // right: 0;
        // top: 0;
        // bottom: 0;
        // z-index: 1;
        .gray-bk {
            background-color: #f1f0f0;
        }
        .product-details{
            // position: absolute;
            // left: -170px;
            // right: 0;
            p{
                font-size: 1rem;
            }
            .shop-btn{
                border: 1px solid #7c7c7c;
                border-radius: 8px;
                display: inline-block;
                padding: 8px 20px;
                background-color: #fff;
                color: #a749ff;
                &:hover{
                    background-color: #a749ff; 
                    color: #fff;
                }
            }
        }
    }
}
