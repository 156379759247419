 
.order-table-content {
  table {
    border: 1px solid #ebebeb;
    width: 100%;
    thead > tr {
      border: 1px solid #ebebeb;
      background-color: #f9f9f9;
      th {
        font-size: 14px;
        font-weight: 500;

        padding: 9px 9px 6px;

        text-align: left;
        vertical-align: top;
        white-space: nowrap;
        text-transform: uppercase;

        color: #333;
        border-top: medium none;
        @media #{$lg-layout} {
          padding: 9px 14px 6px;
        }
        @media #{$xs-layout} {
          padding: 9px 14px 6px;
        }
        p{
          margin-bottom: 0px;
        }
      }
    }
    tbody > tr {
      border-bottom: 1px solid #ebebeb;
      td {
        p{
          margin-bottom: 0px;
        }
        font-size: 15px;
        padding: 9px 9px 9px 9px;
        text-align: left;
        color: #333;
      }
    }
  }
  .inlinefexDecorationDiv{
    display: inline-flex;
    border: 1px solid lightgray;
    border-radius: 5px;
    text-align: center;
    width: 140px !important;
    max-width: 140px !important;
  }
  .decorows {
    align-items: center;
  }
  .MuiInputBase-root{
    font-size: inherit;
  }
  .deleteBtn{
    border: none;
    &:hover {
      color: $theme-color;
    }
  }
}
.order-page-title {
  padding:5px;
  background-color: lightgrey;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 0 0 15px;
}
.filterInput{
  max-width: 100px;
  height: 24px;
}
.checkout-area{
    .totalAmt{
        font-size: medium;
    font-weight: bold;
    }
    #exTab2 h3 {
        color : white;
        background-color: #428bca;
        padding : 5px 15px;
    }
    .nav-tabs {
        border-bottom: 1px solid #ddd;
    }
    nav {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    ul, ol {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical>.btn-group:before, .btn-group-vertical>.btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after {
        display: table;
        content: " ";
    }
    .nav-tabs>li {
        float: left;
        margin-bottom: -1px;
    }
    .nav>li {
        position: relative;
        display: block;
    }
    .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
        color: #555;
        cursor: default;
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
    }
    .nav-tabs>li>a {
        margin-right: 2px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
    }
    .nav>li>a {
        position: relative;
        display: block;
        padding: 10px 15px;
    }
    a {
        color: #e3e3e4;
        text-decoration: none;
    }
    a {
        background: 0 0;
    }
    .form-group{
        margin-bottom: 2px;
        label{
            margin-bottom: 0px;
        }
        .error{
            border-color: #e02f07;
        }
    }
    .paymentBtn{
        min-width: 352px;
        background-color: #000;
        color: white;
        &:hover{
            background-color:#252424;
            box-shadow: 5px 5px 10px #00000057;
        }
    }

  .paymentBtn{
    min-width: 352px;
    background-color: #000;
    color: white;
    &:hover{
      background-color:#252424;
      box-shadow: 5px 5px 10px #00000057;
    }
  }
}
.order-page-title-row {
  padding:5px;
  background-color: lightgrey;  
  border: 1px solid lightgrey;
  border-radius: 5px; 
}
.greenlabelstatus{
  color:green;
  font-weight: bold;
  font-size: 17px;
  margin-top: 0.5rem;
}
.redlabelstatus{
  color:red;
  font-weight: bold;
  font-size: 17px;
  margin-top: 0.5rem;
}
