/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
.ql-font-hurmebold,
.ql-font span[data-value="hurmebold"]::before {
  font-family: "Hurme Bold";
}
.ql-font-poppins,
.ql-font span[data-value="poppins"]::before {
  font-family: "Poppins", sans-serif;
}
.ql-font-abrilFatface,
.ql-font span[data-value="abrilFatface"]::before {
  font-family: "Abril Fatface", cursive;
}
.ql-font-abrilFatface,
.ql-font span[data-value="abrilFatface"]::before {
  font-family: "Abril Fatface", cursive;
}
.ql-font-cormorantGaramond,
.ql-font span[data-value="cormorantGaramond"]::before {
  font-family: "Cormorant Garamond";
}
.ql-font-greatVibes,
.ql-font span[data-value="greatVibes"]::before {
  font-family: "Great Vibes", cursive;
}
.ql-font-lobster,
.ql-font span[data-value="lobster"]::before {
  font-family: "Lobster", cursive;
}
.ql-font-josefinSans,
.ql-font span[data-value="josefinSans"]::before {
  font-family: "Josefin Sans", sans-serif;
}
.ql-font-ArialBlack,
.ql-font span[data-value="ArialBlack"]::before {
  font-family: 'Arial Black';
}
.ql-font-ArialNarrow,
.ql-font span[data-value="ArialNarrow"]::before {
  font-family: 'Arial Narrow';
}
.ql-font-Arimo,
.ql-font span[data-value="Arimo"]::before {
  font-family: 'Arimo';
}
.ql-font-GeometriaLight,
.ql-font span[data-value="GeometriaLight"]::before {
  font-family: 'Geometria Light';
}
.ql-font-HurmeRegular,
.ql-font span[data-value="HurmeRegular"]::before {
  font-family: 'Hurme Regular';
}
.ql-font-HurmeThin,
.ql-font span[data-value="HurmeThin"]::before {
  font-family: 'Hurme Thin';
}
.ql-font-Lato,
.ql-font span[data-value="Lato"]::before {
  font-family: 'Lato';
}
.ql-font-LucidaSans,
.ql-font span[data-value="LucidaSans"]::before {
  font-family: 'Lucida Sans';
}
.ql-font-Montserrat,
.ql-font span[data-value="Montserrat"]::before {
  font-family: 'Montserrat';
}
.ql-font-OpenSans,
.ql-font span[data-value="OpenSans"]::before {
  font-family: 'Open Sans';
}
.ql-font-OpenSansArial,
.ql-font span[data-value="OpenSansArial"]::before {
  font-family: "Open Sans", arial, sans-serif;
}
.ql-font-Oswald,
.ql-font span[data-value="Oswald"]::before {
  font-family: 'Oswald';
}
.ql-font-PTSansNarrow,
.ql-font span[data-value="PTSansNarrow"]::before {
  font-family: 'PT Sans Narrow';
}
.ql-font-Prompt,
.ql-font span[data-value="Prompt"]::before {
  font-family: 'Prompt';
}
.ql-font-Rajdhani,
.ql-font span[data-value="Rajdhani"]::before {
  font-family: 'Rajdhani';
}
.ql-font-Roboto,
.ql-font span[data-value="Roboto"]::before {
  font-family: 'Roboto';
}
.ql-font-RobotoCondensed,
.ql-font span[data-value="RobotoCondensed"]::before {
  font-family: 'Roboto Condensed';
}
.ql-font-Rockwell,
.ql-font span[data-value="Rockwell"]::before {
  font-family: 'Rockwell';
}
.ql-font-ShadowsIntoLight,
.ql-font span[data-value="ShadowsIntoLight"]::before {
  font-family: 'Shadows Into Light', cursive;
}
.ql-font-SourceSansPro,
.ql-font span[data-value="SourceSansPro"]::before {
  font-family: 'Source Sans Pro';
}
.ql-font-Tahoma,
.ql-font span[data-value="Tahoma"]::before {
  font-family: 'Tahoma';
}
.ql-font-TimesNewRoman,
.ql-font span[data-value="TimesNewRoman"]::before {
  font-family: 'Times New Roman';
}
.ql-font-TitilliumWeb,
.ql-font span[data-value="TitilliumWeb"]::before {
  font-family: 'Titillium Web';
}
.ql-font-TrebuchetMS,
.ql-font span[data-value="TrebuchetMS"]::before {
  font-family: 'Trebuchet MS';
}
.ql-font-Verdana,
.ql-font span[data-value="Verdana"]::before {
  font-family: 'Verdana';
}


















/* Set content for sizes */

.ql-size-8,
.ql-size span[data-value="8"]::before {
  font-size: 8px !important;
}
.ql-size-9,
.ql-size span[data-value="9"]::before {
  font-size: 9px !important;
}
.ql-size-10,
.ql-size span[data-value="10"]::before {
  font-size: 10px !important;
}
.ql-size-11,
.ql-size span[data-value="11"]::before {
  font-size: 11px !important;
}
.ql-size-12,
.ql-size span[data-value="12"]::before {
  font-size: 12px !important;
}
.ql-size-14,
.ql-size span[data-value="14"]::before {
  font-size: 14px !important;
}
.ql-size-16,
.ql-size span[data-value="16"]::before {
  font-size: 16px !important;
}
.ql-size-18,
.ql-size span[data-value="18"]::before {
  font-size: 18px !important;
}
.ql-size-20,
.ql-size span[data-value="20"]::before {
  font-size: 20px !important;
}
.ql-size-22,
.ql-size span[data-value="22"]::before {
  font-size: 22px !important;
}
.ql-size-24,
.ql-size span[data-value="24"]::before {
  font-size: 24px !important;
}
.ql-size-26,
.ql-size span[data-value="26"]::before {
  font-size: 26px !important;
}
.ql-size-28,
.ql-size span[data-value="28"]::before {
  font-size: 28px !important;
}
.ql-size-36,
.ql-size span[data-value="36"]::before {
  font-size: 36px !important;
}
.ql-size-48,
.ql-size span[data-value="48"]::before {
  font-size: 48px !important;
}
.ql-size-72,
.ql-size span[data-value="72"]::before {
  font-size: 72px !important;
}
.ql-picker-options{
    max-height: 200px;
    overflow: auto;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before{
    font-size: 15px !important;
}
.ql-container{
    /* min-height: 10em; */
    padding-top:1em;
}

.temp-print {
  border: #dee2e6  1px solid;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 5px;
  max-width: 100%;
  min-width: 100%;
  object-fit: contain;
}

.temp-print-opened {
  border: #dee2e6  1px solid;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 5px;
  min-width: 25%;
  object-fit: contain;
}
.bk-zoom-img {
  background: #ecebeb;
}
