/* ----- Abril Fatface ----- */
@font-face {
    font-family: 'Abril-Fatface';
    src: url('./Abril_Fatface/AbrilFatface-Regular.woff2') format('woff2'),
        url('./Abril_Fatface/AbrilFatface-Regular.woff') format('woff'),
        url('./Abril_Fatface/AbrilFatface-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----- Arimo ----- */
@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Bold.woff2') format('woff2'),
        url('./Arimo/Arimo-Bold.woff') format('woff'),
        url('./Arimo/Arimo-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-BoldItalic.woff2') format('woff2'),
        url('./Arimo/Arimo-BoldItalic.woff') format('woff'),
        url('./Arimo/Arimo-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Italic.woff2') format('woff2'),
        url('./Arimo/Arimo-Italic.woff') format('woff'),
        url('./Arimo/Arimo-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Italic.woff2') format('woff2'),
        url('./Arimo/Arimo-Italic.woff') format('woff'),
        url('./Arimo/Arimo-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Italic.woff2') format('woff2'),
        url('./Arimo/Arimo-Italic.woff') format('woff'),
        url('./Arimo/Arimo-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Medium.woff2') format('woff2'),
        url('./Arimo/Arimo-Medium.woff') format('woff'),
        url('./Arimo/Arimo-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-MediumItalic.woff2') format('woff2'),
        url('./Arimo/Arimo-MediumItalic.woff') format('woff'),
        url('./Arimo/Arimo-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Regular.woff2') format('woff2'),
        url('./Arimo/Arimo-Regular.woff') format('woff'),
        url('./Arimo/Arimo-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Regular.woff2') format('woff2'),
        url('./Arimo/Arimo-Regular.woff') format('woff'),
        url('./Arimo/Arimo-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-SemiBold.woff2') format('woff2'),
        url('./Arimo/Arimo-SemiBold.woff') format('woff'),
        url('./Arimo/Arimo-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-SemiBoldItalic.woff2') format('woff2'),
        url('./Arimo/Arimo-SemiBoldItalic.woff') format('woff'),
        url('./Arimo/Arimo-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('./Arimo/Arimo-Regular.woff2') format('woff2'),
        url('./Arimo/Arimo-Regular.woff') format('woff'),
        url('./Arimo/Arimo-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----- Cormorant-Garamond ----- */
@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-BoldItalic.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-BoldItalic.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-Bold.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-Bold.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-Italic.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-Italic.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-Light.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-Light.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-LightItalic.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-LightItalic.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-Medium.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-Medium.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-MediumItalic.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-MediumItalic.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-Regular.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-Regular.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-SemiBold.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-SemiBold.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('./Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.woff2') format('woff2'),
        url('./Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.woff') format('woff'),
        url('./Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

/* ----- Great-Vibes ----- */
@font-face {
    font-family: 'Great-Vibes';
    src: url('./Great_Vibes/GreatVibes-Regular.woff2') format('woff2'),
        url('./Great_Vibes/GreatVibes-Regular.woff') format('woff'),
        url('./Great_Vibes/GreatVibes-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----- Josefin-Sans ----- */
@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Bold.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Bold.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-ExtraLight.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-ExtraLight.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-ThinItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-ThinItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Italic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Italic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Light.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Light.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-ExtraLightItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-ExtraLightItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-BoldItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-BoldItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Regular.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Regular.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Medium.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Medium.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-MediumItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-MediumItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-LightItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-LightItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-SemiBold.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-SemiBold.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-ThinItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-ThinItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Thin.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Thin.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-ThinItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-ThinItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-SemiBoldItalic.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-SemiBoldItalic.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Thin.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Thin.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin-Sans';
    src: url('./Josefin_Sans/JosefinSans-Thin.woff2') format('woff2'),
        url('./Josefin_Sans/JosefinSans-Thin.woff') format('woff'),
        url('./Josefin_Sans/JosefinSans-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* ----- Lobster ----- */
@font-face {
    font-family: 'Lobster';
    src: url('./Lobster/Lobster-Regular.woff2') format('woff2'),
        url('./Lobster/Lobster-Regular.woff') format('woff'),
        url('./Lobster/Lobster-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----- Montserrat ----- */
@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Black.woff2') format('woff2'),
        url('./Montserrat/Montserrat-Black.woff') format('woff'),
        url('./Montserrat/Montserrat-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-BlackItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-BlackItalic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('./Montserrat/Montserrat-Bold.woff') format('woff'),
        url('./Montserrat/Montserrat-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-BoldItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
        url('./Montserrat/Montserrat-ExtraBold.woff') format('woff'),
        url('./Montserrat/Montserrat-ExtraBold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-ExtraBoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
        url('./Montserrat/Montserrat-ExtraLight.woff') format('woff'),
        url('./Montserrat/Montserrat-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Italic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-Italic.woff') format('woff'),
        url('./Montserrat/Montserrat-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Light.woff2') format('woff2'),
        url('./Montserrat/Montserrat-Light.woff') format('woff'),
        url('./Montserrat/Montserrat-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-LightItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-LightItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('./Montserrat/Montserrat-Medium.woff') format('woff'),
        url('./Montserrat/Montserrat-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-MediumItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('./Montserrat/Montserrat-Regular.woff') format('woff'),
        url('./Montserrat/Montserrat-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('./Montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('./Montserrat/Montserrat-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Thin.woff2') format('woff2'),
        url('./Montserrat/Montserrat-Thin.woff') format('woff'),
        url('./Montserrat/Montserrat-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
        url('./Montserrat/Montserrat-ThinItalic.woff') format('woff'),
        url('./Montserrat/Montserrat-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/* ----- Open-Sans ----- */
@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-Bold.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-Bold.woff') format('woff'),
        url('./Open_Sans/OpenSans-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-BoldItalic.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-BoldItalic.woff') format('woff'),
        url('./Open_Sans/OpenSans-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-ExtraBold.woff') format('woff'),
        url('./Open_Sans/OpenSans-ExtraBold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-Italic.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-Italic.woff') format('woff'),
        url('./Open_Sans/OpenSans-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-Light.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-Light.woff') format('woff'),
        url('./Open_Sans/OpenSans-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-Italic.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-Italic.woff') format('woff'),
        url('./Open_Sans/OpenSans-Italic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-Regular.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-Regular.woff') format('woff'),
        url('./Open_Sans/OpenSans-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-LightItalic.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-LightItalic.woff') format('woff'),
        url('./Open_Sans/OpenSans-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-SemiBoldItalic.woff') format('woff'),
        url('./Open_Sans/OpenSans-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('./Open_Sans/OpenSans-SemiBold.woff2') format('woff2'),
        url('./Open_Sans/OpenSans-SemiBold.woff') format('woff'),
        url('./Open_Sans/OpenSans-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* ----- Oswald ----- */
@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-Bold.woff2') format('woff2'),
        url('./Oswald/Oswald-Bold.woff') format('woff'),
        url('./Oswald/Oswald-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-Light.woff2') format('woff2'),
        url('./Oswald/Oswald-Light.woff') format('woff'),
        url('./Oswald/Oswald-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-ExtraLight.woff2') format('woff2'),
        url('./Oswald/Oswald-ExtraLight.woff') format('woff'),
        url('./Oswald/Oswald-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-Regular.woff2') format('woff2'),
        url('./Oswald/Oswald-Regular.woff') format('woff'),
        url('./Oswald/Oswald-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-SemiBold.woff2') format('woff2'),
        url('./Oswald/Oswald-SemiBold.woff') format('woff'),
        url('./Oswald/Oswald-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-Medium.woff2') format('woff2'),
        url('./Oswald/Oswald-Medium.woff') format('woff'),
        url('./Oswald/Oswald-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-Regular.woff2') format('woff2'),
        url('./Oswald/Oswald-Regular.woff') format('woff'),
        url('./Oswald/Oswald-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----- poppins ----- */
@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-BlackItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-BlackItalic.woff') format('woff'),
        url('./Poppins/Poppins-BlackItalic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Black.woff2') format('woff2'),
        url('./Poppins/Poppins-Black.woff') format('woff'),
        url('./Poppins/Poppins-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Bold.woff2') format('woff2'),
        url('./Poppins/Poppins-Bold.woff') format('woff'),
        url('./Poppins/Poppins-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-BoldItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-BoldItalic.woff') format('woff'),
        url('./Poppins/Poppins-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-ExtraBoldItalic.woff') format('woff'),
        url('./Poppins/Poppins-ExtraBoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-ExtraBold.woff2') format('woff2'),
        url('./Poppins/Poppins-ExtraBold.woff') format('woff'),
        url('./Poppins/Poppins-ExtraBold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-ExtraLight.woff2') format('woff2'),
        url('./Poppins/Poppins-ExtraLight.woff') format('woff'),
        url('./Poppins/Poppins-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-ExtraLightItalic.woff') format('woff'),
        url('./Poppins/Poppins-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Italic.woff2') format('woff2'),
        url('./Poppins/Poppins-Italic.woff') format('woff'),
        url('./Poppins/Poppins-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Light.woff2') format('woff2'),
        url('./Poppins/Poppins-Light.woff') format('woff'),
        url('./Poppins/Poppins-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-LightItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-LightItalic.woff') format('woff'),
        url('./Poppins/Poppins-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Medium.woff2') format('woff2'),
        url('./Poppins/Poppins-Medium.woff') format('woff'),
        url('./Poppins/Poppins-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-MediumItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-MediumItalic.woff') format('woff'),
        url('./Poppins/Poppins-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Regular.woff2') format('woff2'),
        url('./Poppins/Poppins-Regular.woff') format('woff'),
        url('./Poppins/Poppins-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('./Poppins/Poppins-SemiBold.woff') format('woff'),
        url('./Poppins/Poppins-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-SemiBoldItalic.woff') format('woff'),
        url('./Poppins/Poppins-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Thin.woff2') format('woff2'),
        url('./Poppins/Poppins-Thin.woff') format('woff'),
        url('./Poppins/Poppins-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-ThinItalic.woff2') format('woff2'),
        url('./Poppins/Poppins-ThinItalic.woff') format('woff'),
        url('./Poppins/Poppins-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/* ----- Prompt ------ */
@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Black.woff2') format('woff2'),
        url('./Prompt/Prompt-Black.woff') format('woff'),
        url('./Prompt/Prompt-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-BlackItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-BlackItalic.woff') format('woff'),
        url('./Prompt/Prompt-BlackItalic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Bold.woff2') format('woff2'),
        url('./Prompt/Prompt-Bold.woff') format('woff'),
        url('./Prompt/Prompt-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-ExtraBoldItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-ExtraBoldItalic.woff') format('woff'),
        url('./Prompt/Prompt-ExtraBoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-ExtraBold.woff2') format('woff2'),
        url('./Prompt/Prompt-ExtraBold.woff') format('woff'),
        url('./Prompt/Prompt-ExtraBold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-BoldItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-BoldItalic.woff') format('woff'),
        url('./Prompt/Prompt-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-ExtraLightItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-ExtraLightItalic.woff') format('woff'),
        url('./Prompt/Prompt-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Italic.woff2') format('woff2'),
        url('./Prompt/Prompt-Italic.woff') format('woff'),
        url('./Prompt/Prompt-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-ExtraLight.woff2') format('woff2'),
        url('./Prompt/Prompt-ExtraLight.woff') format('woff'),
        url('./Prompt/Prompt-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Light.woff2') format('woff2'),
        url('./Prompt/Prompt-Light.woff') format('woff'),
        url('./Prompt/Prompt-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Medium.woff2') format('woff2'),
        url('./Prompt/Prompt-Medium.woff') format('woff'),
        url('./Prompt/Prompt-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-LightItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-LightItalic.woff') format('woff'),
        url('./Prompt/Prompt-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-SemiBold.woff2') format('woff2'),
        url('./Prompt/Prompt-SemiBold.woff') format('woff'),
        url('./Prompt/Prompt-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-MediumItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-MediumItalic.woff') format('woff'),
        url('./Prompt/Prompt-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Regular.woff2') format('woff2'),
        url('./Prompt/Prompt-Regular.woff') format('woff'),
        url('./Prompt/Prompt-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Thin.woff2') format('woff2'),
        url('./Prompt/Prompt-Thin.woff') format('woff'),
        url('./Prompt/Prompt-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-SemiBoldItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-SemiBoldItalic.woff') format('woff'),
        url('./Prompt/Prompt-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-ThinItalic.woff2') format('woff2'),
        url('./Prompt/Prompt-ThinItalic.woff') format('woff'),
        url('./Prompt/Prompt-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/* PT-Sans-Narrow */
@font-face {
    font-family: 'PT-Sans-Narrow';
    src: url('./PT_Sans_Narrow/PTSans-NarrowBold.woff2') format('woff2'),
        url('./PT_Sans_Narrow/PTSans-NarrowBold.woff') format('woff'),
        url('./PT_Sans_Narrow/PTSans-NarrowBold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT-Sans-Narrow';
    src: url('./PT_Sans_Narrow/PTSans-Narrow.woff2') format('woff2'),
        url('./PT_Sans_Narrow/PTSans-Narrow.woff') format('woff'),
        url('./PT_Sans_Narrow/PTSans-Narrow.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----- Rajdhani ----- */
@font-face {
    font-family: 'Rajdhani';
    src: url('./Rajdhani/Rajdhani-Bold.woff2') format('woff2'),
        url('./Rajdhani/Rajdhani-Bold.woff') format('woff'),
        url('./Rajdhani/Rajdhani-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('./Rajdhani/Rajdhani-Light.woff2') format('woff2'),
        url('./Rajdhani/Rajdhani-Light.woff') format('woff'),
        url('./Rajdhani/Rajdhani-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('./Rajdhani/Rajdhani-Medium.woff2') format('woff2'),
        url('./Rajdhani/Rajdhani-Medium.woff') format('woff'),
        url('./Rajdhani/Rajdhani-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('./Rajdhani/Rajdhani-Regular.woff2') format('woff2'),
        url('./Rajdhani/Rajdhani-Regular.woff') format('woff'),
        url('./Rajdhani/Rajdhani-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('./Rajdhani/Rajdhani-SemiBold.woff2') format('woff2'),
        url('./Rajdhani/Rajdhani-SemiBold.woff') format('woff'),
        url('./Rajdhani/Rajdhani-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* ----- Roboto ------ */
@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-BlackItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-BlackItalic.woff') format('woff'),
        url('./Roboto/Roboto-BlackItalic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Black.woff2') format('woff2'),
        url('./Roboto/Roboto-Black.woff') format('woff'),
        url('./Roboto/Roboto-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Bold.woff2') format('woff2'),
        url('./Roboto/Roboto-Bold.woff') format('woff'),
        url('./Roboto/Roboto-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-BoldItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-BoldItalic.woff') format('woff'),
        url('./Roboto/Roboto-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Light.woff2') format('woff2'),
        url('./Roboto/Roboto-Light.woff') format('woff'),
        url('./Roboto/Roboto-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Italic.woff2') format('woff2'),
        url('./Roboto/Roboto-Italic.woff') format('woff'),
        url('./Roboto/Roboto-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-LightItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-LightItalic.woff') format('woff'),
        url('./Roboto/Roboto-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Medium.woff2') format('woff2'),
        url('./Roboto/Roboto-Medium.woff') format('woff'),
        url('./Roboto/Roboto-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-MediumItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-MediumItalic.woff') format('woff'),
        url('./Roboto/Roboto-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Regular.woff2') format('woff2'),
        url('./Roboto/Roboto-Regular.woff') format('woff'),
        url('./Roboto/Roboto-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-ThinItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-ThinItalic.woff') format('woff'),
        url('./Roboto/Roboto-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Thin.woff2') format('woff2'),
        url('./Roboto/Roboto-Thin.woff') format('woff'),
        url('./Roboto/Roboto-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* ----- Roboto-Condensed ----- */
@font-face {
    font-family: 'Roboto-Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Italic.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Italic.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-BoldItalic.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-BoldItalic.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Bold.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Bold.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Light.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Light.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-LightItalic.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-LightItalic.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Regular.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Regular.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----- Shadows-Into-Light-Two ----- */
@font-face {
    font-family: 'Shadows-Into-Light-Two';
    src: url('./Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.woff2') format('woff2'),
        url('./Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.woff') format('woff'),
        url('./Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* ----- Source-Sans-Pro ----- */
@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-BlackItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-BlackItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-BlackItalic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Black.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Black.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Bold.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Bold.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-BoldItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-BoldItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-ExtraLight.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLight.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Italic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Italic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Light.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Light.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-LightItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-LightItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Regular.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Regular.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBold.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

/* ----- Titillium-Web ----- */
@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-Bold.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-Bold.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-Black.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-Black.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-BoldItalic.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-BoldItalic.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-ExtraLight.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-ExtraLight.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-Italic.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-Italic.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-Light.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-Light.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-ExtraLightItalic.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-ExtraLightItalic.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-SemiBold.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-SemiBold.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-LightItalic.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-LightItalic.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-Regular.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-Regular.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium-Web';
    src: url('./Titillium_Web/TitilliumWeb-SemiBoldItalic.woff2') format('woff2'),
        url('./Titillium_Web/TitilliumWeb-SemiBoldItalic.woff') format('woff'),
        url('./Titillium_Web/TitilliumWeb-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

