.product-attribute {
    display: block;
    flex-grow: 1;

    .MuiGrid-spacing-xs-3 {
        width: calc(80% + 24px);
        margin: auto;
    }
    .reactStar > div > div > span {
        float: right !important;
    }
    .WriteReview {
        text-align: right;
        font-size: 1.05em;
        margin: 10px;
        a {
            color: #e20d22;
        }
    }
    .HaedH3 {
        margin: 10px;
        padding: 15px;
        border: 1px solid #dfdfdfa1;
        background: #dfdfdfa1;
        text-transform: uppercase;
    }
    .HaedHTotal {
        margin: 10px;
        padding: 15px;
        border: 1px solid #dfdfdfa1;
        background: #dfdfdfa1;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
    }
    .productImageMain{
        border: 1px solid #dfdfdfa1;
        overflow: hidden;
    }
    .productImage {
        max-width: 450px;
        margin: 0 auto;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    .reviewscnt {
        display: flex;
        font-weight: 500;
    }
    span.MuiButton-label {
        font-size: 12px;
    }
    .MuiAccordionSummary-root {
        background: #dfdfdfa1;
        margin-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .product-attribute-head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 25px;
    }
    .ProductMainDesc {
        width: 85%;
    }
    .produc-main {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-align:center;
    }
    .sizediv {
        margin: 10px;
        padding: 20px;
       .quantityIn {
            height: 1.5em;
            padding-left: unset;
            text-align: center;
            width: 40px;
        }
    }
    .no-margin {
        margin: unset !important;
        padding: 70px 20px 24px;
    }
    .no-margin-head {
        margin-bottom: -35px !important;
    }
    .product-details-price h4, span {
            font-size: 24px;
    }
    .Qtyediv {
        margin: 10px;
        padding: 20px;
        max-height: 120px;
        height: 115px;
        text-align: center;
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
    }
    .colorSize {
        margin: 10px;
        padding: 10px;
        overflow-y: auto;
        display: flex;
        flex-wrap: nowrap;
    }
    .moqNote {
        margin: 10px;
        padding: 2px;
        text-align: center;
        overflow-x: auto;
        color: #e20d22;
    }
    .cartBtn {
        margin: 10px;
        padding: 0px;
    }
    .product-sticky-image {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        flex-direction: column;
        height: 450px;
        max-height: 450px;
        border: 1px solid dfdfdfa1;
        .containerimg {
            margin: 0 auto;
            max-width: unset;
            width: 100% !important;
        }

        .single_image {
            max-width: 100%;
        }    
        .react_lightgallery_item>img {
            max-height: 450px;
            object-fit: contain;
            width: auto;
            height: auto;
            margin: 0 auto;
            width: 100%;
        }
    }    
    .single_image.lightGal {
        max-width: fit-content;
        margin: 0 auto;
        position: relative;
    }
    .containerimg {
        max-width: fit-content;
    }
    .containerimgMain {
        position: relative;
        width: 100%;
        .react_lightgallery_item{
        img{
            object-fit: contain;
        }
        }
        .single-image img {
            width: 100%;
            max-height: 600px;
            object-fit: contain;
        }
    }
    .atrrmain{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        input[type=radio] {
            width: 24px;
        }
    }
}
@media only screen and (max-width: 767px){
    .sizediv label{
        padding: 8px;
    }
    .quantityIn {
        padding-left: unset;
        text-align: center;
        width: 40px;
    }
    .QtyContainer {
        padding-left: 4px;
        padding-right: 4px;
        width: auto;
    }
    .product-small-image-wrapper .swiper-slide {
        word-break : unset;
    }
  }
  .product-pramotional {
      display: block;
      flex-grow: 1;
      .product-sticky-image {
          display: flex;
          white-space: nowrap;
          align-items: center;
          overflow: hidden;
          flex-direction: column;
          height: 450px;
          border: 1px solid dfdfdfa1;
          .containerimg {
              margin: 0 auto;
              width: max-content !important;
          }
          .single_image {
              max-width: 100%;
              flex:0 0 100%;
          }
          .react_lightgallery_item > img{
            max-height: 450px;
            object-fit: contain;
            max-width: 450px;
            width: auto;
            height: auto;
            margin: 0 auto;
          }
      }
      .productImage {
          width: 100%;
          img {
              height: 100%;
              width: 100%;
              object-fit: contain;
          }
      }

      .containerimgMain {
          position: relative;
          width: 100%;
          .react_lightgallery_item {
              img {
                  object-fit: contain;
              }
          }

          .single-image img {
              width: 100%;
              max-height: 600px;
              object-fit: contain;
          }
      }
  }
  .product-huts {
      .product-hut-discription-info {
          .product-details-price {
              margin: 7px 0px 17px;
          }

          .pro-details-list {
              margin: 10px 0px 0px 0px !important
          }

          .pro-details-list {
              border: unset;
              padding: 0 0 15px;
          }
      }

      .product-small-image-wrapper .swiper-slide {
        // height: 140px !important;
        width: fit-content;
        .single-image{
            position: relative;
            & > img{
                object-fit: initial;
            }
        }
      }

    //   .product-small-image-wrapper .single-image {
    //       height: 140px !important;
    //   }
      .single-image {
        img {
          height: 100% !important;
        }
      }
      .product-small-image-wrapper--side-thumb {
          min-height: 300px;
          max-height: 600px;
      }
      .smh-100 {
        max-height: unset;
        height:unset
      }
      .swiper-wrapper {
        justify-content: flex-start;
      }
     .artworkbox {
        width: 125px;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100% !important;
        }
    }

    .requiredDiv .artworkbox-selectednot {
        pointer-events: none;
    }

    .artworkbox-selected {
        z-index: 1;
    }

    .decorows_selartwork {
        padding: 1em;
        align-items: center;
    }
    .product-hats-details,
    .location-hats-details{
        display: flex;
        flex-wrap: wrap;
    }
    .small-images {
        width: 190px;
        margin-top: 25px;
    }
    .m-85 {
        margin-top: 85px;
    }
    .location-hats-list-details {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 190px);
    }
    .location-hats-data {
        display: flex;
        overflow-x: auto;
    }
    
    .title-hats-location {
        width: 150px;
    }
    .location-hats-list {
        width: calc(100% - 150px);
    }
    @media only screen and (max-width: 991px){
        .small-images {
            flex: 0 0 100%;
        }
        .location-hats-list-details {
            flex: 1
        }
    }
  }
  .requiredDiv {
      .artworkbox-selectednot {
          pointer-events: none;
      }
  }