/*----- 2. Header style ----*/

.header-area {
  position: relative;
  &.header-absolute {
    position: absolute;
    z-index: 99;

    width: 100%;

    @media #{$md-layout,
    $xs-layout} {
      position: relative;

      width: auto;
    }
  }
  nav {
    ul {
      li {
        ul.submenu-right {
          ul.submenu-left{
            margin-left: 100% !important;
            left: 0 !important;
          }
        }
        ul.submenu-left {
          ul.submenu-right{
            margin-right: 100% !important;
            right: 0 !important;
            top: -3px !important;
            margin-top: 0 !important;
          }
        }
        ul.submenu-left {
          ul.submenu-left{
            margin-left: 100% !important;
            left: 0 !important;
            top: -3px !important;
            margin-top: 0 !important;
          }
        }
        ul.submenu-right {
          ul.submenu-right{
            margin-right: 100% !important;
            right: 0 !important;
            top: -3px !important;
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

.sticky-bar.stick {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  width: 100%;

  transition: all 0s ease 0s;
  animation: 0ms ease-in-out 0s normal none 1 running fadeInDown;

  border-bottom: 0 solid #4a90e2;
  background-color: #fff;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
  .center-menu-logo {
    display: none;
    @media #{$md-layout} {
      display: block;
    }
    @media #{$xs-layout} {
      display: block;
    }
  }

  .header-right-wrap-white {
    .same-style {
      > a {
        color: #000;
      }

      &.cart-wrap,
      &.header-compare,
      &.header-wishlist,
      &.account-setting,
      &.header-search {
        > button,
        & > a {
          color: #000;
          span {
            &.count-style {
              color: #fff;
              background-color: #000;
            }
          }
        }
      }
    }
  }
}
.header-padding-1 .container-fluid {
  padding: 0 80px;
  @media #{$xl-layout} {
    padding: 0 15px;
  }
  @media #{$lg-layout} {
    padding: 0 30px;
  }
  @media #{$md-layout} {
    padding: 0 40px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}
.header-res-padding {
  @media #{$md-layout} {
    padding: 20px 0 15px;
  }
  @media #{$xs-layout} {
    padding: 20px 0 15px;
  }
}
.logo {
  margin-top: 32px;
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    margin-top: 0;
  }
  &.logo-hm5 {
    margin-top: 41px;
    a.sticky-block {
      display: none;
      @media #{$md-layout,
      $xs-layout} {
        display: block;
      }
    }
    a.sticky-none {
      display: block;
      @media #{$md-layout,
      $xs-layout} {
        display: none;
      }
    }
    @media #{$md-layout} {
      margin-top: 0;

      text-align: left !important;
    }
    @media #{$xs-layout} {
      margin-top: 0;

      text-align: left !important;
    }
  }
}

.stick {
  .logo-hm5 {
    a.sticky-block {
      display: block;
    }
    a.sticky-none {
      display: none;
    }
  }
}

.main-menu {
  nav {
    ul {
      display: block;

      // margin-top: -5px;

      text-align: center;
      li {
        position: relative;

        display: inline-block;

        padding: 0;
        ul.submenu-left {
          position: absolute;
          z-index: 999;
          top: 100% !important;
          left: 8px !important;

          display: block;
          visibility: hidden;

          width: 210px;
          padding: 3px 0 2px;

          transition: all 0.4s ease 0s;
          transform:translateX(0) rotateX(90deg);
          // transform-origin: center top 0;
          transform-origin: center;
          background: #fff none repeat scroll 0 0;
          // box-shadow: 0 5px 10px 0.5px rgba(0, 0, 0, 0.15);
          li {
            display: block;

            margin-left: 0;
            padding: 0 20px;
            a {
              font-size: 13px;
              font-weight: 400;
              line-height: 32px;

              display: block;

              padding-top: 1px;
              padding-bottom: 1px;

              text-align: left;

              color: #333;
              .fa-circle{
                font-size: 10px;
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.2s ease-in-out;
                opacity: 0;
                transition: ease-in-out;

              }

              &:hover {
                color: $theme-color;
              }
              &::before {
                position: absolute;
                z-index: 999;
                top: 13px;
                left: 15px;

                width: 7px;
                height: 7px;

                content: "";
                transition: all 0.3s ease-in-out 0s;

                opacity: 0;
                border-radius: 50%;
                background-color: inherit;
                // background: $theme-color none repeat scroll 0 0;
              }
            }
            &:hover a::before {
              opacity: 0;
            }
            &:hover > a {
              padding-left: 12px;
              .fa-circle{
                opacity: 0;
              }
            }
          }
        }
        ul.submenu-right {
          position: absolute;
          z-index: 999;
          top: 100% !important;
          right: 15px !important;

          display: block;
          visibility: hidden;

          width: 210px;
          padding: 3px 0 2px;

          transition: all 0.4s ease 0s;
          transform:translateX(0) rotateX(90deg);
          // transform-origin: center top 0;
          transform-origin: center;
          background: #fff none repeat scroll 0 0;
          // box-shadow: 0 5px 10px 0.5px rgba(0, 0, 0, 0.15);
          li {
            display: block;

            margin-right: 0;
            padding: 0 20px;
            a {
              font-size: 13px;
              font-weight: 400;
              line-height: 32px;

              display: block;

              padding-top: 1px;
              padding-bottom: 1px;

              text-align: left;

              color: #333;
              .fa-circle{
                font-size: 10px;
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.2s ease-in-out;
                opacity: 0;
                transition: ease-in-out;

              }

              &:hover {
                color: $theme-color;
              }
              &::before {
                position: absolute;
                z-index: 999;
                top: 13px;
                right: 15px;

                width: 7px;
                height: 7px;

                content: "";
                transition: all 0.3s ease-in-out 0s;

                opacity: 0;
                border-radius: 50%;
                background-color: inherit;
                // background: $theme-color none repeat scroll 0 0;
              }
            }
            &:hover a::before {
              opacity: 0;
            }
            &:hover > a {
              padding-right: 12px;
              .fa-circle{
                opacity: 0;
              }
            }
          }
        }
        ul.mega-menu {
          position: absolute;
          z-index: 999;
          top: 100%;
          left: 0;

          display: block !important;
          visibility: hidden;

          width: 700px;
          padding: 40px;
          overflow-y: auto;
          transition: all 0.5s ease 0s;
          transform: translateX(-50%)  rotateX(90deg);
          transform-origin: center top 0;
          text-align: left;

          background-color: #fff;
          box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);

          width: 95%;
          min-width: unset;
          max-width: 1300px;
          left: 50%;
          padding: 24px;
          margin: 0;
          @media #{$lg-layout} {
            left: -75px;
          }
          > li {
            display: inline-block;
            float: left;

            width: 33.33%;
            margin-left: 0;
            padding: 0;
            ul {
              li.mega-menu-title {
                float: inherit;

                width: 100%;
                a {
                  font-size: 14px;
                  font-weight: 500;

                  margin: 0 0 10px;

                  text-transform: uppercase;

                  color: #242424;
                  &::before {
                    display: none;
                  }
                }
                &:hover a {
                  padding-left: 0;
                }
              }
              li {
                display: block;
                float: inherit;

                padding: 0;
                a {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 28px;

                  display: block;

                  padding-top: 0;

                  text-align: left;

                  color: #333;
                  &:hover {
                    color: $theme-color;
                  }
                  &::before {
                    // position: absolute;
                    // z-index: 999;
                    // top: 13px;
                    // left: 0;

                    // width: 7px;
                    // height: 7px;

                    // content: "";
                    // transition: all 0.3s ease-in-out 0s;

                    // opacity: 0;
                    // border-radius: 50%;
                    background: $theme-color none repeat scroll 0 0;
                  }
                }
                &.mega-menu-img {
                  &:hover a {
                    padding-left: 0;
                  }
                  a {
                    &::before {
                      display: none;
                    }
                  }
                }
                &:hover a::before {
                  opacity: 1;
                }
                &:hover a {
                  // padding-left: 12px;
                }
              }
            }
          }
          &.mega-menu-padding {
            padding: 40px 40px 36px 40px;
            @media #{$lg-layout} {
              left: 0;
            }
          }
        }
        &:hover > ul.submenu-left {
          visibility: visible;

          transform: translateX(0)  rotateX(0deg);
          top: 100% !important;
          left: 8px !important;
        }
        &:hover > ul.submenu-right {
          visibility: visible;

          transform: translateX(0)  rotateX(0deg);
          top: 100% !important;
          right: 8px !important;
        }
        &:hover > ul.mega-menu {
          visibility: visible;

          transform:translateX(-50%)  rotateX(0deg);
        }
        @media #{$lg-layout} {
          padding: 0 7px;
        }
        @media #{$xl-layout} {
          padding: 0 10px;
        }
        > a {
          font-size: 15px;
          font-weight: 500;
          line-height: unset;
          padding: 10px 12px;
          display: inline-block;

          letter-spacing: 0.8px;

          color: #555252;
          i {
            font-size: 13px;

            position: relative;
            top: 0;

            margin-left: 1px;
            color: inherit !important;
          }
        }
        &:hover a {
          color: $themehover-color;
        }
      }
    }
  }
  &.menu-white {
    nav {
      ul {
        text-align: left;
        li {
          padding: 0 30px 0 0;
          @media #{$xx-layout} {
            padding: 0 22px 0 0;
          }
          @media #{$xl-layout} {
            padding: 0 14px 0 0;
          }
          @media #{$lg-layout} {
            padding: 0 14px 0 0;
          }
          > a {
            line-height: 112px;

            color: #ffffff;
          }
        }
      }
    }
  }
}
.stick .main-menu.menu-white nav ul li a {
  color: #555252;
  &:hover {
    color: $theme-color;
  }
}

// .stick .main-menu nav ul li a {
//   line-height: 40px;
// }

.stick .logo,
.stick .header-right-wrap {
  // margin-top: 22px;
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    margin-top: 0;
  }
  @media #{$md-layout} {
    margin-top: 0;
  }
}
.stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
.stick .header-right-wrap .same-style .account-dropdown {
  top: 160%;
  @media #{$xs-layout} {
    top: 100%;

    margin-top: 14px;
  }
  @media #{$md-layout} {
    top: 100%;

    margin-top: 14px;
  }
}

.header-right-wrap {
  display: flex;
  justify-content: flex-end;

  margin-top: 32px;
  .same-style{
    &.account-setting{
      & > button{
        padding: 0 4px !important;
      }
    }
    & > button, 
    & > a{
      padding: 0 2px !important;
    }
  }
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    margin-top: 2px;
    flex-wrap: wrap;
  }
  .same-style {
    position: relative;

    margin-left: 14px;
    @media #{$lg-layout} {
      margin-left: 10px;
    }
    @media #{$md-layout} {
      margin-left: 15px;
    }
    @media #{$xs-layout} {
      margin-left: 20px;
    }
    > a,
    button {
      font-size: 23px;

      color: #000;
    }
    > button {
      border: none;
      background: none;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover > a,
    &:hover > button {
      color: $theme-color;
    }
    .account-dropdown {
      position: absolute;
      z-index: 220;
      top: 190%;
      right: 0;

      visibility: hidden;

      width: 125px;
      padding: 8px 12px 10px;

      transition: all 0.5s ease 0s;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      text-align: left;

      opacity: 0;
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
      &.active {
        visibility: visible;

        transform: rotateX(0deg);

        opacity: 1;
      }
      @media #{$xs-layout} {
        top: 100%;

        margin-top: 14px;
      }
      @media #{$md-layout} {
        top: 100%;

        margin-top: 14px;
      }
      @media #{$lg-layout} {
        left: -40px;
      }
      @media #{$xl-layout} {
        left: -34px;
      }
      ul {
        li {
          a {
            font-size: 13px;
            line-height: 30px;

            display: block;

            padding: 0;

            color: #000;
            &:hover {
              padding-left: 5px;

              color: $theme-color;
            }
          }
        }
      }
    }
  }
  .same-style.account-setting {
    > a,
    button {
      font-size: 24px;
    }

    > button {
      border: none;
      background: none;
    }
  }
  .same-style.cart-wrap,
  .same-style.header-wishlist,
  .same-style.header-compare {
    // margin-left: 15px;
    @media #{$lg-layout} {
      margin-left: 15px;
    }
    @media #{$md-layout} {
      margin-left: 20px;
    }
    @media #{$xs-layout} {
      margin-left: 12px;
    }
    button,
    & > a {
      font-size: 24px;

      position: relative;

      display: inline-block;

      padding: 0;

      color: #000;
      border: none;
      background: transparent;
      span.count-style {
        font-size: 12px;
        line-height: 21px;

        position: absolute;
        top: -9px;
        right: -14px;

        display: inline-block;

        // width: 21px;
        // height: 21px;
        min-width: 21px;
        min-height: 21px;
        max-width: 60px;
        max-height: 22px;
        padding: 0px 3px;

        text-align: center;

        color: #fff;
        border-radius: 100%;
        background-color: #000;
        @media #{$xl-layout} {
          right: -5px;
        }
      }
    }
    &:hover > button {
      color: $theme-color;
    }
    .shopping-cart-content {
      position: absolute;
      z-index: 1200;
      top: 190%;
      right: 0;

      visibility: hidden;

      width: 340px;
      padding: 10px 35px 10px;

      transition: all 0.5s ease 0s;
      transform: rotateX(90deg);
      transform-origin: center top 0;

      opacity: 0;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      background: #fff none repeat scroll 0 0;
      &.cart-visible,
      &.active {
        visibility: visible;

        transform: rotateX(0deg);

        opacity: 1;
      }
      @media #{$xs-layout} {
        top: 100%;
        right: -52px;

        overflow-y: auto;

        width: 295px;
        height: 300px;
        margin-top: 14px;
        padding: 31px 15px 10px;
      }
      @media #{$md-layout} {
        top: 100%;

        overflow-y: auto;

        height: 300px;
        margin-top: 18px;
        margin-top: 14px;
      }
      @media #{$lg-layout} {
        overflow-y: auto;
      }
      ul {
        overflow-y: auto;

        max-height: 300px;
        @media #{$xl-layout} {
          max-height: 200px;
        }
        @media #{$lg-layout} {
          max-height: 200px;
        }
        @media #{$md-layout} {
          max-height: 200px;
        }
        margin-right: -15px;
        padding-top: 20px;
        padding-right: 15px;
        li {
          position: relative;

          display: flex;
          flex-wrap: nowrap;

          margin-bottom: 20px;
          padding-bottom: 15px;

          border-bottom: 1px solid #ebebeb;
          .shopping-cart-img {
            flex: 0 0 82px;

            margin-right: 15px;
          }
          .shopping-cart-title {
            h4 {
              font-size: 15px;
              line-height: 16px;

              margin: 0;
              margin-right: 20px;
              a {
                line-height: 1.2;

                color: #000;
                &:hover {
                  color: $theme-color;
                }
              }
            }
            h6 {
              font-size: 13px;

              margin: 5px 0 8px;
            }
            span {
              font-size: 14px;
            }
          }
          .shopping-cart-delete {
            position: absolute;
            bottom: 10px;
            right: 0;
            a,
            button {
              font-size: 16px;

              color: $theme-color;
              &:hover {
                color: $themehover-color;
              }
            }
          }
        }
      }
      .shopping-cart-total {
        h4 {
          font-size: 14px;

          margin-top: 17px;
          margin-bottom: 17px;
          span {
            float: right;
          }
        }
      }
      .shopping-cart-btn {
        margin-top: 23px;
        a {
          font-size: 14px;
          font-weight: 500;

          display: block;

          margin-bottom: 20px;
          padding: 16px 40px 17px;
        }
      }
    }
  }
  &.header-right-wrap-white {
    margin-top: 40px;
    .same-style {
      > a {
        color: #fff;
      }

      &.cart-wrap,
      &.header-compare,
      &.header-wishlist,
      &.account-setting,
      &.header-search {
        > button,
        & > a {
          color: #000;
          span {
            &.count-style {
              color: #000;
              background-color: #fff;
            }
          }
        }
      }
    }

    @media #{$md-layout} {
      margin-top: 0;

      .same-style {
        > a {
          color: #000;
        }

        &.cart-wrap,
        &.header-compare,
        &.header-wishlist,
        &.account-setting,
        &.header-search {
          > button,
          & > a {
            color: #000;
            span {
              &.count-style {
                color: #fff;
                background-color: #000;
              }
            }
          }
        }
      }
    }
    @media #{$xs-layout} {
      margin-top: 3px;
      .same-style {
        > a {
          color: #000;
        }

        &.cart-wrap,
        &.header-compare,
        &.header-wishlist,
        &.account-setting,
        &.header-search {
          > button,
          & > a {
            color: #000;
            span {
              &.count-style {
                color: #fff;
                background-color: #000;
              }
            }
          }
        }
      }
    }
  }
  .same-style.header-search {
    .search-content {
      position: absolute;
      z-index: 99;
      top: 193%;
      right: 0;

      visibility: hidden;

      min-width: 300px;
      padding: 3px;

      transition: all 0.5s ease 0s;
      transform: rotateX(90deg);
      transform-origin: center top 0;

      opacity: 0;
      background: #ffffff none repeat scroll 0 0;
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
      &.active {
        visibility: visible;

        transform: rotateX(0deg);

        opacity: 1;
      }
      @media #{$md-layout} {
        top: 154%;
      }
      @media #{$xs-layout} {
        top: 154%;
        right: -105px;

        min-width: 260px;
      }
      form {
        position: relative;
        input {
          width: 100%;
          padding: 5px 0px 5px 1px;
          transition: all 0.4s ease 0s;
          color: #000;
          outline: medium none;
          background: transparent;
        }
        .button-search {
          font-size: 20px;
          line-height: 1;

          position: absolute;
          top: 0;
          right: 0;

          height: 100%;
          padding: 5px 18px 5px;

          transition: all 0.4s ease 0s;
          text-transform: uppercase;

          color: #ffffff;
          border: none;
          border-color: #ce9634;
          background: $theme-color;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: #000;
          }
        }
      }
    }
  }
}

.header-account-info{
  .header-right-wrap {
    .same-style{
      @media #{$md-layout} {
        display: none !important;
        &:last-child{
          display: block !important;
        }
        &.account-setting,
        &.m-show{
          display: block !important;
        }
      }
      @media #{$xs-layout} {
        display: none !important;
        &:last-child{
          display: block !important;
        }
        &.account-setting,
        &.m-show{
          display: block !important;
        }
      }
    }
  }
}
.offcanvas-mobile-menu{
  .offcanvas-navigation{
    ul{
      text-align: left !important;
    }
  }
  .header-right-wrap {
    &.icon-auto{
      margin-left: 0;
      justify-content: space-around;
      margin-bottom: 8px;
    }
    .same-style{
      margin-left: 8px;
      .account-dropdown{
        left: 0;
      }
      .cart-wrap-desktop{
        display: none;
      }
      @media #{$md-layout} {
        display: block;
        &.header-search, 
        &:last-child{
          display: none !important;
        }
        &.account-setting,
        &.m-show{
          display: none !important;
        }
      }
      @media #{$xs-layout} {
        display: block;
        &.header-search,
        &:last-child{
          display: none !important;
        }
        &.account-setting,
        &.m-show{
          display: none !important;
        }
      }
    }
  }
}
.icon-sidebar-menu{
  .header-right-wrap{
    .cart-wrap{
      position: unset;
    }
  }
}
.top-menu{
  .header-right-wrap {
    .same-style.cart-wrap {
      .shopping-cart-content{
        top: 190% !important;
        right: 0 !important;
        left: unset !important;
      }
    }
  }
}
.stick {
  .same-style {
    &.header-search {
      .search-content {
        top: 160%;
      }
    }
  }
}
.stick .header-right-wrap.header-right-wrap-white {
  margin-top: 22px;
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    margin-top: 0;
  }
  .same-style {
    > a {
      color: #000;
      &:hover {
        color: $theme-color;
      }
    }
    &.cart-wrap {
      > button {
        color: #000;
        &:hover {
          color: $theme-color;
        }
        span {
          &.count-style {
            color: #fff;
            background-color: #000;
          }
        }
      }
    }
  }
}

/* header 3 */

.header-padding-2 .container-fluid {
  padding: 0 70px;
  @media #{$xl-layout} {
    padding: 0 15px;
  }
  @media #{$lg-layout} {
    padding: 0 30px;
  }
  @media #{$md-layout} {
    padding: 0 40px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}

.header-top-area {
  position: relative;
  .header-top-wap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    @media #{$xs-layout} {
      display: block;

      padding-bottom: 0;
    }
    @media #{$sm-layout} {
      display: flex;

      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &.border-none {
    border: none;
  }
}
.header-offer {
  p {
    margin: 0;

    letter-spacing: 0.8px;

    // color: #555252;
    color: inherit;
    word-break: break-word;
    span {
      // color: #ff1c1c;
      color: inherit;
    }
    a{
      color: inherit;
    }
    @media #{$xs-layout} {
      text-align: center !important;
    }
    @media #{$sm-layout} {
      text-align: right;
    }
  }
}
.header-top-wap{
  color: #555252;
}
.language-currency-wrap {
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  padding-left: 60px;
  flex-wrap: wrap;

  @media #{$xs-layout} {
    justify-content: center;
    max-width: unset;
  }
  .same-language-currency {
    position: relative;

    margin-right: 54px;
    white-space: nowrap;
    @media #{$xs-layout} {
      margin-right: 15px;
      white-space: normal;
    }
    &:before {
      position: absolute;
      top: 47%;
      right: -27px;

      width: 1px;
      height: 15px;

      content: "";
      transform: translateY(-50%);

      background-color: #d5d4d4;
      @media #{$xs-layout} {
        right: -10px;
      }
    }
    &:last-child {
      margin-right: 0;
      &:before {
          display: none;
        }
    }
    span {
      line-height: 54px;

      display: inline-block;

      margin-top: -3px;

      cursor: pointer;
      letter-spacing: 0.8px;

      // color: #555252;
      color: inherit;
      @media #{$xs-layout} {
        line-height: 35px;
      }
      @media #{$sm-layout} {
        line-height: 54px;
      }
      i {
        font-size: 16px;

        position: relative;
        top: 2px;

        // color: #555252;
        color: inherit;
      }
    }
    .lang-car-dropdown {
      position: absolute;
      z-index: 220;
      top: 100%;
      left: 0;

      visibility: hidden;

      width: 125px;
      padding: 8px 12px 10px;

      transition: all 0.5s ease 0s;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      text-align: left;

      opacity: 0;
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
      ul {
        li {
          a,
          button {
            font-size: 13px;
            line-height: 27px;

            border: none;
            background: none;
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
    p {
      line-height: normal;

      margin-top: -1px;

      letter-spacing: 0.8px;

      color: #555252;
      color: inherit;
      white-space: normal;
      word-break: break-word;
    }
    a {
      color: inherit;
    }
    &:hover {
      & > a,
      span {
        color: $theme-color;
      }

      .lang-car-dropdown {
        visibility: visible;

        transform: rotateX(0deg);

        opacity: 1;
      }
    }
    &.language-style {
      span {
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
  }
  .header-full-width-text{
    flex: 0 0 100%;
  }
}

.nav-left-header-menu{
  .language-currency-wrap{
    max-width: 100%;
    flex: 0 0 200px;
  }
  &.nav-header-full-menu{
    .language-currency-wrap{
      flex: 1;
    }
  }
}

.header-left-side{
  width: 100%;
  max-width: 33.33%;
  .header-top-left-con{
    word-break: break-all;
    padding: 6px;
    p{
      color: inherit;
    }
  }
}

.nav-left-text{
  p{
    color: inherit;
  }
}

.header-center-side{
  // width: 100%;
  // max-width: 33.33%;
  &.header-center-height{
    min-height: 45px;
    width: 100%;
    max-width: 33.33%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 767px){
      min-height: auto;
      padding: 5px 0;
    }
  }
}

.header-top-center-con{
  padding: 6px;
  p{
    color: inherit;
    word-break: break-word;
  }
  @media only screen and (max-width: 767px){
    width: 100%;
    max-width: unset;
    text-align: center;
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
  }
}
.header-right-side{
  width: 100%;
  max-width: 33.33%;
  @media only screen and (max-width: 767px){
    width: 100%;
    max-width: unset;
  }
  .header-offer{
    padding: 6px;
  }
}
.beachTheme{
  .header-left-side{
    max-width: 100%;
  }
  .language-currency-wrap{
    max-width: none;
  }
  .header-center-side{
    max-width: 100%;
  }
  .header-top-center-con{
    max-width: none;
    position: relative;
    left: unset;
    top:unset;
    transform: unset;
  }
  .header-right-side{
    max-width: none;
  }
}
/* home 4 */

.home-sidebar-left {
  position: fixed;
  z-index: 7;
  top: 0;

  width: 375px;
  height: 100%;
  padding: 40px 0 0;

  transition: all 0.3s ease 0s;
  // overflow-x: hidden;
  // overflow-y: auto;
  @media #{$xx-layout} {
    width: 300px;
    padding: 50px 0 30px 0;
  }
  @media #{$xl-layout} {
    width: 300px;
    padding: 50px 0 30px 0;
  }
  @media #{$xl-layout} {
    width: 280px;
  }
  @media #{$lg-layout} {
    width: 250px;
  }
  @media #{$md-layout} {
    display: none;
  }
  @media #{$xs-layout} {
    display: none;
  }
  .logo {
    margin-top: 0;
    padding: 0 0 0 108px;
    @media #{$xx-layout} {
      padding: 0 0 0 50px;
    }
    @media #{$xl-layout} {
      padding: 0 0 0 30px;
    }
    @media #{$lg-layout} {
      padding: 0 0 0 15px;
    }
    @media #{$md-layout} {
      padding: 0 0 0 30px;
    }
  }
  .header-right-wrap {
    justify-content: flex-start;

    margin-top: 46px;
    margin-bottom: 67px;
    padding: 0 0 0 50px;
    flex-wrap: wrap;
    @media #{$xx-layout} {
      margin-top: 26px;
      margin-bottom: 27px;
      padding: 0 0 0 50px;
    }
    @media #{$xl-layout} {
      margin-top: 26px;
      margin-bottom: 27px;
      padding: 0 0 0 30px;
    }
    @media #{$lg-layout} {
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0 0 0 15px;
    }
    @media #{$md-layout} {
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0 0 0 30px;
    }
    & .same-style.cart-wrap {
      .shopping-cart-content {
        right: auto;
        left: 0;
        @media #{$xx-layout} {
          overflow-y: auto;

          height: 400px;
        }
        @media #{$xl-layout} {
          overflow-y: auto;

          height: 300px;
        }
      }
    }
    & .same-style.header-search {
      .search-content {
        right: auto;
        left: 0;
      }
    }
  }
  .sidebar-copyright {
    padding: 120px 63px 50px 63px;
    @media #{$xx-layout} {
      padding: 40px 63px 50px 63px;
    }
    @media #{$xl-layout} {
      padding: 40px 63px 50px 63px;
    }
    @media #{$lg-layout} {
      padding: 40px 15px 50px 15px;
    }
    @media #{$md-layout} {
      padding: 50px 15px 50px 30px;
    }
    p {
      margin: 0;

      text-align: center;
      letter-spacing: 0.9px;

      color: #313131;
    }
  }
  .header-top-wap{
    margin-top: 16px;
    padding: 12px;
    .language-currency-wrap{
      flex-wrap: wrap;
      .same-language-currency{
        p{
          line-height: normal;
          word-break: break-word;
        }
        // &:last-child{
        //   p{
        //     line-height: 40px;
        //   }
        // }
      }
    }
  }
}

.translate-sidebar-left {
  z-index: 999;
  top: 0;

  width: 375px;
  min-width: 375px;
  padding: 0 0;
  transition: all 0.3s ease 0s;

  @media #{$xx-layout} {
    width: 300px;
    min-width: 300px;
  }
  @media #{$xl-layout} {
    width: 300px;
    min-width: 300px;
  }
  @media #{$xl-layout} {
    width: 280px;
    min-width: 280px;
  }
  @media #{$lg-layout} {
    width: 250px;
    min-width: 250px;
  }
  @media #{$md-layout} {
    display: none;
  }
  @media #{$xs-layout} {
    display: none;
  }
}

.home-sidebar-right {
  margin-left: 375px;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media #{$xx-layout} {
    margin-left: 300px;
  }
  @media #{$xl-layout} {
    margin-left: 280px;
  }
  @media #{$lg-layout} {
    margin-left: 250px !important;
  }
  @media #{$md-layout} {
    margin-left: 0 !important;
  }
  @media #{$xs-layout} {
    margin-left: 0 !important;
  }
  &.margin0{
    margin-left: 0 !important;
  }
}

.home-sidebar-right-margin{
  margin-left: 375px;
  @media #{$xx-layout} {
    margin-left: 300px;
  }
  @media #{$xl-layout} {
    margin-left: 280px;
  }
  @media #{$lg-layout} {
    margin-left: 250px !important;
  }
  @media #{$md-layout} {
    margin-left: 0 !important;
  }
  @media #{$xs-layout} {
    margin-left: 0 !important;
  }
}

.sidebar-menu {
  padding: 0 0 0 50px;
  @media #{$xx-layout} {
    padding: 0 0 0 50px;
  }
  @media #{$xl-layout} {
    padding: 0 0 0 30px;
  }
  @media #{$lg-layout} {
    padding: 0 0 0 15px;
  }
  @media #{$md-layout} {
    padding: 0 0 0 30px;
  }
  nav {
    ul {
      li {
        // position: relative;

        // margin-bottom: 30px;
        margin-bottom: 12px;
        @media #{$xx-layout} {
          padding-bottom: 20px;
        }
        @media #{$xl-layout} {
          padding-bottom: 15px;
        }
        @media #{$lg-layout} {
          padding-bottom: 10px;
        }
        @media #{$md-layout} {
          padding-bottom: 12px;
        }
        &:last-child {
          padding-bottom: 0;
        }
        ul.submenu-left {
          position: absolute;
          z-index: 999;
          top: 0;
          left: 100%;

          display: block !important;
          visibility: hidden;

          width: 190px;
          padding: 0px 0 6px;

          transition: all 0.5s ease 0s;
          transform: rotateX(90deg);
          transform-origin: center top 0;

          background: #fff none repeat scroll 0 0;
          box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
          @media #{$lg-layout} {
            overflow-y: auto;

            max-height: 250px;
          }
          li {
            display: block;

            margin-left: 0;
            margin-bottom: 0;
            padding: 0 20px;
            position: relative;
            a {
              font-size: 13px;
              font-weight: 400;
              line-height: 32px;

              display: block;

              padding-top: 0;

              text-align: left;

              color: #333;
              &:hover {
                color: $theme-color;
              }
              &::before {
                position: absolute;
                z-index: 999;
                top: 13px;
                left: 15px;

                width: 7px;
                height: 7px;

                content: "";
                transition: all 0.3s ease-in-out 0s;

                opacity: 0;
                border-radius: 50%;
                background: $theme-color none repeat scroll 0 0;
              }
            }
            ul.submenu-left{
              left: 100% !important;
              top: 10px !important;
            }
            ul.submenu-right{
              right: 100% !important;
              top: 10px !important;
            }
            &:hover a::before {
              opacity: 0;
            }
            &:hover a {
              padding-left: 12px;
            }
          }
          &.rolling-submenu{
            width: 175px !important;
            li{
              position: unset;
            }
          }
        }
        ul.submenu-right {
          position: absolute;
          z-index: 999;
          top: 0;
          right: 100%;

          display: block !important;
          visibility: hidden;

          width: 190px;
          padding: 0px 0 6px;

          transition: all 0.5s ease 0s;
          transform: rotateX(90deg);
          transform-origin: center top 0;

          background: #fff none repeat scroll 0 0;
          box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
          @media #{$lg-layout} {
            overflow-y: auto;

            max-height: 250px;
          }
          li {
            display: block;

            margin-right: 0;
            margin-bottom: 0;
            padding: 0 20px;
            position: relative;
            a {
              font-size: 13px;
              font-weight: 400;
              line-height: 32px;

              display: block;

              padding-top: 0;

              text-align: left;

              color: #333;
              &:hover {
                color: $theme-color;
              }
              &::before {
                position: absolute;
                z-index: 999;
                top: 13px;
                right: 15px;

                width: 7px;
                height: 7px;

                content: "";
                transition: all 0.3s ease-in-out 0s;

                opacity: 0;
                border-radius: 50%;
                background: $theme-color none repeat scroll 0 0;
              }
            }
            ul.submenu-left{
              left: 175px !important;
              top: 20px !important;
            }
            ul.submenu-right{
              right: 175px !important;
              top: 20px !important;
            }
            &:hover a::before {
              opacity: 0;
            }
            &:hover a {
              padding-right: 12px;
            }
          }
        }
        &:hover > ul.submenu-left, &:hover > ul.submenu-right {
          visibility: visible;

          transform: rotateX(0deg);
        }
        ul.mega-menu {
          position: absolute;
          z-index: 999;
          top: 0;
          left: 100%;

          display: block !important;
          visibility: hidden;

          width: 100%;
          width: 700px;
          padding: 0;

          transition: all 0.5s ease 0s;
          transform: rotateX(90deg);
          transform-origin: center top 0;
          text-align: left;

          background-color: #fff;
          box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
          @media #{$xx-layout} {
            top: -50px;
          }
          @media #{$xl-layout} {
            top: -50px;
          }
          @media #{$lg-layout} {
            top: -80px;
          }
          > li {
            display: inline-block;
            float: left;

            width: 33.33%;
            margin-left: 0;
            padding: 0;
            ul {
              li.mega-menu-title {
                float: inherit;

                width: 100%;
                a {
                  font-size: 14px;
                  font-weight: 500;

                  margin: 0 0 10px;

                  text-transform: uppercase;

                  color: #242424;
                  &::before {
                    display: none;
                  }
                }
                &:hover a {
                  padding-left: 0;
                }
              }
              li {
                display: block;
                float: inherit;

                padding: 0;
                a {
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 32px;

                  display: block;

                  padding-top: 0;

                  text-align: left;

                  color: #333;
                  &:hover {
                    color: $theme-color;
                  }
                  &::before {
                    position: absolute;
                    z-index: 999;
                    top: 13px;
                    left: 0;

                    width: 7px;
                    height: 7px;

                    content: "";
                    transition: all 0.3s ease-in-out 0s;

                    opacity: 0;
                    border-radius: 50%;
                    background: $theme-color none repeat scroll 0 0;
                  }
                }
                &.mega-menu-img {
                  &:hover a {
                    padding-left: 0;
                  }
                  a {
                    &::before {
                      display: none;
                    }
                  }
                }
                &:hover a::before {
                  opacity: 0;
                }
                &:hover a {
                  padding-left: 12px;
                }
              }
            }
          }
          &.mega-menu-padding {
            padding: 40px 40px 36px 40px;
          }
        }
        &:hover > ul.mega-menu {
          visibility: visible;

          transform: rotateX(0deg);
        }
        a {
          font-size: 15px;
          font-weight: 500;

          display: block;

          letter-spacing: 0.8px;

          color: #000000;
          position: relative;
          @media #{$xl-layout} {
            font-size: 16px;
          }
          @media #{$lg-layout} {
            font-size: 16px;
          }
          @media #{$md-layout} {
            font-size: 16px;
          }
          span {
            float: right;

            padding-right: 20px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
          }
        }
        &:hover a {
          color: $theme-color;
        }
      }
      & > li{
        & > a{
          padding: 10px;
        }
      }
    }
  }
  .mega-menu {
    & > li {
      margin-bottom: 0;
    }
  }
}

.inner-home-sidebar-left{
  overflow: auto;
  .categories-link{
    .submenu-right{
      .submenu-left{
        margin-left:0 !important
      }
    }
    .submenu-left{
      .submenu-right{
        margin-right:0 !important
      }
    }
    .submenu-left{
      .submenu-left{
        margin-left:0 !important
      }
    }
    .submenu-right{
      .submenu-right{
        margin-right:0 !important
      }
    }
    a {
      i{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
    > a{
      i{
        right: 20px;
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }
}

.header-hm4-none {
  display: none;
  @media #{$xs-layout} {
    display: block;
  }
  @media #{$md-layout} {
    display: block;
  }
}

/* home 5 */

.wrapper {
  margin: 20px 20px;
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    margin: 0;
  }
}
.wrapper-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .layout-wrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.transparent-bar {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;

  width: 100%;
}

.header-padding-3 .container-fluid {
  padding: 0 80px;
  @media #{$xl-layout} {
    padding: 0 40px;
  }
  @media #{$lg-layout} {
    padding: 0 40px;
  }
  @media #{$md-layout} {
    padding: 0 40px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}

/* home 7 */

.clickable-mainmenu {
  margin-top: 35px;
  a,
  button {
    font-size: 30px;
    line-height: 1;

    display: inline-block;

    color: #fff;
    border: none;
    background: none;
    &:hover {
      color: $theme-color;
    }
  }
  @media #{$md-layout} {
    margin-top: -3px;
  }
  @media #{$xs-layout} {
    margin-top: -3px;
  }

  .sidebar-menu {
    padding-left: 0;
  }
}
.clickable-mainmenu {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 300px;
  height: 100vh;
  margin-top: 0;
  padding: 60px 0px 40px 35px;

  transition: transform 0.5s ease-in-out 0s;
  transform: translateX(-110%);

  color: #353535;
  background: rgba(255, 255, 255, 1) none repeat scroll 0 0;
  &.inside {
    z-index: 9999;

    transform: translateX(0);
  }
  @media #{$xs-layout} {
    padding: 100px 35px 40px;
  }
  .clickable-mainmenu-icon {
    button {
      font-size: 50px;

      position: absolute;
      top: 20px;
      left: 20px;

      padding: 0;

      cursor: pointer;
      transition: all 0.3s ease 0s;

      color: #000;
      border: medium none;
      background: transparent none repeat scroll 0 0;
      &:hover {
        color: $theme-color;
      }
    }
  }
  .side-logo {
    margin-bottom: 50px;
    @media #{$xs-layout} {
      margin-bottom: 40px;
    }
  }
  .clickable-menu-style {
    background: transparent;
    ul {
      li {
        a {
          font-size: 16px;

          padding: 0 0 20px;

          color: #000;
          &:hover {
            color: $theme-color;
            background: transparent;
          }
        }
      }
    }
  }
  .side-social {
    margin-top: 50px;
    ul {
      li {
        position: relative;

        display: inline-block;

        margin: 0 30px 0 0;
        &:last-child {
          margin-right: 0;
        }
        &:before {
          position: absolute;
          top: 50%;
          right: -20px;

          width: 10px;
          height: 1px;

          content: "";
          transform: translateY(-50%);

          background: #999;
        }
        &:last-child:before {
          display: none;
        }
        a {
          font-size: 18px;
          &.facebook {
            color: #3b5999;
            &:hover {
              color: #333;
            }
          }
          &.dribbble {
            color: #ea4c89;
            &:hover {
              color: #333;
            }
          }
          &.pinterest {
            color: #bd081c;
            &:hover {
              color: #333;
            }
          }
          &.twitter {
            color: #ffb893;
            &:hover {
              color: #333;
            }
          }
          &.linkedin {
            color: #0077b5;
            &:hover {
              color: #333;
            }
          }
        }
      }
    }
  }
  &-active {
    a,
    button {
      font-size: 30px;

      margin-top: 35px;

      color: #fff;
      border: none;
      background: none;
      &:hover {
        color: $theme-color;
      }
    }
  }
}

.header-hm-7.stick {
  .clickable-menu {
    margin-top: 19px;
    a,
    button {
      font-size: 30px;

      margin-top: 0;

      color: #282828;
      border: none;
      background: none;
      &:hover {
        color: $theme-color;
      }
    }
    @media #{$md-layout} {
      margin-top: 0;
    }
    @media #{$xs-layout} {
      margin-top: 0;
    }
  }
  .logo {
    margin-top: 22px;
    margin-bottom: 18px;
    @media #{$md-layout} {
      margin-top: 2px;
      margin-bottom: 0;
    }
    @media #{$xs-layout} {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }
  .header-right-wrap {
    margin-top: 22px;
    margin-bottom: 18px;
    @media #{$md-layout} {
      margin-top: 2px;
      margin-bottom: 0;
    }
    @media #{$xs-layout} {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }
}

.header-hm-7 {
  .header-right-wrap {
    @media #{$md-layout} {
      margin-right: 0;
    }
    @media #{$xs-layout} {
      margin-right: 0;
    }
    .same-style {
      &.cart-wrap {
        .shopping-cart-content {
          @media #{$xs-layout} {
            right: 0;
          }
        }
      }
      &.header-search {
        .search-content {
          @media #{$xs-layout} {
            right: -80px;
          }
        }
      }
    }
  }
}

/* home 8 */

.header-hm8 {
  .header-right-wrap {
    margin-top: 6px;
    @media #{$md-layout} {
      margin-right: 0;
    }
    @media #{$xs-layout} {
      margin-right: 0;
    }
    .same-style {
      &.cart-wrap {
        .shopping-cart-content {
          top: 125%;

          margin-top: 0;
          @media #{$xs-layout} {
            right: -99px;
          }
        }
      }
      & .account-dropdown {
        top: 125%;
        left: -32px;

        margin-top: 0;
      }
    }
    .same-style.header-search {
      .search-content {
        top: 125%;
        @media #{$xs-layout} {
          right: -150px;
        }
        @media #{$sm-layout} {
          right: 0;
        }
      }
    }
  }
  @media #{$md-layout} {
    .header-res-padding {
      padding: 20px 0 20px;
    }
    .mean-container a.meanmenu-reveal {
      top: -26px;
    }
  }
  @media #{$xs-layout} {
    .header-res-padding {
      padding: 20px 0 20px;
    }
    .mean-container a.meanmenu-reveal {
      top: -26px;
    }
  }
}
.header-hm8 {
  .stick {
    .logo {
      display: none;
      @media #{$md-layout} {
        display: block;
      }
      @media #{$xs-layout} {
        display: block;
      }
    }
  }
  .logo {
    @media #{$md-layout} {
      text-align: left !important;
    }
    @media #{$xs-layout} {
      text-align: left !important;
    }
  }
}

.header-hm8 {
  .main-menu {
    padding-bottom: 8px;
    nav {
      ul {
        li {
          > a {
            line-height: 60px;
          }
        }
      }
    }
  }
}

/* home 9 */

.header-hm9 {
  .main-menu {
    nav {
      ul {
        li {
          a {
            line-height: 40px;
          }
        }
      }
    }
  }
  .header-top-area {
    padding: 22px 0;
  }
  .header-right-wrap {
    margin-top: 13px;
    @media #{$md-layout} {
      margin-top: 0;
      margin-right: 0;
    }
    @media #{$xs-layout} {
      margin-top: 0;
      margin-right: 0;
    }
    @media #{$xs-layout} {
      .same-style.cart-wrap {
        .shopping-cart-content {
          right: -97px;
        }
      }
    }
  }
  .logo-hm9 {
    margin-top: 12px;
  }
  @media #{$md-layout} {
    .mean-container {
      a.meanmenu-reveal {
        top: -26px;
      }
    }
    .header-res-padding {
      padding: 20px 0 20px;
    }
  }
  @media #{$xs-layout} {
    .mean-container {
      a.meanmenu-reveal {
        top: -26px;
      }
    }
    .header-res-padding {
      padding: 20px 0 20px;
    }
  }
}

/* header hm10 */

@media #{$sm-layout} {
  .header-in-container {
    .header-top-wap {
      display: block;

      margin-top: 8px;
      margin-bottom: 15px;
      .language-currency-wrap {
        justify-content: center;
      }
      .header-offer {
        p {
          text-align: center;
        }
      }
    }
  }
}

@media #{$xs-layout} {
  .header-hm9 {
    .header-right-wrap {
      .same-style {
        &.header-search {
          .search-content {
            right: -142px;
          }
        }
      }
    }
  }
}

.mobile-aside-button {
  padding: 0;
}

/* offcanvas mobile menu */

.header-mobile-navigation {
  padding: 20px 0;

  .mobile-navigation {
    .header-cart-icon {
      a {
        span {
          left: 50%;
        }
      }
    }
  }
}

.offcanvas-widget-area {
  margin-top: auto;
  margin-bottom: 35px;

  @media #{$sm-layout} {
    margin-bottom: 30px;
  }
}

.off-canvas-contact-widget {
  margin-bottom: 20px;

  .header-contact-info {
    flex-basis: 33.33%;
    &__list {
      li {
        display: inline-block;

        margin-right: 25px;
        i {
          font-size: 16px;

          margin-right: 5px;
        }

        a {
          &:hover {
            color: $theme-color;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.offcanvas-mobile-menu {
  position: fixed;
  z-index: 999999999999;
  top: 0;
  right: 0;

  width: 400px;
  max-width: 100%;
  height: 100vh;
  padding-left: 60px;

  transition: 0.6s;
  transform: translateX(100%);

  &.active {
    transform: translateX(0);
  }

  &.inactive {
    transform: translateX(calc(100% + 60px));
  }
}

.offcanvas-menu-close {
  font-size: 30px;
  line-height: 70px;

  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;

  width: 60px;
  height: 65px;
  padding: 0;

  text-align: center;

  color: #fff;
  border: none;
  background: #343538;

  // @media #{$sm-layout}{
  //     width: 50px;
  //     height: 50px;
  //     line-height: 55px;
  //     left: 10px;
  //     font-size: 25px;
  // }

  &:hover,
  &:focus {
    color: $white;
  }

  i {
    font-size: 40px;
    line-height: 1;

    margin-top: 12px;

    transition: 0.3s;
    transform: rotate(0);
  }

  &:hover {
    i {
      transform: rotate(-90deg);
    }
  }
}

.offcanvas-wrapper {
  overflow: auto;

  height: 100%;

  background-color: $white;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
}

.offcanvas-mobile-search-area {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;

  width: calc(100% - 60px);
  margin-left: 60px;
  padding: 10px;

  background-color: #e6e6e6;

  input {
    font-size: 16px;

    display: block;

    width: 100%;
    padding: 9px 25px;

    color: #222;
    border: none;
    background: #e6e6e6;

    @media #{$sm-layout} {
      font-size: 14px;

      padding: 5px 15px;
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 50px;

    padding: 0;

    transform: translateY(-50%);

    color: #aaa;
    border: none;
    background: none;

    i {
      font-size: 18px;
      line-height: 40px;
    }
    &.MuiAutocomplete-clearIndicator {
      &.MuiAutocomplete-clearIndicatorDirty {
        display: none !important;
      }
    }
    &.MuiAutocomplete-popupIndicator {
      margin-right: -15px;
    }
  }
  .MuiAutocomplete-endAdornment {
    height: 100%;
    top: calc(50% - 26px);
    right: 0;
  }
}

.offcanvas-inner-content {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 90px 35px 0;

  @media #{$sm-layout} {
    padding: 70px 25px 0;
  }
}

.offcanvas-navigation {
  margin-bottom: 50px;
  & > ul {
    li {
      &.menu-item-has-children {
        .sub-menu {
          visibility: hidden;

          height: 0;

          transition: 0.3s;

          opacity: 0;
        }

        &.active {
          & > .sub-menu {
            visibility: visible;

            height: 100%;

            opacity: 1;
          }
        }
      }
      & > a {
        font-weight: 600;

        display: block;

        padding: 10px;

        color: #111;
        &:hover {
          color: $theme-color;
        }

        @media #{$sm-layout} {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .menu-item-has-children{
    .MuiButtonBase-root{
      .MuiSvgIcon-root{
        color: #000;
      }
    }
  }

  ul {
    &.sub-menu {
      margin-left: 0;

      transition: 0.3s;
      padding-left: 20px;
      & > li {
        & > a {
          font-size: 14px;
          font-weight: 400;

          padding: 10px 0;
          font-weight: 600;
          &:hover {
            color: $theme-color;
          }

          @media #{$sm-layout} {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    li {
      a {
        text-transform: uppercase;
      }

      &.menu-item-has-children {
        position: relative;

        display: block;
        a {
          display: block;
        }

        &.active {
          & > .menu-expand {
            i {
              &:before {
                transform: rotate(0);
              }
            }
          }
        }

        .menu-expand {
          line-height: 50px;

          position: absolute;
          top: -5px;
          right: auto;
          left: 95%;

          width: 30px;
          height: 50px;

          cursor: pointer;
          text-align: center;
          display: none;

          i {
            position: relative;

            display: block;

            width: 10px;
            margin-top: 25px;

            transition: all 250ms ease-out;

            border-bottom: 1px solid;
            &:before {
              position: absolute;
              top: 0;

              display: block;

              width: 100%;

              content: "";
              transform: rotate(90deg);

              border-bottom: 1px solid;
            }
          }
        }

        .more-items{
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}

.off-canvas-widget-social {
  a {
    font-size: 14px;

    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }

    @media #{$sm-layout} {
      margin: 0 10px;
    }

    &:hover {
      color: $theme-color;
    }
  }
}

/* offcanvas settings */

.offcanvas-settings {
  .offcanvas-navigation {
    & > ul {
      & > li {
        & > a {
          font-size: 12px;
          font-weight: 400;

          padding: 5px 0;
        }

        &.menu-item-has-children {
          .menu-expand {
            top: -15px;

            height: 30px;
            margin-top: 0;
          }
        }
      }
    }

    ul {
      &.sub-menu {
        & > li {
          & > a {
            padding: 5px 0;
          }
        }
      }
    }
  }
}

.transparent-bar {
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;

  width: 100%;

  @media #{$md-layout,
  $xs-layout,
  $sm-layout} {
    position: relative;
  }

  .header-top {
    display: none !important;
  }
}

.mobile-menu-middle {
  margin-bottom: 50px;
  .lang-curr-style {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    button {
      border: none;
      background: none;
    }

    .title {
      font-weight: 500;

      display: inline-block;
    }

    select {
      padding: 5px 0;

      border: 1px solid #ddd;
    }

    ul {
      li {
        button {
          font-size: 12px;
          &:hover,
          &.active {
            color: $theme-color;
          }
        }
      }
    }
  }
}

.icon-auto {
  margin: auto 0;
}
.swiper-pagination {
  display: none;
}
.custom-swiper-container{
  .swiper-pagination {
    display: block;
    bottom: 0;
  }
}
.mega-menu {
  margin-left: 30px;
  margin-right: 30px;
  display: table;
  ul {
    display: inline-flex;
  }

  ul li {
    display: table-cell;
    float: none;
  }
  position: absolute;
  left: 0;
  min-width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  max-height: 600px !important;
}
.imageUl {
  // align-items: flex-end;
  display: flex !important;
  overflow: auto !important;
  border-bottom: 1px solid #ccc;
  margin-bottom: 12px;
  &.removeBottom{
    border-bottom: none;
  }
  li {
    display: flex !important;
    // border-left: 1px solid lightgrey;
    width: auto !important;
    min-width: 150px;
    // max-width: 151px;
    flex: 1;
    flex-direction: column;

    img {
      padding: 5px;
    }
    a:nth-child(1){
      /*height: calc(100% - 60px);
      display: flex !important;
      align-items: flex-end;
      justify-content: center;
      margin-top: 10px !important;*/
      img{
        max-height: 100% !important;
      }
    }
    a {
      color: #000;
      text-align: center !important;
      &::after {
        position: relative;
      }
      &::before {
        position: relative !important;
      }
      &:hover {
        color: $theme-color;
      }
    }
    &:last-child {
      // border-right: 1px solid lightgrey;
    }
    &:first-child {
      border-left: none;
    }
  }
}
// .magamenuLi {
//   margin-top: 10px;
//   width: 25% !important;
// }
#root {
  scrollbar-width: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  &>.contact-area,
  &>.shop-area,
  &>.cart-main-area,
  &>.blog-area {
    flex: 1;
  }

  &.productSticky {
    display: block;
  }
}
.sidebar-menu {
  .mega-menu {
    // min-height: -webkit-fill-available;
    top: 15% !important;
    min-width: max-content;
    max-height: 600px !important;
    li {
      width: 100% !important;
      ul {
        width: 100% !important;
        display: contents;
      }
      a {
        color: #000;
        &:hover {
          color: $themehover-color;
        }
      }
    }
    .imageUl {
      width: 100% !important;
      display: inline-flex !important;
      overflow: auto !important;
      .submenu{
        li{
          margin-bottom: 0px;
          a{
            padding-bottom: 0;
          }
        }
      }
      li {
        width: auto !important;
        min-width: 150px;
        border-right: 1px dashed #ccc;
        &:last-child{
          border-right:none;                    
        }
        a {
          color: #000;
          &:hover {
            color: $theme-color;
          }
        }
        .submenu{
          li{
            border-right: none;
            flex: unset;
          }
        }
      }
    }
    .magamenuLi {
      margin-top: 10px;
      width: 200px !important;
    }
  }
}
.widthFull {
  border-bottom: 1ps solid lightgrey;
  width: 100% !important;
}
.magamenuLi ul li {
  min-width: 150px;
}
.productSearchList {
  max-height: 320px;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
  li {
    padding: 5px 5px;
    cursor: pointer;
    &:hover {
      background-color: #f7f7f7;
      color: $theme-color !important;
    }
  }
  .MuiAccordionSummary-content {
    justify-content: space-between;
    &.Mui-expanded {
      margin: 0;
      min-height: auto;
    }
    span {
      width: 25px;
      height: 25px;
      background: #000;
      border-radius: 50%;
      line-height: 25px;
      text-align: center;
      color: #fff;
      font-size: 13px;
    }
  }
  .view-all{
    text-align: center;
    padding: 8px 0;
    a{
      font-weight: 600;
      padding: 4px 12px;
      border-radius: 4px;
    }
  }
}

.header-area {
  [class*=" pe-7s-"],
  [class^="pe-7s-"] {
    font-weight: 600;
  }
}

.header-four{
  .header-right-content{
    > div{
      height: 100%;
    }
    .header-right-wrap-white{
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}

.bottomMenu{
  columns: 4;
  margin-top: 0 !important;
  // border-bottom: 1px solid #ccc;
  // border-right: 1px solid #ccc;
  padding-top: 10px;
  .magamenuLi{
    padding-left: 10px !important;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    // &::after{
    //   content: "";
    //   width: 1px;
    //   height: 100%;
    //   background: #ccc;
    //   top: 0;
    //   position: absolute;
    //   left: 0;
    // }
    ul{
      margin-top: 0;
      .mega-menu-title{
        
        a{
          margin-bottom: 0 !important;
          font-weight: 600 !important;
          font-size: 15px !important;
        }
      }
    }
  }
}

.inner-header-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-logo-container{
    padding: 0 12px;
    min-width: 16%;
    @media only screen and (max-width: 767px){
      padding: 0;
      .container{
        padding: 0;
      }
    }
  }
  .header-menu-items{
    padding: 0 12px;
    flex: 1;
  }
  .header-account-info{
    padding: 0 12px;
    min-width: 12%;
    @media only screen and (max-width: 767px){
      padding: 0 6px;
    }
  }
}

.add-maxwidth{
	width: 275px !important;
}
.center-div{
	justify-content: center
}

.top-header-right-text{
  position: relative;
  &::before{
    position: absolute;
    top: 47%;
    right: 0;
    width: 1px;
    height: 15px;
    content: "";
    transform: translateY(-50%);
    background-color: #d5d4d4;
  }
}

.budget-header-icon{
  max-width: 50px;
}
.fancy-nav-header-top{
  position: relative;
  .language-currency-wrap{
    min-height: 50px;
  }
  .fancy-icon-group{
    display: flex;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 12px;
  }
  &.fancy-nav-header-top-full{
    display: flex;
    .header-top-wap{
      flex: 1;
    }
    .fancy-icon-group{
      position: relative;
      top: unset;
      right: unset;
    }
  }
}

.searchRow {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background-color: #fff;

  .container-fluid {
    padding: 0 80px;
    display: inline-block;
    vertical-align: top;
  }

  .search-form {
    max-width: 450px;
    width: 100%;
    float: right;
    position: relative;

    form {
      border: 1px solid #ccc;
      max-width: 450px;
      float: right;
      width: 100%;

      .input-row {
        position: relative;

        input[type="text"] {
          background-color: #fff;
          border: none;
          padding: 5px 70px 5px 15px;
          &.dark-search{
            background-color: #1b1b1b;
            color: #fff;
          }
        }

        .searchbtn {
          position: absolute;
          top: 0;
          right: 0;
          background: var(--themedefaultColor);
          border: none;
          height: 100%;
          width: 50px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .searchList {
      max-width: 450px;
      float: right;
      position: absolute;
      top: 46px;
      left: 0;
      z-index: 9;
      background-color: #fff;
      border: 1px solid #ccc;
      max-height: 280px;
      overflow-y: auto;
      width: 100%;
      &.dark-search{
        background-color: #1b1b1b;
        color: #fff;
      }
      li {
        padding: 7px;
        margin: 0 !important;
        cursor: pointer;

        &:hover {
          background-color: #f7f7f7;
          color: var(--themedefaultColor) !important;
        }
      }

      .view-all {
        text-align: center;
        padding: 8px 0;
        background-color: #ccc;
        font-weight: 600;

        a {
          color: #fff;
          font-size: 15px;
        }
      }
    }
  }

  .beachTheme {
    padding: unset !important;
  }

  &.apparelTheme {
    padding: unset !important;
    padding-left: 0 !important;
    padding-right: 15px !important;
    padding-bottom: 25px !important;
  } 
  &.dark-search {
    background-color: #1b1b1b !important;
  }
}
.main-menu nav ul li ul.mega-menu>li>ul.imageUl>li {
  border-right: 1px dashed #cccc;
}

.main-menu nav ul li ul.mega-menu>li>ul.imageUl>li:last-child {
  border-right: none;
}

ul.imageU.submenu a {
  display: block !important;
  height: auto !important;
}
.btn-cat {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px 1px 3px;
  transition: all .2s linear 0s;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  width: auto;
  height: auto;
  line-height: 1.1;
  margin: 0px 15px;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
.apparelTheme .sidebar-menu nav ul li ul.submenu-left li ul.submenu-left{
  top:0px !important
}