/*----------------------------------------*/

/*  1. Template default CSS
/*----------------------------------------*/

/*-- Google Font --*/
@import "../fonts/generated-fonts/genereted-font-style.css";
/*-- Common Style --*/
@font-face {
    font-family: "Geometria Light";
    src: url("../fonts/Geometria-Light.otf");
}
@font-face {
    font-family: "Lucida Sans";
    src: url("../fonts/Lucida\ Sans.ttf");
}
@font-face {
    font-family: "Hurme Bold";
    src: url("../fonts/HurmeGeometricSans1\ Bold.otf");
}
@font-face {
    font-family: "Hurme Thin";
    src: url("../fonts/HurmeGeometricSans1\ Thin.otf");
}
@font-face {
    font-family: "Hurme Regular";
    src: url("../fonts/HurmeGeometricSans1.otf");
}
@font-face {
    font-family: "Tahoma";
    src: url("../fonts/Tahoma\ Regular\ font.ttf");
}
@font-face {
    font-family: "Rockwell";
    src: url("../fonts/ROCK.TTF"), url("../fonts/rockb.ttf");
}
@font-face {
    font-family: "Hurme Geometric Sans No.4";
    src: url("//db.onlinewebfonts.com/t/f1631895ab87c699523b01ff210840a2.eot");
    src: url("//db.onlinewebfonts.com/t/f1631895ab87c699523b01ff210840a2.eot?#iefix") format("embedded-opentype"),
        url("//db.onlinewebfonts.com/t/f1631895ab87c699523b01ff210840a2.woff2") format("woff2"),
        url("//db.onlinewebfonts.com/t/f1631895ab87c699523b01ff210840a2.woff") format("woff"),
        url("//db.onlinewebfonts.com/t/f1631895ab87c699523b01ff210840a2.ttf") format("truetype"),
        url("//db.onlinewebfonts.com/t/f1631895ab87c699523b01ff210840a2.svg#Hurme Geometric Sans No.4") format("svg");
}
*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}
:root {
    --font-family: var(--font-family);
}
body {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;

    color: #000;
    background-color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family);
    font-weight: 400;
    margin-top: 0;
    color: $heading-color;
}

p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    color: #333;
}

h1 {
    font-size: 36px;
    line-height: 42px;
}

h2 {
    font-size: 30px;
    line-height: 36px;
}

h3 {
    font-size: 24px;
    line-height: 30px;
}

h4 {
    font-size: 18px;
    line-height: 24px;
}

h5 {
    font-size: 14px;
    line-height: 18px;
}

h6 {
    font-size: 12px;
    line-height: 14px;
}

p:last-child {
    margin-bottom: 0;
}

a,
button {
    line-height: inherit;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

a,
button,
img,
input,
span {
    transition: all 0.3s ease 0s;
}

*:focus {
    outline: none !important;
}

a:focus {
    text-decoration: none;
    color: inherit;
    outline: none;
}

a:hover {
    text-decoration: none;
}

button,
input[type="submit"] {
    cursor: pointer;
}

ul {
    margin: 0;
    padding: 0;
    list-style: outside none none;
}

/*-- 
    - Common Classes
-----------------------------------------*/

.fix {
    overflow: hidden;
}

.hidden {
    display: none;
}

.clear {
    clear: both;
}

.section {
    float: left;
    width: 100%;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.bg-img {
    background-position: center center;
    background-size: cover;
}

.border-bottom-1 {
    border-bottom: 1px solid #d5d5d5;
}

a:hover {
    color: $themehover-color;
}

.pe-7s-angle-right {
    color: $slidertext-color;
}
.pe-7s-angle-left {
    color: $slidertext-color;
}
/*************************
  Basic margin padding
*************************/

.m-0 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.p-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

/*************************
         Margin top
*************************/

.mt-0 {
    margin-top: 0;
}

.mt-10 {
    margin-top: 10px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}
.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-80 {
    margin-top: 80px;
    @media #{$xs-layout} {
        margin-top: 60px;
    }
}

.mt-90 {
    margin-top: 90px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-150 {
    margin-top: 150px;
}
.mt-160 {
    margin-top: 160px;
}
.mt-170 {
    margin-top: 170px;
}
.mt-180 {
    margin-top: 180px;
}
.mt-190 {
    margin-top: 190px;
}
.mt-195 {
    margin-top: 195px;
}
.mt-200 {
    margin-top: 200px;
}

/*************************
      Margin right
*************************/

.mr-0 {
    margin-right: 0;
}

.mr-10 {
    margin-right: 10px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-35 {
    margin-right: 35px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-60 {
    margin-right: 60px;
}

.mr-70 {
    margin-right: 70px;
}

.mr-80 {
    margin-right: 80px;
}

.mr-90 {
    margin-right: 90px;
}

.mr-100 {
    margin-right: 100px;
}

.mr-110 {
    margin-right: 110px;
}

.mr-120 {
    margin-right: 120px;
}

.mr-130 {
    margin-right: 130px;
}

.mr-140 {
    margin-right: 140px;
}

.mr-150 {
    margin-right: 150px;
}
.mr-160 {
    margin-right: 160px;
}
.mr-170 {
    margin-right: 170px;
}
.mr-180 {
    margin-right: 180px;
}
.mr-190 {
    margin-right: 190px;
}
.mr-200 {
    margin-right: 200px;
}

/*************************
      Margin bottom
*************************/

.mb-0 {
    margin-bottom: 0;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}
.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
    @media #{$xs-layout} {
        margin-bottom: 60px;
    }
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
    @media #{$xs-layout} {
        margin-bottom: 50px;
    }
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-150 {
    margin-bottom: 150px;
}
.mb-160 {
    margin-bottom: 160px;
}
.mb-170 {
    margin-bottom: 170px;
}
.mb-180 {
    margin-bottom: 180px;
}
.mb-190 {
    margin-bottom: 190px;
}
.mb-200 {
    margin-bottom: 200px;
}

/*************************
        Margin left
*************************/

.ml-0 {
    margin-left: 0;
}

.ml-10 {
    margin-left: 10px;
}

.ml-12 {
    margin-left: 12px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-55 {
    margin-left: 55px;
}

.ml-60 {
    margin-left: 60px;
}

.ml-70 {
    margin-left: 70px;
}
.ml-75 {
    margin-left: 75px;
}

.ml-80 {
    margin-left: 80px;
}

.ml-90 {
    margin-left: 90px;
}
.ml-95 {
    margin-left: 95px;
}

.ml-100 {
    margin-left: 100px;
}

.ml-110 {
    margin-left: 110px;
}

.ml-120 {
    margin-left: 120px;
}

.ml-130 {
    margin-left: 130px;
}

.ml-140 {
    margin-left: 140px;
}
.ml-145 {
    margin-left: 145px;
}

.ml-150 {
    margin-left: 150px;
}
.ml-160 {
    margin-left: 160px;
}
.ml-170 {
    margin-left: 170px;
}
.ml-180 {
    margin-left: 180px;
}
.ml-190 {
    margin-left: 190px;
}
.ml-200 {
    margin-left: 200px;
}
.ml-ntv5 {
    margin-left: -5px;
}

/*************************
        Padding top
*************************/

.pt-0 {
    padding-top: 0;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}
.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-70 {
    padding-top: 70px;
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}

.pt-95 {
    padding-top: 95px;
    @media #{$xs-layout} {
        padding-top: 55px;
    }
}

.pt-90 {
    padding-top: 90px;
    @media #{$xs-layout} {
        padding-top: 50px;
    }
}

.pt-100 {
    padding-top: 100px;
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
    @media #{$xs-layout} {
        padding-top: 40px;
    }
}

.pt-115 {
    padding-top: 115px;
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}

.pt-120 {
    padding-top: 120px;
    @media #{$xs-layout} {
        padding-top: 50px;
    }
}

.pt-125 {
    padding-top: 125px;
    @media #{$xs-layout} {
        padding-top: 55px;
    }
    @media #{$md-layout} {
        padding-top: 75px;
    }
}

.pt-128 {
    padding-top: 128px;
}

.pt-130 {
    padding-top: 130px;
    @media #{$xs-layout} {
        padding-top: 60px;
    }
    @media #{$md-layout} {
        padding-top: 80px;
    }
}

.pt-135 {
    padding-top: 135px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-155 {
    padding-top: 155px;
}

.pt-160 {
    padding-top: 160px;
}
.pt-170 {
    padding-top: 170px;
}

.pt-180 {
    padding-top: 180px;
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}
.pt-190 {
    padding-top: 190px;
}
.pt-200 {
    padding-top: 200px;
}

.pt-205 {
    padding-top: 205px;
}

.pt-230 {
    padding-top: 230px;
}

.pt-270 {
    padding-top: 270px;
    @media #{$md-layout} {
        padding-top: 170px;
    }
    @media #{$xs-layout} {
        padding-top: 100px;
    }
}

.pt-240 {
    padding-top: 240px;
}

.pt-365 {
    padding-top: 365px;
}

.pt-370 {
    padding-top: 370px;
}

.pt-380 {
    padding-top: 380px;
}

/*************************
        Padding right
*************************/

.pr-0 {
    padding-right: 0;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.pr-50 {
    padding-right: 50px;
}

.pr-60 {
    padding-right: 60px;
}

.pr-70 {
    padding-right: 70px;
}

.pr-80 {
    padding-right: 80px;
}

.pr-90 {
    padding-right: 90px;
}

.pr-100 {
    padding-right: 100px;
}

.pr-110 {
    padding-right: 110px;
}

.pr-120 {
    padding-right: 120px;
}

.pr-130 {
    padding-right: 130px;
}

.pr-140 {
    padding-right: 140px;
}

.pr-155 {
    padding-right: 155px;
}

.pr-160 {
    padding-right: 160px;
}
.pr-170 {
    padding-right: 170px;
}
.pr-180 {
    padding-right: 180px;
}
.pr-190 {
    padding-right: 190px;
}
.pr-200 {
    padding-right: 200px;
}

.pr-270 {
    padding-right: 270px;
}

/*************************
        Padding bottom
*************************/

.pb-0 {
    padding-bottom: 0;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}
.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-50 {
    padding-bottom: 50px;
}
.pb-55 {
    padding-bottom: 55px;
    @media #{$xs-layout} {
        padding-bottom: 15px;
    }
}

.pb-60 {
    padding-bottom: 60px;
    @media #{$xs-layout} {
        padding-bottom: 20px;
    }
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
    @media #{$xs-layout} {
        padding-bottom: 30px;
    }
}

.pb-80 {
    padding-bottom: 80px;
    @media #{$xs-layout} {
        padding-bottom: 40px;
    }
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-95 {
    padding-bottom: 95px;
    @media #{$xs-layout} {
        padding-bottom: 55px;
    }
}

.pb-100 {
    padding-bottom: 100px;
    @media #{$xs-layout} {
        padding-bottom: 30px;
    }
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
}

.pb-120 {
    padding-bottom: 120px;
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-130 {
    padding-bottom: 130px;
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
    @media #{$md-layout} {
        padding-bottom: 80px;
    }
}

.pb-140 {
    padding-bottom: 140px;
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
}

.pb-145 {
    padding-bottom: 145px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-160 {
    padding-bottom: 160px;
}
.pb-170 {
    padding-bottom: 170px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-190 {
    padding-bottom: 190px;
}
.pb-200 {
    padding-bottom: 200px;
}

.pb-280 {
    padding-bottom: 280px;
}

.pb-210 {
    padding-bottom: 210px;
}

.pb-285 {
    padding-bottom: 285px;
}

.pb-270 {
    padding-bottom: 270px;
    @media #{$md-layout} {
        padding-bottom: 170px;
    }
    @media #{$xs-layout} {
        padding-bottom: 100px;
    }
}

.pb-290 {
    padding-bottom: 290px;
}

/*************************
        Padding left
*************************/

.pl-0 {
    padding-left: 0;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-50 {
    padding-left: 50px;
}

.pl-60 {
    padding-left: 60px;
}

.pl-70 {
    padding-left: 70px;
}

.pl-80 {
    padding-left: 80px;
}

.pl-90 {
    padding-left: 90px;
}

.pl-100 {
    padding-left: 100px;
}

.pl-110 {
    padding-left: 110px;
}

.pl-120 {
    padding-left: 120px;
}

.pl-130 {
    padding-left: 130px;
}

.pl-140 {
    padding-left: 140px;
}

.pl-150 {
    padding-left: 150px;
}

.pl-155 {
    padding-left: 155px;
}

.pl-160 {
    padding-left: 160px;
}
.pl-170 {
    padding-left: 170px;
}
.pl-180 {
    padding-left: 180px;
}
.pl-190 {
    padding-left: 190px;
}
.pl-200 {
    padding-left: 200px;
}

/***************************
    Page section padding 
****************************/

.ptb-0 {
    padding: 0;
}

.ptb-10 {
    padding: 10px 0;
}

.ptb-20 {
    padding: 20px 0;
}

.ptb-30 {
    padding: 30px 0;
}

.ptb-35 {
    padding: 35px 0;
}

.ptb-32 {
    padding: 32px 0;
}

.ptb-40 {
    padding: 40px 0;
}

.ptb-50 {
    padding: 50px 0;
}

.ptb-60 {
    padding: 60px 0;
}

.ptb-70 {
    padding: 70px 0;
}

.ptb-80 {
    padding: 80px 0;
}

.ptb-90 {
    padding: 90px 0;
}

.ptb-100 {
    padding: 100px 0;
}

.ptb-110 {
    padding: 110px 0;
}

.ptb-120 {
    padding: 120px 0;
}

.ptb-130 {
    padding: 130px 0;
}

.ptb-140 {
    padding: 140px 0;
}

.ptb-150 {
    padding: 150px 0;
}

.ptb-160 {
    padding: 160px 0;
}

.ptb-170 {
    padding: 170px 0;
}

.ptb-177 {
    padding: 177px 0;
}

.ptb-180 {
    padding: 180px 0;
}

.ptb-190 {
    padding: 190px 0;
}

.ptb-200 {
    padding: 200px 0;
}

.ptb-210 {
    padding: 210px 0;
}

.ptb-220 {
    padding: 220px 0;
}

.ptb-290 {
    padding: 290px 0;
}

.ptb-310 {
    padding: 310px 0;
}

/***************************
    Page section margin 
****************************/

.mtb-0 {
    margin: 0;
}

.mtb-10 {
    margin: 10px 0;
}

.mtb-15 {
    margin: 15px 0;
}

.mtb-20 {
    margin: 20px 0;
}

.mtb-30 {
    margin: 30px 0;
}

.mtb-40 {
    margin: 40px 0;
}

.mtb-50 {
    margin: 50px 0;
}

.mtb-60 {
    margin: 60px 0;
}

.mtb-70 {
    margin: 70px 0;
}

.mtb-80 {
    margin: 80px 0;
}

.mtb-90 {
    margin: 90px 0;
}

.mtb-100 {
    margin: 100px 0;
}

.mtb-110 {
    margin: 110px 0;
}

.mtb-120 {
    margin: 120px 0;
}

.mtb-130 {
    margin: 130px 0;
}

.mtb-140 {
    margin: 140px 0;
}

.mtb-150 {
    margin: 150px 0;
}

.mtb-290 {
    margin: 290px 0;
}

.mb--10 {
    margin-bottom: -10px;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
    font-size: 14px;

    width: 100%;
    height: 45px;
    padding-left: 10px;

    color: #333;
    border: 2px solid #eceff8;
    background: #eceff8;
    box-shadow: none;
}

.input-border {
    border: 2px solid rgba(0, 0, 0, 0.384);
}

select {
    font-size: 14px;

    width: 100%;

    color: #333;
    border: 0 solid #eceff8;
    background: transparent;
    box-shadow: none;
    min-width: 50px;
}

option {
    font-size: 14px;

    padding-left: 10px;

    border: 0 solid #626262;
    background: #fff;
}

input:focus {
    background: transparent;
}

textarea {
    font-size: 14px;

    width: 100%;
    padding: 10px;

    resize: vertical;

    border: 2px solid #eceff8;
    background: #eceff8;
}

textarea:focus {
    border: 2px solid #4fc1f0;
    outline: none;
    background: transparent;
}

.input-group.divcenter.input-group .form-control {
    padding-left: 0;
}
.input-border {
    border: 2px solid #00000045;
}

.material-like-input {
    border: 1px solid #bbbbbb;
    background: transparent;
    border-radius: 3px;
    height: 56px;
}
.custom-row {
    display: flex;
    flex-wrap: wrap;

    margin-right: -15px;
    margin-left: -15px;
    @media #{$xl-layout} {
        margin-right: -15px;
        margin-left: -15px;
    }
    @media #{$lg-layout} {
        margin-right: -15px;
        margin-left: -15px;
    }
    @media #{$md-layout} {
        margin-right: -15px;
        margin-left: -15px;
    }
    @media #{$xs-layout} {
        margin-right: -15px;
        margin-left: -15px;
    }
}

.custom-row-2 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    & div[class^="col-"] {
        padding-right: 5px;
        padding-left: 5px;
        @media #{$md-layout} {
            padding-right: 15px;
            padding-left: 15px;
        }
        @media #{$xs-layout} {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    @media #{$xl-layout} {
        margin-right: -5px;
        margin-left: -5px;
    }
    @media #{$lg-layout} {
        margin-right: -5px;
        margin-left: -5px;
    }
    @media #{$md-layout} {
        margin-right: -15px;
        margin-left: -15px;
    }
    @media #{$xs-layout} {
        margin-right: -15px;
        margin-left: -15px;
    }
}
.custom-row-3 {
    display: flex;
    flex-wrap: wrap;

    margin-right: -13.6px;
    margin-left: -13.6px;
    & div[class^="col-"] {
        padding-right: 13.6px;
        padding-left: 13.6px;
        @media #{$xs-layout} {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    @media #{$xl-layout} {
        margin-right: -13.6px;
        margin-left: -13.6px;
    }
    @media #{$lg-layout} {
        margin-right: -13.6px;
        margin-left: -13.6px;
    }
    @media #{$md-layout} {
        margin-right: -13.6px;
        margin-left: -13.6px;
    }
    @media #{$xs-layout} {
        margin-right: -15px;
        margin-left: -15px;
    }
}

.custom-col-5 {
    position: relative;

    flex: 0 0 20%;

    width: 100%;
    max-width: 20%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    @media #{$xl-layout} {
        padding-right: 15px;
        padding-left: 15px;
    }
    @media #{$lg-layout} {
        flex: 0 0 33.333%;

        max-width: 33.333%;
        padding-right: 15px;
        padding-left: 15px;
    }
    @media #{$md-layout} {
        flex: 0 0 50%;

        max-width: 50%;
        padding-right: 15px;
        padding-left: 15px;
    }
    @media #{$xs-layout} {
        flex: 0 0 100%;

        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    @media #{$sm-layout} {
        flex: 0 0 50%;

        max-width: 50%;
    }
}

.mobile-menu-area {
    display: none;
    @media #{$md-layout} {
        display: block;
    }
    @media #{$xs-layout} {
        display: block;
    }
    @media #{$sm-layout} {
        display: block;
    }
    &.white-mobile-menu {
        a.meanmenu-reveal {
            color: #fff;
            border: 1px solid #fff;
            span {
                background-color: #fff;
            }
        }
    }
}

.stick {
    .mobile-menu-area {
        &.white-mobile-menu {
            a.meanmenu-reveal {
                color: #333;
                border: 1px solid #333;
                span {
                    background-color: #333;
                }
            }
        }
    }
}

.mean-container a.meanmenu-reveal {
    top: -30px;
}

#mobile-menu-active {
    display: none;
}

.mobile-menu .mean-nav ul.menu-overflow {
    overflow-y: auto;

    height: 215px;
    margin-top: 10;
}

.mobile-menu.mean-container .mean-nav ul {
    margin: 14px 0 0;
}

/*-- 
    Scroll Up 
-----------------------------------------*/

.scroll-top {
    position: fixed;
    z-index: 9811 !important;
    right: 20px;
    bottom: 60px;

    visibility: hidden;
    overflow: hidden;

    width: 50px;
    height: 50px;

    text-align: center;

    opacity: 0;
    color: #fff;
    border: none;
    border-radius: 50px;
    background: none;
    background-color: $theme-color;
    &.show {
        visibility: visible;

        opacity: 1;
    }
    @media #{$xs-layout} {
        width: 40px;
        height: 40px;
    }
    & i {
        font-size: 22px;
        line-height: 50px;

        display: block;
        @media #{$xs-layout} {
            font-size: 16px;
            line-height: 40px;
        }
    }
    &:hover {
        & i {
            animation-name: fadeInUp;
            animation-duration: 1s;
            animation-iteration-count: infinite;

            animation-fill-mode: both;
        }
    }
}

.height-100 {
    height: 100vh;
}

/*--
    - Background Color
------------------------------------------*/

.bg-blue {
    background-color: #2d21d2;
}
.bg-blue-2 {
    background-color: #1b0ceb;
}
.bg-gray {
    background-color: #f6f6f8;
}
.bg-gray-2 {
    background-color: #f6f6f6;
}

.bg-gray-3 {
    background-color: #f7f7f7;
}

.bg-gray-4 {
    background-color: #f1f1f1;
}
.bg-gray-5 {
    background-color: #f9f9f9;
}

.bg-gray-6 {
    background-color: #e9e6e9;
}

.bg-gray-7 {
    background-color: #f8f8f8;
}

.bg-black {
    background-color: #000000;
}
.bg-glaucous {
    background-color: #daedff;
}
.bg-aqua {
    background-color: #dbfffb;
}
.bg-white {
    background-color: #fff;
}

.default-overlay {
    position: relative;
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: "";
    }
}

.btn-hover-r {
    a {
        &::before,
        &::after {
            border-radius: 50px;
        }
    }
}

.btn-hover {
    a {
        position: relative;

        transition: all 0.5s ease-in-out 0s;
        &:hover {
            color: $themetext-color;
            border: 1px solid $theme-color;
        }
        &::before,
        &::after {
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;
            color: orange;
            width: 100%;
            height: 100%;

            content: "";
            transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &::after {
            right: 0;
            left: auto;

            width: 0;

            background: $theme-color;
        }
        &:hover::after {
            right: auto;
            left: 0;

            width: 100%;
        }
    }

    button {
        position: relative;

        transition: all 0.5s ease-in-out 0s;
        &:hover {
            color: $themetext-color;
            border: 1px solid $theme-color;
        }
        &::before,
        &::after {
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;
            color: $themetext-color;
            width: 100%;
            height: 100%;

            content: "";
            transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &::after {
            right: 0;
            left: auto;

            width: 0;

            background: $theme-color;
        }
        &:hover::after {
            right: auto;
            left: 0;

            width: 100%;
        }
    }

    &--blue {
        a,
        button {
            &:hover {
                border-color: #ffb893 !important;
            }
            &::after {
                background: #ffb893;
            }
        }
    }
}

a.default-btn, button.default-btn {
    font-size: 16px !important;
    line-height: 1;

    display: inline-block;

    padding: 19px 50px 21px !important;

    text-transform: uppercase;

    color: #010101 !important;
    border: 1px solid #333 !important;
    width: 100%;
    border-radius: 0;
}

/* slider img bounce */

@-webkit-keyframes bounce-img {
    0% {
        transform: translateY(0);

        opacity: 1;
    }
    50% {
        transform: translateY(20px);

        opacity: 1;
    }
    100% {
        transform: translateY(0);

        opacity: 1;
    }
}

@keyframes bounce-img {
    0% {
        transform: translateY(0);

        opacity: 1;
    }
    50% {
        transform: translateY(20px);

        opacity: 1;
    }
    100% {
        transform: translateY(0);

        opacity: 1;
    }
}

@keyframes snow {
    0% {
        background-position: 0 0, 0 0, 0 0;
    }
    100% {
        background-position: 500px 1000px, 400px 400px, 300px 300px;
    }
}
.effect-snow {
    animation: snow 20s linear infinite;
}

.body-effect {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0);
}

.padding-10-row-col {
    .row {
        margin-right: -5px;
        margin-left: -5px;
        div[class^="col-"] {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
}
.padding-20-row-col {
    .row {
        margin-right: -10px;
        margin-left: -10px;
        div[class^="col-"] {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

[class*="ToastContainer"] {
    overflow: hidden !important;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.reward-table-container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.item-empty-area {
    &__icon {
        i {
            font-size: 140px;
            line-height: 1;
            @media #{$xs-layout} {
                font-size: 100px;
            }
        }
    }

    &__text {
        font-size: 20px;
        a {
            font-size: 14px;
            font-weight: 500;

            display: inline-block;

            margin-top: 30px;
            padding: 10px 30px;

            transition: 0.3s;

            color: $white;
            background-color: #363f4d;
            &:hover {
                color: $white;
                background-color: $theme-color;
            }
        }
    }
}

.fullpage-slider-wrapper {
    .Navigation {
        .Navigation-Anchor {
            background-color: #333 !important;
        }
    }
}

.bg-white {
    background-color: $white;
}

.rounden-btn {
    overflow: hidden;

    border-radius: 50px;
    &:before,
    &:after {
        border-radius: 50px;
    }
}

.bg-black-2 {
    background-color: #1b1b1b;
}

.bg-black-3 {
    background-color: #202022;
}
.withoutBorderBtn {
    background: "none" !important;
    border: "none";
    border-bottom: "2px solid #9b9b9c";
}
.ql-container {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    height: 100%;
    margin: 0px;
    position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
    visibility: hidden;
}
.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
    pointer-events: none;
}
.ql-clipboard {
    left: -100000px;
    height: 1px;
    overflow-y: hidden;
    position: absolute;
    top: 50%;
}
.ql-clipboard p {
    margin: 0;
    padding: 0;
}
.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol,
.ql-editor ul {
    padding-left: 1.5em;
}
.ql-editor ol > li,
.ql-editor ul > li {
    list-style-type: none;
}
.ql-editor ul > li::before {
    content: "\2022";
}
.ql-editor ul[data-checked="true"],
.ql-editor ul[data-checked="false"] {
    pointer-events: none;
}
.ql-editor ul[data-checked="true"] > li *,
.ql-editor ul[data-checked="false"] > li * {
    pointer-events: all;
}
.ql-editor ul[data-checked="true"] > li::before,
.ql-editor ul[data-checked="false"] > li::before {
    color: #777;
    cursor: pointer;
    pointer-events: all;
}
.ql-editor ul[data-checked="true"] > li::before {
    content: "\2611";
}
.ql-editor ul[data-checked="false"] > li::before {
    content: "\2610";
}
.ql-editor li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
}
.ql-editor li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
}
.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
    padding-right: 1.5em;
}
.ql-editor ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
}
.ql-editor ol li:before {
    content: counter(list-0, decimal) ". ";
}
.ql-editor ol li.ql-indent-1 {
    counter-increment: list-1;
}
.ql-editor ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2 {
    counter-increment: list-2;
}
.ql-editor ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3 {
    counter-increment: list-3;
}
.ql-editor ol li.ql-indent-3:before {
    content: counter(list-3, decimal) ". ";
}
.ql-editor ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4 {
    counter-increment: list-4;
}
.ql-editor ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5 {
    counter-increment: list-5;
}
.ql-editor ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6 {
    counter-increment: list-6;
}
.ql-editor ol li.ql-indent-6:before {
    content: counter(list-6, decimal) ". ";
}
.ql-editor ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-7 {
    counter-increment: list-7;
}
.ql-editor ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-8 {
    counter-increment: list-8;
}
.ql-editor ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-8 {
    counter-reset: list-9;
}
.ql-editor ol li.ql-indent-9 {
    counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
    content: counter(list-9, decimal) ". ";
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
}
.ql-editor .ql-video {
    display: block;
    max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
    margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
    margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
    background-color: #000;
}
.ql-editor .ql-bg-red {
    background-color: #e60000;
}
.ql-editor .ql-bg-orange {
    background-color: #f90;
}
.ql-editor .ql-bg-yellow {
    background-color: #ff0;
}
.ql-editor .ql-bg-green {
    background-color: #008a00;
}
.ql-editor .ql-bg-blue {
    background-color: #06c;
}
.ql-editor .ql-bg-purple {
    background-color: #93f;
}
.ql-editor .ql-color-white {
    color: #fff;
}
.ql-editor .ql-color-red {
    color: #e60000;
}
.ql-editor .ql-color-orange {
    color: #f90;
}
.ql-editor .ql-color-yellow {
    color: #ff0;
}
.ql-editor .ql-color-green {
    color: #008a00;
}
.ql-editor .ql-color-blue {
    color: #06c;
}
.ql-editor .ql-color-purple {
    color: #93f;
}
.ql-editor .ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
}
.ql-editor .ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
}
.ql-editor .ql-size-small {
    font-size: 0.75em;
}
.ql-editor .ql-size-large {
    font-size: 1.5em;
}
.ql-editor .ql-size-huge {
    font-size: 2.5em;
}
.ql-editor .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
}
.ql-editor .ql-align-center {
    text-align: center;
}
.ql-editor .ql-align-justify {
    text-align: justify;
}
.ql-editor .ql-align-right {
    text-align: right;
}
.ql-editor.ql-blank::before {
    color: rgba(0, 0, 0, 0.6);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
}
.ql-snow {
    box-sizing: border-box;
}
.ql-snow * {
    box-sizing: border-box;
}
.ql-snow .ql-hidden {
    display: none;
}
.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
    visibility: hidden;
}
.ql-snow .ql-tooltip {
    position: absolute;
    transform: translateY(10px);
}
.ql-snow .ql-tooltip a {
    cursor: pointer;
    text-decoration: none;
}
.ql-snow .ql-tooltip.ql-flip {
    transform: translateY(-10px);
}
.ql-snow .ql-formats {
    display: inline-block;
    vertical-align: middle;
}
.ql-snow .ql-formats:after {
    clear: both;
    content: "";
    display: table;
}
.ql-snow .ql-stroke {
    fill: none;
    stroke: #444;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}
.ql-snow .ql-stroke-miter {
    fill: none;
    stroke: #444;
    stroke-miterlimit: 10;
    stroke-width: 2;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: #444;
}
.ql-snow .ql-empty {
    fill: none;
}
.ql-snow .ql-even {
    fill-rule: evenodd;
}
.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
    stroke-width: 1;
}
.ql-snow .ql-transparent {
    opacity: 0.4;
}
.ql-snow .ql-direction svg:last-child {
    display: none;
}
.ql-snow .ql-direction.ql-active svg:last-child {
    display: inline;
}
.ql-snow .ql-direction.ql-active svg:first-child {
    display: none;
}
.ql-snow .ql-editor h1 {
    font-size: 2em;
}
.ql-snow .ql-editor h2 {
    font-size: 1.5em;
}
.ql-snow .ql-editor h3 {
    font-size: 1.17em;
}
.ql-snow .ql-editor h4 {
    font-size: 1em;
}
.ql-snow .ql-editor h5 {
    font-size: 0.83em;
}
.ql-snow .ql-editor h6 {
    font-size: 0.67em;
}
.ql-snow .ql-editor a {
    text-decoration: underline;
}
.ql-snow .ql-editor blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}
.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
    background-color: #f0f0f0;
    border-radius: 3px;
}
.ql-snow .ql-editor pre {
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
}
.ql-snow .ql-editor code {
    font-size: 85%;
    padding: 2px 4px;
}
.ql-snow .ql-editor pre.ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: visible;
}
.ql-snow .ql-editor img {
    max-width: 100%;
}
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    position: relative;
    vertical-align: middle;
}
.ql-snow .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;
}
.ql-snow .ql-picker-label::before {
    display: inline-block;
    line-height: 22px;
}
.ql-snow .ql-picker-options {
    background-color: #fff;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
}
.ql-snow .ql-picker-options .ql-picker-item {
    cursor: pointer;
    display: block;
    padding-bottom: 5px;
    padding-top: 5px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #ccc;
    z-index: 2;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    display: block;
    margin-top: -1px;
    top: 100%;
    z-index: 1;
}
.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
    width: 28px;
}
.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
    padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
    right: 4px;
}
.ql-snow .ql-icon-picker .ql-picker-options {
    padding: 4px 0px;
}
.ql-snow .ql-icon-picker .ql-picker-item {
    height: 24px;
    width: 24px;
    padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-options {
    padding: 3px 5px;
    width: 152px;
}
.ql-snow .ql-color-picker .ql-picker-item {
    border: 1px solid transparent;
    float: left;
    height: 16px;
    margin: 2px;
    padding: 0px;
    width: 16px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px;
    right: 0;
    top: 50%;
    width: 18px;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""])::before {
    content: attr(data-label);
}
.ql-snow .ql-picker.ql-header {
    width: 98px;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: "Normal";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "Heading 1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "Heading 2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "Heading 3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "Heading 4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "Heading 5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "Heading 6";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    font-size: 2em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    font-size: 1.5em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    font-size: 1.17em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    font-size: 1em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    font-size: 0.83em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    font-size: 0.67em;
}
.ql-snow .ql-picker.ql-font {
    width: 108px;
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: "Sans Serif";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    content: "Serif";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    content: "Monospace";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    font-family: Georgia, Times New Roman, serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    font-family: Monaco, Courier New, monospace;
}
.ql-snow .ql-picker.ql-size {
    width: 98px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: "Normal";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    content: "Small";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    content: "Large";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    content: "Huge";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    font-size: 32px;
}
.ql-snow .ql-color-picker.ql-background .ql-picker-item {
    background-color: #fff;
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item {
    background-color: #000;
}
.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    padding: 8px;
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 15px;
}
.ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: #ccc;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #ccc;
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
    border-color: #000;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
}
.ql-snow .ql-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ddd;
    color: #444;
    padding: 5px 12px;
    white-space: nowrap;
}
.ql-snow .ql-tooltip::before {
    content: "Visit URL:";
    line-height: 26px;
    margin-right: 8px;
}
.ql-snow .ql-tooltip input[type="text"] {
    display: none;
    border: 1px solid #ccc;
    font-size: 13px;
    height: 26px;
    margin: 0px;
    padding: 3px 5px;
    width: 170px;
}
.ql-snow .ql-tooltip a.ql-preview {
    display: inline-block;
    max-width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}
.ql-snow .ql-tooltip a.ql-action::after {
    border-right: 1px solid #ccc;
    content: "Edit";
    margin-left: 16px;
    padding-right: 8px;
}
.ql-snow .ql-tooltip a.ql-remove::before {
    content: "Remove";
    margin-left: 8px;
}
.ql-snow .ql-tooltip a {
    line-height: 26px;
}
.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
    display: none;
}
.ql-snow .ql-tooltip.ql-editing input[type="text"] {
    display: inline-block;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "Save";
    padding-right: 0px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "Enter link:";
}
.ql-snow .ql-tooltip[data-mode="formula"]::before {
    content: "Enter formula:";
}
.ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "Enter video:";
}
.ql-snow a {
    color: #06c;
}
.ql-container.ql-snow {
    border: 1px solid #ccc;
}
.categorySectionBanner {
    width: 100%;
    height: "auto";
    margin-right: 1%;
    align-items: stretch;
    @media screen and (max-width:"990px") {
        width: 100% !important;
        flex: 0 0 100% !important;
    }
}

.banner-area{
    @media screen and (max-width:"767px") {
        padding: 0 16px;
    }
    .single-banner, .single-banner-2{
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
        > a{
            // height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 230px;
            margin-bottom: 10px;
        }
        .banner-content-2, .banner-content-3{
            position: relative;
            left: unset;
            top: unset;
            padding: 6px;
            h4{
                margin: 10px 0 0;
            }
        }
    }
    .gridViewActive{
        flex-wrap: wrap !important;
        justify-content: center;
        .categorySectionBanner{
            flex: 0 0 33.33%;
            width: 33.33%;
            margin: 0;
        }
    }
}
.mx_width {
    .MuiDialog-container {
        > div {
            max-width: 850px;
            width: 100%;
        }
    }
}
.px-text {
    padding-top: 10px;
    display: inline-block;
    padding-left: 4px;
}
.grid-text-center {
    justify-content: center;
}
.error {
    color: red !important;
}
//.image-slider-area{
//  .swiper-wrapper{
//    justify-content:center ;
//  }
//}
//.categorySlider{
//  .swiper-wrapper{
//    justify-content:center ;
//  }
//}

.category-section-container {
    .container, 
    .container-fluid{
        position: relative;
        .swiper-button-next{
            // right: -50px;
            opacity: 0;
        }
        .swiper-button-prev{
            // left: -50px;
            opacity: 0;
        }
    }
    .inner-category{
        @media screen and (max-width:992px) {
            flex-wrap: wrap !important;
        }
    }
    .container-fluid{
        padding: 0 60px;
        @media screen and (max-width:992px) {
            padding: 0 20px;
        }
    }
    .categorySectionBanner{
        width: 20%;
    }
    .gridViewActive{
        .categorySectionBanner{
            width: 33.33%;
            flex: 0 0 33.33%;
        } 
    }
    &.banner-four-section{
        .categorySectionBanner{
            padding: 8px;
            .single-banner{
                height: 95%;
            }
        }
        .swiper-pagination {
            bottom: -5px;
        }
    }
}

.custome-swiper-btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 50%;
    font-size: 30px;
    background: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    cursor: pointer;
    z-index: 9;
    &.swiper-button-next1{
        right: -50px;
        @media screen and (max-width:992px) {
            right : 10px
        }
    }
    &.swiper-button-prev1{
        left: -50px;
        @media screen and (max-width:992px) {
            left : 10px
        }
    }
}

.categories-slider-container {
    .container-fluid{
        padding-left: 70px;
        padding-right: 70px;
        .swiper-button-prev1{
            left: 24px;
        }
        .swiper-button-next1{
            right: 24px;
        }
    }
    .container, .container-fluid{
        position: relative;
        .swiper-button-prev1{
            transform: translateY(calc(-50% - 36px))
        }
        .swiper-button-next1{
            transform: translateY(calc(-50% - 36px))
        }
        .swiper-button-next,
        .swiper-button-prev{
            display: none;
        }
    }
    .collection-product{
        &:hover{
            .flip-box.active{
                a img{
                    transform: scale(1);
                }
            }
            
        }
    }
    .collection-active .swiper-container{
        padding: 0 12px !important;
        width: 100%;
    }
    .collection-active .swiper-wrapper{
        width: 100%;
        justify-content: center;
        padding-top: 10px;
    }
    .swiper-pagination {
        display: block;
        position:relative;
        bottom: unset;
        top: 7px;
        .swiper-pagination-bullet-active{
            background: $theme-color
        }
    }

    .flip-box {
        padding: 15px;
        background-color: transparent;
        width: 100%;
        height: 280px;
        // border: 1px solid #f1f1f1;
        perspective: 1000px;
        .flip-box-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;
            display: block;
            .flip-box-front{
                width: 100%;
                height: 100%;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.active{
            .flip-box-inner {
                position: relative;
                width: 100%;
                height: 100%;
                text-align: center;
                transition: transform 0.8s;
                transform-style: preserve-3d;
                display: block;
            }
            &:hover{
                .flip-box-inner{
                    transform: rotateY(180deg);
                }
            }
            .flip-box-front,
            .flip-box-back {
                position: absolute;
                width: 100%;
                height: 100%;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                top: 0;
                box-shadow: 0 1px 15px rgba(0, 0, 0, 0.07);
            }
        
            .flip-box-front {
                background-color: #bbb;
                color: black;
                display: flex;
                align-items: center;
                justify-content: center;
        
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        
            .flip-box-back {
                background-color: #fff;
                color: #000;
                transform: rotateY(180deg);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                // padding: 10px;
        
                p {
                    font-size: 15px;
                }
                .backContent{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 90%;
                    width: 97%;
                    overflow-y: auto;
                    border-radius: 50px;
                    padding: 10px 20px;
                }

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    
}
.react-tel-input {
    .form-control {
        background: #eceff8 !important;
        width: 100% !important;
        height: 45px !important;
    }
}
.maxWidth {
    width: 100%;
    height: 45px;
    .MuiInputBase-formControl {
        .MuiSelect-select {
            padding: 13px 30px 13px 15px;
            background: #eceff8;
        }
    }
}
.pageLogo {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}
.swiper-button-prev {
    &.swiper-button-disabled {
        opacity: 0 !important;
    }
}
.swiper-button-next {
    &.swiper-button-disabled {
        opacity: 0 !important;
    }
}

.shop-area {
    .shop-bottom-area {
        .product-wrap {
            .product-img {
                max-width: 300px;
                width: 100%;
                background: #fff;
                height: 400px;
                margin: 0 auto;
                @media screen and (max-width:767px) {
                    height: 150px;
                    min-height: unset;
                    margin: 0 auto;
                }
                a {
                    flex-flow: column;
                    justify-content: space-evenly !important;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    figure {
                        margin: 0;
                        max-width: 100%;
                        max-height: 100%;
                        &:hover{
                            opacity: 1;
                        }
                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                    .overly-hover {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #fff !important;
                        background-size: contain !important;
                        transition: all 0.5s ease-in-out;
                        z-index: 9;
                    }
                    &:hover {
                        figure{
                            img.img-fluid-secondary{
                                opacity: 0;
                            }
                        }
                        .overly-hover {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .product-img-bg {
                        background-color: var(--product-img-bg) !important;
                    }
                }   
            }

            &.secondaryImage-hover-false{
                .product-img{
                    figure:hover{
                        opacity: 1;
                    }
                    a:hover{
                        figure{
                            img.img-fluid-secondary{
                                opacity: 1;
                            }
                        }
                        .overly-hover{
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }
            }
        }
    }
}
.hovercolorDiv{
        width: 23px;
        height: 23px;
        border-radius: 5px;
        cursor: pointer;
        margin: 2px;
        padding: 2px;
        &:hover{
            border: 1px solid #000;
        }
    
}
.hovercolorDivinner{
        width: 100%;
        height: 100%;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid #ededed;
}
.hovercolorDiv.active{
    border: 1px solid #000;
}
.colorDivrow{   
        .plusi{
            cursor: pointer;
            display: block;
        }
        .minusi{
            cursor: pointer;
            display: none;
        }
    
    &__show{
        .plusi{
            cursor: pointer;
            display: none;
        }
        .minusi{
            cursor: pointer;
            display: block;
        }
    }
}
.colorContainer{
    display: inline-flex;
    height: 53px;
    overflow: hidden;
   
    &__show{
        height: auto;
        overflow: auto;
        
    }
}
.dataFilter {
    max-height: 250px;
    overflow-y: auto;
    padding-left: 5px;
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .MuiSvgIcon-root {
        width: 19px;
        height: auto;
    }
    .MuiTypography-body1 {
        font-size: 14px;
        font-family: var(--font-family);
    }
    .PrivateSwitchBase-root-1 {
        padding: 6px;
    }
}
.filterTag {
    ul li {
        margin-bottom: 0 !important;
        label {
            padding: 9px 3px 6px !important;
            background: none !important;
            margin-bottom: 0 !important;
            &:hover {
                background: none !important;
                color: #424242 !important;
            }
        }
    }
}
.colorFilter {
   // display: flex;
    align-items: center;
	text-transform: capitalize;

    // input {
    //   opacity: 1 !important;
    //   position: relative !important;
    //   top: unset !important;
    //   width: 15px !important;
    //   margin-right: 6px !important;
    // }
    .MuiFormControlLabel-label {
        display: flex;
    }
    .colorbox {
        width: 20px;
        height: 20px;
		flex: 0 0 20px;
        margin-right: 6px;
    }
}
.MuiCheckbox-colorSecondary {
    &.Mui-checked {
        color: $theme-color !important;
    }
}
// .colorFilter label:before {
//   content:'';
//   -webkit-appearance: none;
//   background-color: #ff7f00;
//   border: 2px solid #000;
//   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
//   padding: 10px;
//   display: inline-block;
//   position: relative;
//   vertical-align: middle;
//   cursor: pointer;
//   margin-right: 5px;
// }

// .colorFilter input:checked + label:after {
//   content: '';
//   display: block;
//   position: absolute;
//   top: 2px;
//   left: 9px;
//   width: 6px;
//   height: 14px;
//   border: solid #fff;
//   background-color: #ff7f00;

//   border-width: 0 2px 2px 0;
//   transform: rotate(45deg);
// }
// .colorFilter {
//   display: block;
//   margin-bottom: 15px;
// }

// .colorFilter input {
//   padding: 0;
//   height: initial;
//   width: initial;
//   margin-bottom: 0;
//   display: none;
//   cursor: pointer;
// }

// .colorFilter label {
//   position: relative;
//   cursor: pointer;
// }
[class*="ToastContainer"] {
    z-index: 2000 !important;
}
.selectedItem {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
        padding: 0 5px 0 16px;
        font-size: 14px;
        line-height: 1;
        border-right: 1px solid #a1a5aa;
        margin-bottom: 2px;
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 600;
        &:last-child {
            border-right: 0;
            padding-right: 0;
        }
    }
}
.selectAll {
    span {
        cursor: pointer !important;
        font-weight: 600 !important;
    }
}

.colorbox {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.selectedColorBox {
    display: inline-block;
    height: 15px;
    width: 15px;
    position: relative;
    padding: 0 !important;
    margin: 0 20px 0 10px;
    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background: #a1a5aa !important;
        position: absolute;
        left: calc(100% + 18px);
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}
.colorDeleteIcon {
    cursor: pointer !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 2px);
    font-size: 12px !important;
}

.notlogin-form {
    padding: 24px;

    background-color: #f3f3f3;
    @media #{$lg-layout} {
        padding: 50px 50px 50px 50px;
    }
    @media #{$md-layout} {
        padding: 50px 30px 50px 30px;
    }
    @media #{$xs-layout} {
        padding: 30px 30px 30px 30px;
    }

    .notlogin-form-style {
        &.only-banner {
            max-width: 800px;
            .row {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        .bannerbutton {
            font-size: 14px;
            font-weight: 500;
            line-height: 1;

            margin-top: 38px;
            padding: 15px 52px;

            text-transform: uppercase;

            color: $themetext-color;
            border: none;
            background-color: $theme-color;
            &:hover {
                color: $themetext-color;
                background-color: $themehover-color;
            }
        }
        .cancelButton {
            font-size: 14px;
            font-weight: 500;
            line-height: 1;

            margin-top: 38px;
            padding: 15px 52px;

            text-transform: uppercase;

            color: #fff;
            border: none;
            background-color: #504a4a;
            &:hover {
                color: #000;
                background-color: #e7cece;
            }
        }
        .file-upload-wrapper {
            max-width: 300px;
        }
    }
    p {
        color: #333;
        &.success {
            margin-top: 10px;
        }
    }
}

#header {
    min-height: unset !important;
    // @media (max-width: 767px) {
    //     min-height: 65px !important;
    // }
}
.inputQty {
    position: relative;
    display: inline-block;
}
  
.inputQty .inputQtyTitle {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 76%;
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    border-radius: 5px;
    left: 50%;
    transition: all 0s ease 0s;
    margin: 0 auto;
    transform: translate(-50%, 0%);
    min-width: 75px;
}
  
.inputQty .inputQtyTitle::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 49%;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.inputQty .StepperTitle::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 43%;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.inputQty:hover .inputQtyTitle {
    visibility: visible;
}
.slider_spring {
    .animated {
        left: 0 !important;
    }
    .caption_block {
        margin-left: auto;
        .edit-inline {
            justify-content: center;
        }
    }
}
.bannerTextCenter {
    justify-content: center;
}
.bannerTextLeft {
    justify-content: flex-start !important;
}
.bannerTextRight {
    justify-content: flex-end!important;
}

.textBox-container{
    @media screen and (max-width:767px) {
        padding: 0 16px;
    }
    img{
        max-width: 100%;
    }
    table{
        tr{
          @media screen and (max-width:1200px) {
            display: flex;
            flex-wrap: wrap;
            td{
              width: auto !important;
              padding-right: 16px;
              margin-bottom: 16px;
              iframe{
                max-width: 100%;
              }
            }
          }
          @media screen and (max-width:767px) {
            display: flex;
            flex-direction: column;
            td{
              width: 100% !important;
              margin-bottom: 16px;
              iframe{
               height: auto;
              }
            }
          }
        }
      }
}
.breakable-price{
    text-decoration: line-through;
}

.section-bgImage{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.deco_per_stup{
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
	padding-right: 18px;
}
.deco_per_stup_currency{
    padding-right: 15px;
}

.static-page-container{
    .container-fluid{
        padding: 0;
    }
    .ql-editor{
        padding: 0;
        white-space: normal;
    }
    img{
        max-width: 100%;
    }
    @media screen and (max-width:992px) {
        & > div {
            padding: 0 24px;
        }
    }
    @media screen and (max-width:767px) {
        & > div {
            padding: 0 16px;
        }
    }
}

.productDetailBox {
    width: max-content;
    margin: 30px auto 0;
    font-size: 17px;
    .productListing{
        margin-bottom: 16px;
        display: flex;
    }
    .leftBox{
        width:180px ;
    }
}
.contact-center{
	justify-content: center;
}

.selectOption{
    min-height: 45px;
    border: 1px solid #ccc;
    margin: 10px 0;
    padding: 3px;
}

.cart-table-content{
    .productAsKits{
        background: #fbfbfb;
        .cartSizeTable{
            .delete-size{
                .deleteBtn{
                    display: none;
                }
            }
        }
        .product-remove{
            button{
                display: none;
            }
        }
    }
}
.product-img-bg {
    background-color: var(--product-img-bg) !important;
}

.product-img {
    figure {
        z-index: 5;
    }
    figure:hover {
        opacity: 0;
        transition: 0.3s;
    }
}

.lg-outer {
    z-index: 1350;
}

.lg-toolbar {
    .lg-icon {
        color: white;
        
        &:hover {
            color: #cccccc;
            transition: color 0.2s ease;
        }
    }
    
    #lg-zoom-in:after,
    #lg-zoom-out:after,
    .lg-fullscreen:after,
    #lg-actual-size:after,
    .lg-close:after,
    .lg-download:after {
        color: white;
    }
    
    #lg-zoom-in:hover:after,
    #lg-zoom-out:hover:after,
    .lg-fullscreen:hover:after,
    #lg-actual-size:hover:after,
    .lg-close:hover:after,
    .lg-download:hover:after {
        color: #cccccc;
    }
    
    .lg-group {
        #lg-zoom-in:hover,
        .lg-download:hover {
            color: #cccccc;
        }
    }
    
    #lg-actual-size:hover {
        color: #cccccc;
    }
}

.lg-img-wrap{
    &.lg-img-zoom{
        overflow: scroll;
        & > .lg-image{
            display: none;
        }
    }
    .lightGal{
        position: absolute;
        left: 50%;
        transform: scale(1) translateX(-50%);
        top: 0;
        height: 100%;
        transform-origin: left top;
        transition: all 0.3s ease-in-out;
        .containerimg{
            max-width: unset;
            height: 100%;
        }
        .react_lightgallery_item{
            height: 100%;
            & > img{
                width: unset;
                max-width: unset !important;
                height: 100%;
            }
        }
        .overlayimg{
            width: auto;
        }
    }
}
.product-attribute {
    .product-sticky-image {
        display: flex;
        white-space: nowrap;
        overflow: hidden;

        .single_image {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.singleImg .swiper-slide{
    width: 100% !important;
}

.tag-product-slider{
    .swiper-container{
        width: 100%;
    }
}

.disabled-link-2{
    pointer-events: none;
}

.static-page-container {
    .ql-editor{
        p{
            margin-bottom: 16px;
        }
        dl, ol, ul, blockquote, pre, h1, h2, h3, h4, h5, h6{
            margin-bottom: 1rem;
        }
        li{
            line-height: 24px;
        }
    }
} 
.deactive-page {
    .error-contain {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &>h2:first-child {
            font-size: 35px;
            margin-bottom: 30px;
        }

        a {
            color: rgb(42, 162, 255);
            font-size: 20px;
            display: block;
        }
    }
}
.points-table{
	width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.points-balance{
    color: #5a5757;
    font-size: 20px;
    margin-bottom: 20px;
}
.points-head{
	background-color:lightgray !important
}

.deco_per_stup2 {
    display: flex;
    justify-content: flex-end;
    padding-left: 2px;
    padding-right: 2px;
}

.deco_per_stup_currency2 {
    padding-right: 2px;
    z-index: 100;
}

.impersonatingUser{
    position: fixed;
    top: 36px;
    left: 20px;
    z-index: 99999;
    background: #ff7f00;
    color: #ffff;
    padding: 6px 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.20);
    font-size: 16px;
    b{
        font-weight: 600;
    }
}

.phoneInput{
    .react-tel-input{
        input {
            padding-left: 36px !important;
            background: #fff !important;

        }
    }
}

.category-section-container{
    .swiper-container{
        .swiper-slide{
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.product-container {
    .order-1 {
        img {
            @media only screen and (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
}

.login-layout,
.register-layout,
.forgotPassword-layout{
    .home-sidebar-left{
        display: none;
    }
    header{
        display: none;
    }
    .searchRow {
        display: none;
    }
}

.remove-search{
    position: absolute;
    top: 10px;
    right: 60px;
    opacity: 0.5;
    cursor: pointer;
}

.form-dialog-title{
    h2{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.locModalChk {
    display: flex;
    margin-top: 20px;
    justify-content: end;
    align-items: center;
    width: 100%;
}

label[for=iframesCardNumber], label[for=iframesCardExpiration], label[for=iframesCardCvv] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: #555;
    line-height: 1.5;
}

#ss-banner {
    background: transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-shield@2x.png?raw=true) no-repeat left center;
    height: 40px;
    background-size: 280px 34px;
    margin-bottom: 7px;
}

.form-row {
  margin-top: 10px;
}

.form-wrapper {
  display: block;
  width: 300px;
  margin: 10px auto;
}

.main-menu nav ul li ul.submenu-left {
    &.rolling-submenu {
        padding-top: 0 !important;
        width: 175px;
        &.add-maxwidth{
            width: unset !important;
        }
        ul{
            margin-top: 0;
        }

        .p-slidemenu {
            width: 100%;
            background: transparent;
            border: none;

            .p-slidemenu-wrapper {
                max-height: 300px;
            }

            .p-slidemenu-rootlist {
                position: relative;
                .p-menuitem-link {
                    position: relative;
                    padding: 0;
                    flex: 1;
                    &::before{
                        content: unset;
                    }
                    .p-menuitem-icon{
                        display: none;
                        &.fa{
                            display: block;
                        }
                    }
                    &:focus {
                        box-shadow: none
                    }
                }
                .p-menuitem-icon.fa {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .p-menuitem-link:not(.p-disabled):hover {
                    background: transparent
                }
                .rollover-next-btn{
                    .rollover-next-arrow{
                        width: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                    }
                }
                
            }
            .p-slidemenu-backward{
                text-align: left;
                font-weight: 600;
            }
        }
    }

}

.submenu-left{
    .p-slidemenu {
        width: 100%;
        background: transparent;
        border: none;

        .p-slidemenu-wrapper {
            max-height: 300px;
        }

        .p-slidemenu-rootlist {
            position: relative;
            .p-menuitem-link {
                position: relative;
                padding: 0;
                flex: 1;
                &::before{
                    content: unset;
                }
                .p-menuitem-icon{
                    display: none;
                    &.fa{
                        display: block;
                    }
                }
                &:focus {
                    box-shadow: none
                }
            }
            .p-menuitem-icon.fa {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .p-menuitem-link:not(.p-disabled):hover {
                background: transparent
            }
            .rollover-next-btn{
                .rollover-next-arrow{
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                }
            }
            
        }
        .p-slidemenu-backward{
            text-align: left;
            font-weight: 600;
        }
    }
}

.select-search-address {
    input {
        height: 26px;
    }

    .css-1nmdiq5-menu {
        width: fit-content !important;
        white-space: nowrap;
        z-index: 1000;
    }
    .css-1n6sfyn-MenuList {
        div {
            white-space: nowrap;
        }
    }
    .css-26l3qy-menu,
    #react-select-2-listbox,
    #react-select-5-listbox
    #react-select-3-listbox {
        width: fit-content !important;
        z-index: 9999;
        >div {
            >div {
                white-space: nowrap;
            }
        }
    }
}

.disabled-block-print {
    background-color: hsl(0, 0%, 95%);
    border-radius: 7px;
    border: 5px hsl(0, 0%, 90%);
}

.block-print {
    border-radius: 7px;
}

.select-search-dyn-print {
    input {
        height: 26px;
        width: 100%;
    }
    .css-1nmdiq5-menu {
        z-index: 1000;
    }
    .css-b62m3t-container {
        width: 100%!important;
    }
    .css-3iigni-container {
        min-width: 100%;
    }
    .css-26l3qy-menu,
    #react-select-2-listbox,
    #react-select-3-listbox {
        z-index: 9999;
        >div {
            >div {
                white-space: nowrap;
            }
        }
    }
}
.breadcrumbs-container{
    text-transform: uppercase;
    ol{
        justify-content: center;
    }
}

.btn-primary{
    color: $themetext-color;
    background-color: $theme-color;
    border-color: $theme-color;
    &:hover{
        background-color: $themehover-color;
        border-color: $themehover-color;
        color: $themetext-color;
    }
}
.NoProductFound
{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}
.NoProductFound p {
    color: red;
    font-weight: 600;
}
  @page {
    size: A4;
    margin: 1cm; 
  }
  @media print {
    .pages {
      top: 10px !important;
      z-index: 9999;
      left: 0 !important;
    }
    button {
      display: none;
    }
    body {
      height: auto;
      overflow: unset;
    }
    .MuiDrawer-root{
      display: none;
    }
    .makeStyles-content-1, .home-sidebar-right, .home-sidebar-right-margin{
      margin-left: 0 !important;
    }
    .header-area, .beach-theam-home-sidebar-left,
    .order-page-title-row, .footer-area, .breadcrumb-area, .purechat-chatbox{
      display: none;
    }
  }
.printableLayout-details-content{
    input:disabled {
        background: #f2f2f2;
        border: #e6e6e6 1px;
        cursor: not-allowed;
    }
}

input:disabled.disabled-background {
    background-color: #e9ecef !important;
}

.shipstationModal {
    padding: 37px 75px !important;
}
.catalougueLayout {
    margin: 0 100px;
    .product-large-image-wrapper {
        margin-top: 40px;
    }
    .single-image {
        margin: 0 auto;
        .containerimg {
            .react_lightgallery_item {
                text-align: center;
            }
        }
    }
    .product-small-image-wrapper {
        .single-image {
            height: 90px;
        }
    }
    .Product-Catalogue-Description {
        display: table;
        margin: 0 auto !important;
        max-width: 800px;
       
        .lblbggray{
            height: 34px;
        }
    }
}

.catalogue-product-quantity {
    width: 435px;
    .cart-plus-minus {
        position: relative;
        display: inline-block;
        width: 110px;
        height: 30px;
        padding: 0;
        .qtybutton {
            font-size: 16px;
            position: absolute;
            float: inherit;
            width: 20px;
            margin: 0;
            cursor: pointer;
            transition: all 0.3s ease 0s;
            text-align: center;
            color: #333;
            border: none;
            background: none;
        }
        .dec.qtybutton {
            top: 0;
            left: 0;
            height: 30px;
            border-right: 1px solid #e5e5e5;
        }
        .inc.qtybutton {
            top: 0;
            right: 0;
            height: 30px;
            border-left: 1px solid #e5e5e5;
        }
        input.cart-plus-minus-box {
            font-size: 14px;
            float: left;
            width: 110px;
            height: 30px;
            margin: 0;
            padding: 0;
            text-align: center;
            color: #333;
            border: 1px solid #e1e1e1;
            background: transparent none repeat scroll 0 0;
        }
    }
}

.shipstationDailog .modal-dialog {
    margin: 80px auto;
    padding: 10px;
}
.QuitationDailog .modal-dialog {
    margin: 0 auto;
    padding: 10px;
}

.shipstationDailog .modal-body {
    padding: 10px 40px !important;
}

#specialRequirement {
    background: #fff;
}

@media screen and (max-width:767px) {
    .catalougueLayout {
        margin: 0px;
    }

    .catalougueLayout .product-details-widget>div {
        padding: 20px 0px !important;
    }

    .catalougueLayout .product-details-widget {
        padding-top: 5px;
    }

    .catalougueLayout>div {
        padding: 0px !important;
    }

    .catalougueLayout .row {
        margin: 0px;
    }

    .catalougueLayout .Product-Catalogue-Description {
        display: block;
    }

    .catalougueLayout .Product-Catalogue-Description table tr td {
        width: auto !important;
    }

    .catalougueLayout .product-wrapper {
        overflow: auto;
    }
}
.billing-desc{
    display: table;
    width: 100%;
}
.general-setting {
    margin-top: 40px;

    &__body {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 24px;
        margin: 40px 0;
    }
}
.catalougueLayout h1,.catalougueLayout strong, .catalougueLayout p  {
    color: #000 !important;
}
.portalLayout .sizediv .bggray{
    margin-bottom: 0px;
    padding: 5px;
    height: 38px;
}
.justify-content-right {
    justify-content: end !important;
}

input:-webkit-autofill {
    animation-name: autofill;
    animation-duration: 0.6s;
}

@keyframes autofill {
    from {}
    to {}
}

.orderdecorows {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    .react_lightgallery_item {
      width: 100%;
    }
  }
  
  .card_decorows {
    flex: 1 1 calc(50% - 10px);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    text-align: center;
    box-sizing: border-box;
    min-height: 80px; 
    background-color: white;
    margin: 0.5em;
    img {
      max-height: 80px;
      max-width: 80px;
      object-fit: contain;
      flex-grow: 1;
      background: #ccc url("../../assets/img/checker.png") 0 0 repeat;
    }
    div {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
    }
    p {
      margin: 0;
      flex-grow: 1;
    }
  }
  
  /* Asegurar que las cartas sean responsivas */
  @media screen and (max-width: 768px) {
    .card_decorows {
      flex: 1 1 100%; /* Mostrar una carta por fila en pantallas pequeñas */
      max-width: 100%;
    }
  }